import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CarrierBookingAgent } from 'src/app/models/carrier-booking-agent.model';
import { CarrierService } from 'src/app/services/carrier.service';

@Component({
  selector: 'app-add-carrier-booking-agent',
  templateUrl: './add-carrier-booking-agent.component.html',
  styleUrls: ['./add-carrier-booking-agent.component.scss']
})
export class AddCarrierBookingAgentComponent implements OnInit, OnDestroy {

  carrierId: number;
  agent = new CarrierBookingAgent();

  destroy$ = new Subject<null>();

  errors: string[] = [];
  isSaving = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private carrierService: CarrierService,
    private dialogRef: MatDialogRef<AddCarrierBookingAgentComponent>
  ) { }

  ngOnInit(): void {
    this.carrierId = this.data.carrierId;
    this.agent.name = this.data.name;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  cancel() {
    this.dialogRef.close({ created: false });
  }

  save() {
    this.isSaving = true;
    this.carrierService.addNewBookingAgent(this.carrierId, this.agent).pipe(
      finalize(() => this.isSaving = false),
      takeUntil(this.destroy$)
    ).subscribe(id => {
      this.errors = [];
      this.agent.id = id;
      this.dialogRef.close({ created: true, item: this.agent });
    }, err => {
      if (err.error?.errors) {
        this.errors = [].concat.apply([], Object.values(err.error.errors));
      } else if (err.error?.message) {
        this.errors = [err.error.message];
      } else {
        this.errors = ['An unexpected error has occurred. Please try again or contact administrator.'];
      }
    });
  }

}
