import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActualFileObject, FilePondFile } from 'filepond';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadFilter } from '../enums/load-filter.enum';
import { LoadInvoicingStatus } from '../enums/load-invoicing-status.enum';
import { LoadStatus } from '../enums/load-status.enum';
import { DtResponse } from '../models/dt-response.model';
import { Load } from '../models/load.model';

@Injectable({
  providedIn: 'root'
})
export class LoadService {

  dashboardLoadCount: number;
  loadToEdit: Load;

  constructor(
    private http: HttpClient
  ) { }

  getMyLoads(dtParams: any, loadFilter?: LoadFilter, searchQuery: string = ''): Observable<DtResponse<Load>> {
    const params = dtParams;
    if (loadFilter != null) {
      params.load_filter = loadFilter;
    };

    return this.http.post<DtResponse<Load>>(`${ environment.apiUrl }/load/my-loads`, {
      ...params,
      search_query: searchQuery
    });
  }

  getAllLoads(dtParams: any, loadFilter?: LoadFilter, searchQuery: string = ''): Observable<DtResponse<Load>> {
    const params = dtParams;
    if (loadFilter != null) {
      params.load_filter = loadFilter;
    };

    return this.http.post<DtResponse<Load>>(`${ environment.apiUrl }/load/all-loads`, {
      ...params,
      search_query: searchQuery
    });
  }

  changeLoadStatus(id: number, status: LoadStatus, deadline: Moment, notes?: string) {
    return this.http.post(`${ environment.apiUrl }/load/${ id }/change-status`, {
      status,
      deadline: deadline.utc().format('YYYY-MM-DD HH:mm:ss'),
      notes
    });
  }

  markAsPodDelivered(id: number, pod: File, notes?: string) {
    let formData = new FormData();
    formData.append('status', LoadStatus.PodDelivered.toString());
    formData.append('pod', pod);
    if (notes) {
      formData.append('notes', notes);
    }

    return this.http.post(`${ environment.apiUrl }/load/${ id }/change-status`, formData);
  }

  addNewLoad(load: Load, loadTender?: File): Observable<number> {
    load.client_id = load.client?.id;
    for (let stop of load.pickup_stops) {
      if (stop.location?.id != null) {
        stop.location_id = stop.location?.id;
      }
    }
    for (let stop of load.dropoff_stops) {
      if (stop.location?.id != null) {
        stop.location_id = stop.location?.id;
      }
    }
    load.carrier_id = load.carrier?.id;
    load.carrier_driver_id = load.carrier_driver?.id;
    load.carrier_booking_agent_id = load.carrier_booking_agent?.id;
    if (load.deadlineMoment.isValid()) {
      load.deadline = load.deadlineMoment.utc().format('YYYY-MM-DD HH:mm:ss');
    }

    let formData = new FormData();
    if (loadTender != null) {
      formData.append('load_tender', loadTender);
    }
    Object.keys(load).forEach(key => {
      if (typeof load[key] === 'object' && load[key] != null) {
        Object.keys(load[key]).forEach(key2 => {
          if (typeof load[key][key2] === 'object' && load[key][key2] != null) {
            Object.keys(load[key][key2]).forEach(key3 => {
              if (load[key][key2][key3] != null) {
                formData.append(`${ key }[${ key2 }][${ key3 }]`, load[key][key2][key3] ?? '');
              }
            });
          } else {
            if (load[key][key2] != null) {
              formData.append(`${ key }[${ key2 }]`, load[key][key2] ?? '');
            }
          }
        });
      } else {
        if (load[key] != null) {
          formData.append(key, load[key] ?? '');
        }
      }
    });

    return this.http.post<number>(`${ environment.apiUrl }/load/create-update`, formData);
  }

  getLoadsForCarrier(carrierId: number, dtParams: any, loadFilter?: LoadFilter, searchQuery: string = ''): Observable<DtResponse<Load>> {
    const params: any = {
      id: carrierId,
      ...dtParams
    };
    if (loadFilter != null) {
      params.load_filter = loadFilter;
    };

    return this.http.post<DtResponse<Load>>(`${ environment.apiUrl }/load/loads-for-carrier`, {
      ...params,
      search_query: searchQuery
    });
  }

  getLoadsForClient(clientId: number, dtParams: any, loadFilter?: LoadFilter, searchQuery: string = ''): Observable<DtResponse<Load>> {
    const params: any = {
      id: clientId,
      ...dtParams
    };
    if (loadFilter != null) {
      params.load_filter = loadFilter;
    };

    return this.http.post<DtResponse<Load>>(`${ environment.apiUrl }/load/loads-for-client`, {
      ...params,
      search_query: searchQuery
    });
  }

  getLoadsForEmployee(employeeId: number, dtParams: any, loadFilter?: LoadFilter, searchQuery: string = ''): Observable<DtResponse<Load>> {
    const params: any = {
      id: employeeId,
      ...dtParams
    };
    if (loadFilter != null) {
      params.load_filter = loadFilter;
    };

    return this.http.post<DtResponse<Load>>(`${ environment.apiUrl }/load/loads-for-employee`, {
      ...params,
      search_query: searchQuery
    });
  }

  updateRequiresAssistance(loadId: number, requiresAssistance: boolean): Observable<boolean> {
    return this.http.post<boolean>(`${ environment.apiUrl }/load/${ loadId }/requires-assistance`, {
      requires_assistance: requiresAssistance
    });
  }

  getLoadDetails(id: number): Observable<Load> {
    return this.http.get<Load>(`${ environment.apiUrl }/load/${ id }`).pipe(
      map(load => {
        load.deadlineMoment = moment.utc(load.deadline).local();
        for (let stop of load.pickup_stops) {
          let loc = stop.location;
          stop.location.full_address = `${ loc.label ? loc.label + ' - ' : '' }${ loc.address }, ${ loc.city } ${ loc.state }`;
        }
        for (let stop of load.dropoff_stops) {
          let loc = stop.location;
          stop.location.full_address = `${ loc.label ? loc.label + ' - ' : '' }${ loc.address }, ${ loc.city } ${ loc.state }`;
        }
        return load;
      })
    );
  }

  downloadRateCon(id: number) {
    return this.http.post(`${ environment.apiUrl }/load/${ id }/generate-rate-con`, null, {
      responseType: 'blob'
    });
  }

  deleteLoad(id: number): Observable<boolean> {
    return this.http.post<boolean>(`${ environment.apiUrl }/load/delete`, {
      id
    });
  }

  changeLoadInvoicingStatus(id: number, status: LoadInvoicingStatus, notes?: string, deadline?: Moment, file?: File) {
    let formData = new FormData();
    formData.append('status', status.toString());
    if (notes) {
      formData.append('notes', notes);
    }
    if (deadline) {
      formData.append('deadline', deadline.format('YYYY-MM-DD'));
    }
    if (file) {
      formData.append('invoice', file);
    }

    return this.http.post(`${ environment.apiUrl }/load/${ id }/change-invoicing-status`, formData);
  }

  changeLoadOwner(loadId: number, ownerId: number): Observable<boolean> {
    return this.http.post<boolean>(`${ environment.apiUrl }/load/${ loadId }/change-owner`, {
      owner_id: ownerId.toString()
    });
  }

  sendRateConEmail(loadId: number, emailsTo: string[], emailsCc: string[], content: string, attachments: File[]) {
    let formData = new FormData();
    formData.append('id', loadId.toString());
    for (let i = 0; i < emailsTo.length; i++) {
      formData.append(`emails_to[${ i }]`, emailsTo[i]);
    }
    for (let i = 0; i < emailsCc.length; i++) {
      formData.append(`emails_cc[${ i }]`, emailsCc[i]);
    }
    formData.append('content', content);
    for (let i = 0; i < attachments.length; i++) {
      formData.append(`attachments[${ i }]`, attachments[i]);
    }

    return this.http.post<boolean>(`${ environment.apiUrl }/load/send-rate-con-email`, formData);
  }

}
