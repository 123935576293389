<div class="form-container">
  <div class="header-container">
    <h1>{{ headerTitle }}</h1>
    <div class="buttons-container">
      <button class="btn-primary" (click)="save()" [disabled]="isSaving || isSearchingFmcsa">Save</button>
      <button class="btn-secondary" (click)="cancel()" [disabled]="isSaving || isSearchingFmcsa">Cancel</button>
    </div>
  </div>
  <div class="errors-container" *ngIf="errors.length > 0">
    <div class="error-line" *ngFor="let error of errors">{{ error }}</div>
  </div>
  <h2>Carrier Details</h2>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="mc_num">MC #</label>
        <input id="mc_num" type="text" [(ngModel)]="carrier.mc_num" (change)="searchFmcsaCarrierByMc()" (keyup.enter)="searchFmcsaCarrierByMc()" [disabled]="isSearchingFmcsa" />
      </div>
      <div class="form-field-container">
        <label for="dot_num">DOT #</label>
        <input id="dot_num" type="text" [(ngModel)]="carrier.dot_num" (change)="searchFmcsaCarrierByDot()" (keyup.enter)="searchFmcsaCarrierByDot()" [disabled]="isSearchingFmcsa" />
      </div>
    </div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="company_name">Company Name</label>
        <input id="company_name" type="text" [(ngModel)]="carrier.company_name" [disabled]="isSearchingFmcsa" />
      </div>
      <div class="form-field-container">
        <label for="business_name">Business Name</label>
        <input id="business_name" type="text" [(ngModel)]="carrier.business_name" [disabled]="isSearchingFmcsa" />
      </div>
    </div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="email">Email</label>
        <input id="email" type="text" [(ngModel)]="carrier.email" [disabled]="isSearchingFmcsa" />
      </div>
      <div class="form-field-container">
        <label for="power_units">Power Units (#)</label>
        <input id="power_units" type="number" onkeypress="return !(event.charCode == 46)" [(ngModel)]="carrier.power_units" [disabled]="isSearchingFmcsa" (wheel)="$event.target.blur()" />
      </div>
    </div>
  </div>
  <h2>Addresses</h2>
  <div class="form-group-container">
    <div class="form-row flex" *ngFor="let address of carrier.addresses; let i = index">
      <div class="form-field-container">
        <label for="is_hq">HQ</label>
        <input type="checkbox" name="is_hq" id="is_hq" [disabled]="address.is_hq" [(ngModel)]="address.is_hq" (ngModelChange)="hqChecked(i)" />
      </div>
      <div class="form-field-container">
        <label for="address">Address</label>
        <input #addressText placeholder="Start typing an address" [disabled]="!mapLoaded" id="address" type="text" [(ngModel)]="address.address" />
      </div>
      <div class="form-field-container">
        <label for="city">City</label>
        <input id="city" type="text" [(ngModel)]="address.city" />
      </div>
      <div class="form-field-container">
        <label for="state">State</label>
        <input class="half-width" id="state" type="text" [(ngModel)]="address.state" />
      </div>
      <div class="form-field-container">
        <label for="zip">ZIP</label>
        <input class="half-width" id="zip" type="text" [(ngModel)]="address.zip" />
      </div>
      <button class="btn-remove" (click)="removeAddress(i)">- Remove</button>
    </div>
    <button class="btn-secondary" (click)="addAddress()" [disabled]="isSearchingFmcsa">+ Add new address</button>
  </div>
  <h2>Booking Agents</h2>
  <div class="form-group-container">
    <div class="form-row flex" *ngFor="let agent of carrier.booking_agents; let i = index">
      <div class="form-field-container">
        <label for="name">Name</label>
        <input id="name" type="text" [(ngModel)]="agent.name" />
      </div>
      <div class="form-field-container">
        <label for="email">Email</label>
        <input id="email" type="text" [(ngModel)]="agent.email" />
      </div>
      <div class="form-field-container">
        <label for="phone">Phone</label>
        <input id="phone" type="text" [(ngModel)]="agent.phone" />
      </div>
      <button class="btn-remove" (click)="removeAgent(i)">- Remove</button>
    </div>
    <button class="btn-secondary" (click)="addAgent()" [disabled]="isSearchingFmcsa">+ Add new agent</button>
  </div>
  <h2>Drivers</h2>
  <div class="form-group-container">
    <div class="form-row flex" *ngFor="let driver of carrier.drivers; let i = index">
      <div class="form-field-container">
        <label for="name">Name</label>
        <input id="name" type="text" [(ngModel)]="driver.name" />
      </div>
      <div class="form-field-container">
        <label for="phone">Phone</label>
        <input class="half-width" id="phone" type="text" [(ngModel)]="driver.phone" />
      </div>
      <div class="form-field-container">
        <label for="driver_num">Driver #</label>
        <input class="half-width" id="driver_num" type="text" [(ngModel)]="driver.driver_num" />
      </div>
      <div class="form-field-container">
        <label for="tractor_num">Tractor #</label>
        <input class="half-width" id="tractor_num" type="text" [(ngModel)]="driver.tractor_num" />
      </div>
      <div class="form-field-container">
        <label for="trailer_num">Trailer #</label>
        <input class="half-width" id="trailer_num" type="text" [(ngModel)]="driver.trailer_num" />
      </div>
      <button class="btn-remove" (click)="removeDriver(i)">- Remove</button>
    </div>
    <button class="btn-secondary" (click)="addDriver()" [disabled]="isSearchingFmcsa">+ Add new driver</button>
  </div>
  <h2>Equipment</h2>
  <div class="form-group-container">
    <div class="form-row flex" *ngFor="let equipment of carrier.equipments; let i = index">
      <div class="form-field-container two-widths">
        <label for="name">Name</label>
        <ng-select
          #equipmentSelects
          [items]="equipment$[i] | async"
          bindLabel="name"
          [addTag]="(auth.isOps$ | async) ? createEquipment : null"
          [trackByFn]="trackEquipmentByFn"
          [loading]="equipmentLoading"
          [typeahead]="equipmentInput$[i]"
          [(ngModel)]="carrier.equipments[i]"
          [disabled]="equipmentLoading">
        </ng-select>
      </div>
      <button class="btn-remove" (click)="removeEquipment(i)">- Remove</button>
    </div>
    <button class="btn-secondary" (click)="addEquipment()" [disabled]="isSearchingFmcsa">+ Add new equipment</button>
  </div>
  <h2>Attachments</h2>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container flex-grow">
        <label for="carrier_packet">Carrier Packet</label>
        <file-pond #carrierPacketPond [options]="pondOptions"
          [files]="carrier.carrier_packet_url"
          (onremovefile)="carrierPacketRemoved()">
        </file-pond>
      </div>
      <div class="form-field-container">
        <label for="carrier_packet_expiry">Carrier Packet Expiry</label>
        <input type="date" name="carrier_packet_expiry" id="carrier_packet_expiry" [(ngModel)]="carrier.carrier_packet_expiry" [disabled]="isSearchingFmcsa" />
      </div>
    </div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container flex-grow">
        <label for="insurance">Insurance</label>
        <file-pond #insurancePond [options]="pondOptions"
          [files]="carrier.insurance_url"
          (onremovefile)="insuranceRemoved()">
        </file-pond>
      </div>
      <div class="form-field-container">
        <label for="insurance_expiry">Insurance Expiry</label>
        <input type="date" name="insurance_expiry" id="insurance_expiry" [(ngModel)]="carrier.insurance_expiry" [disabled]="isSearchingFmcsa" />
      </div>
    </div>
  </div>
  <div class="form-group-container">
    <div class="form-row">
      <div class="form-field-container flex-grow">
        <label for="paperwork">Paperwork</label>
        <file-pond class="paperwork-pond" #paperworkPond [options]="paperworkPondOptions"
          (onaddfile)="onAddedPaperwork($event)">
        </file-pond>
        <div class="bold" *ngIf="currentPaperwork.length > 0">Added files:</div>
        <div class="paperwork-entry" *ngFor="let paperwork of currentPaperwork; let i = index">
          <div class="filename-label">{{ getFilename(paperwork) }}</div>
          <input type="text" [disabled]="paperwork.file_url != null" [(ngModel)]="paperwork.description" placeholder="Enter description" />
          <button class="btn-remove" (click)="removePaperwork(i)">- Remove</button>
        </div>
      </div>
    </div>
  </div>
</div>
