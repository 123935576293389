<div class="form-container">
  <div class="header-container">
    <h1>Add Note</h1>
  </div>
  <div class="errors-container" *ngIf="errors.length > 0">
    <div class="error-line" *ngFor="let error of errors">{{ error }}</div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container full-width">
        <textarea name="note" id="note" rows="10" [(ngModel)]="note.content"></textarea>
      </div>
    </div>
    <div class="form-row flex">
      <button class="btn-primary" (click)="addNote()" [disabled]="isAdding">Save</button>
    </div>
  </div>
</div>
