import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { Carrier } from 'src/app/models/carrier.model';
import { CarrierService } from 'src/app/services/carrier.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-carriers-table',
  templateUrl: './carriers-table.component.html',
  styleUrls: ['./carriers-table.component.scss']
})
export class CarriersTableComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(DataTableDirective, { static: false }) private dtElem: DataTableDirective;

  currentQuery = '';
  filterQuery$ = new Subject<string>();
  filterQueryDebounced$ = new Observable<string>();

  destroy$ = new Subject<null>();

  carriers: Carrier[];
  dtOptions: DataTables.Settings = {};

  constructor(
    public carrierService: CarrierService,
    private router: Router,
    private uiService: UiService
  ) {
    this.uiService.setHeaderTitle('Carrier List');
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      lengthChange: false,
      searching : false,
      columns: [
        { name: 'carrier_num', orderable: true },
        { name: 'mc_num', orderable: false},
        { name: 'dot_num', orderable: false},
        { name: 'business_name', orderable: true },
        { name: 'status', orderable: true }
      ],
      order: [[4, 'desc']],
      ajax: (dtParams, callback) => {
        this.carrierService.getAllCarriers(dtParams, this.currentQuery).pipe(
          takeUntil(this.destroy$),
          takeUntil(this.filterQueryDebounced$)
        ).subscribe(res => {
          this.carriers = res.data;
          callback({
            recordsTotal: res.recordsTotal,
            recordsFiltered: res.recordsFiltered,
            data: []
          });
        });
      },
      drawCallback: () => {
        setTimeout(() => {
          this.dtElem.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.columns.adjust();
          });
        });
      },
    };

    this.filterQueryDebounced$ = this.filterQuery$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(query => this.currentQuery = query),
      takeUntil(this.destroy$)
    );
  }

  ngAfterViewInit(): void {
    this.filterQueryDebounced$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(_ => {
      this.dtElem.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload();
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  goToCarrier(id: number) {
    this.router.navigate(['/carrier', id]);
  }

  filterQueryChanged(query: string) {
    this.filterQuery$.next(query);
  }

}
