<div class="form-container">
  <div class="header-container">
    <h1>Carrier Search</h1>
  </div>
  <div class="errors-container" *ngIf="errors.length > 0">
    <div class="error-line" *ngFor="let error of errors">{{ error }}</div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container full-width">
        <label for="mc_num">MC # / DOT # / Business Name</label>
        <input id="mc_num" type="text" [(ngModel)]="searchModel.term" />
      </div>
    </div>
    <div class="form-row flex">
      <div class="form-field-container full-width">
        <label for="address_from">From</label>
        <input #fromAddressText placeholder="Start typing an address" [disabled]="!mapLoaded" id="address_from" type="text" [(ngModel)]="fromAddress" />
      </div>
      <div class="form-field-container">
        <label for="address_from_radius_miles">Radius (Miles)</label>
        <select name="address_from_radius_miles" id="address_from_radius_miles" [(ngModel)]="searchModel.from_radius">
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
        </select>
      </div>
    </div>
    <div class="form-row flex">
      <div class="form-field-container full-width">
        <label for="address_to">To</label>
        <input #toAddressText placeholder="Start typing an address" [disabled]="!mapLoaded"  id="address_to" type="text" [(ngModel)]="toAddress" />
      </div>
      <div class="form-field-container">
        <label for="address_to_radius_miles">Radius (Miles)</label>
        <select name="address_to_radius_miles" id="address_to_radius_miles" [(ngModel)]="searchModel.to_radius">
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
        </select>
      </div>
    </div>
    <div class="form-row flex">
      <button class="btn-primary" (click)="search()" [disabled]="isSearching">Search</button>
    </div>
  </div>
  <div class="results-container" *ngIf="results != null">
    <p *ngIf="results.length === 0">No carrier found</p>
    <table *ngIf="results.length > 0">
      <colgroup>
        <col style="width: 10%;" />
        <col style="width: 10%;" />
        <col style="width: 20%;" />
        <col style="width: 20%;" />
        <col style="width: 20%;" />
        <col style="width: 20%;" />
      </colgroup>
      <thead>
        <tr>
          <th>MC #</th>
          <th>DOT #</th>
          <th>Name</th>
          <th>From Carrier Address</th>
          <th>To Carrier Address</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let carrier of results">
          <td>{{ carrier.mc_num ? carrier.mc_num : '-' }}</td>
          <td>{{ carrier.dot_num ? carrier.dot_num : '-' }}</td>
          <td>{{ carrier.business_name }}</td>
          <td>{{ getAddressString(carrier.search_result_from_address) }}</td>
          <td>{{ getAddressString(carrier.search_result_to_address) }}</td>
          <td>
            <button *ngIf="getInactiveReason(carrier) == null" (click)="selectCarrier(carrier)">Select</button>
            <div class="inactive-text" *ngIf="getInactiveReason(carrier) != null">{{ getInactiveReason(carrier) }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
