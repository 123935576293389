import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilePond, FilePondOptions } from 'filepond';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit {

  showDeadlinePicker = false;
  showNotesTextArea = false;
  showFileUpload = false;
  showTime = true;

  titleLabel = 'Select a deadline';
  subtitleLabel = 'Status has to be updated by the given date & time, or the load status will turn urgent';

  submitButtonLabel = 'Change status';

  dateTimePicker = new FormControl('');
  @ViewChild('filePond') filePond: FilePond;
  notes: string;

  pondOptions: FilePondOptions = {
    labelIdle: 'Drop invoice here',
    allowMultiple: false,
    maxFiles: 1,
    credits: false
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DateTimePickerComponent>
  ) { }

  ngOnInit(): void {
    this.showDeadlinePicker = this.data.showDeadlinePicker;
    this.showNotesTextArea = this.data.showNotesTextArea;
    this.showFileUpload = this.data.showFileUpload;
    if (this.data.showTime != null) {
      this.showTime = this.data.showTime;
    }
    if (this.data.submitButtonLabel) {
      this.submitButtonLabel = this.data.submitButtonLabel;
    }
    if (this.data.titleLabel != null) {
      this.titleLabel = this.data.titleLabel;
    }
    if (this.data.subtitleLabel != null) {
      this.subtitleLabel = this.data.subtitleLabel;
    }
    if (this.data.defaultMoment) {
      this.dateTimePicker.setValue(this.data.defaultMoment);
    }
  }

  getHours(): number {
    const d = new Date();
    return d.getHours();
  }

  cancel() {
    this.dialogRef.close({ proceed: false });
  }

  proceed() {
    if (this.showDeadlinePicker && !this.dateTimePicker.value) {
      return;
    }
    const file = this.filePond?.getFile()?.file;
    this.dialogRef.close({ proceed: true, result: this.dateTimePicker.value, notes: this.notes, file });
  }

}
