import { Moment } from "moment";
import { CarrierEquipment } from "./carrier-equipment.model";
import { ClientBusinessLocation } from "./client-business-location.model";
import { Client } from "./client.model";

export class Quote {
  id: number;
  pickup_city: string;
  pickup_state: string;
  pickup_location_id: number;
  pickup_location: ClientBusinessLocation;
  pickup_shipment_description: string;
  dropoff_city: string;
  dropoff_state: string;
  dropoff_location_id: number;
  dropoff_location: ClientBusinessLocation;
  dropoff_shipment_description: string;
  rate: number;
  owner_id: number;
  client_id: number;
  client: Client;
  created_at: Moment;
  equipments: CarrierEquipment[];

  constructor() {
    this.rate = 0;
    this.equipments = [];
  }

}
