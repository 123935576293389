import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Client } from 'src/app/models/client.model';
import { AuthService } from 'src/app/services/auth.service';
import { ClientService } from 'src/app/services/client.service';
import { UiService } from 'src/app/services/ui.service';
import { SendManagementAlertComponent } from '../send-management-alert/send-management-alert.component';

@Component({
  selector: 'app-clients-table',
  templateUrl: './clients-table.component.html',
  styleUrls: ['./clients-table.component.scss']
})
export class ClientsTableComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(DataTableDirective, { static: false }) private dtElem: DataTableDirective;

  currentQuery = '';
  filterQuery$ = new Subject<string>();
  filterQueryDebounced$ = new Observable<string>();

  destroy$ = new Subject<null>();

  clients: Client[];
  dtOptions: DataTables.Settings = {};

  constructor(
    private clientService: ClientService,
    private router: Router,
    private uiService: UiService,
    private dialog: MatDialog,
    private auth: AuthService
  ) {
    this.uiService.setHeaderTitle('Client List');
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      lengthChange: false,
      searching : false,
      scrollY: '100%',
      scrollX: true,
      scrollCollapse: true,
      columns: [
        { name: 'id', orderable: true },
        { name: 'name', orderable: false },
        { name: 'status', orderable: true },
        { name: 'address', orderable: false },
        { name: 'active_loads', orderable: true },
        { name: 'completed_loads', orderable: true },
        { name: 'revenue', orderable: true },
        { name: 'margin', orderable: true },
        { name: '', orderable: false }
      ],
      order: [[2, 'desc']],
      ajax: (dtParams, callback) => {
        this.auth.isOps$.pipe(
          switchMap(isOps => {
            if (isOps) {
              return this.clientService.getAllClients(dtParams, this.currentQuery);
            } else {
              return this.clientService.getMyClients(dtParams, this.currentQuery);
            }
          }),
          takeUntil(this.destroy$),
          takeUntil(this.filterQueryDebounced$)
        ).subscribe(res => {
          this.clients = res.data;
          callback({
            recordsTotal: res.recordsTotal,
            recordsFiltered: res.recordsFiltered,
            data: []
          });
        });
      },
      drawCallback: () => {
        setTimeout(() => {
          this.dtElem.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.columns.adjust();
          });
        });
      },
    };

    this.filterQueryDebounced$ = this.filterQuery$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(query => this.currentQuery = query),
      takeUntil(this.destroy$)
    );
  }

  ngAfterViewInit(): void {
    this.filterQueryDebounced$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(_ => {
      this.dtElem.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload();
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  goToClient(id: number) {
    this.router.navigate(['/client', id]);
  }

  sendManagmentAlert(event: any, client: Client) {
    event.stopPropagation();
    const dialog = this.dialog.open(SendManagementAlertComponent, {
      data: {
        id: client.id,
        name: client.name
      }
    });
  }

  formatMoney(n: number): string {
    return n ? n.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    }) : '0';
  }

  filterQueryChanged(query: string) {
    this.filterQuery$.next(query);
  }

}
