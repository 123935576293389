<div class="welcome-text">Welcome {{ userFullName | async }}</div>
<div class="tab-container">
  <a [class.selected]="currentRoute.includes('reports')" [routerLink]="['reports']">
    <div class="link-content">
      <div class="number-text">2</div>
      <div class="description-text">Total Reports</div>
    </div>
  </a>
  <a [class.selected]="currentRoute.includes('loads')" [routerLink]="['loads']">
    <div class="link-content">
      <div class="number-text">{{ loadService.dashboardLoadCount != null ? loadService.dashboardLoadCount : '-' }}</div>
      <div class="description-text">My Loads</div>
    </div>
  </a>
</div>
<hr />
<div class="content">
  <router-outlet></router-outlet>
</div>
