import { Location } from '@angular/common';
import { Component, ElementRef, NgZone, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject, concat, from, merge, Observable, of, Subject } from 'rxjs';
import { filter, tap, debounceTime, distinctUntilChanged, switchMap, catchError, map, switchMapTo, finalize, takeUntil } from 'rxjs/operators';
import { CarrierEquipment } from 'src/app/models/carrier-equipment.model';
import { ClientBusinessLocation } from 'src/app/models/client-business-location.model';
import { Client } from 'src/app/models/client.model';
import { Quote } from 'src/app/models/quote.model';
import { QuoteService } from 'src/app/services/quote.service';
import { CarrierService } from 'src/app/services/carrier.service';
import { ClientService } from 'src/app/services/client.service';
import { UiService } from 'src/app/services/ui.service';
import { AddClientBusinessLocationComponent } from '../add-client-business-location/add-client-business-location.component';
import { AuthService } from 'src/app/services/auth.service';
import { MapsAPILoader } from '@agm/core';
import { GeocodingService } from 'src/app/services/geocoding.service';

@Component({
  selector: 'app-add-quote',
  templateUrl: './add-quote.component.html',
  styleUrls: ['./add-quote.component.scss']
})
export class AddQuoteComponent implements OnInit, OnDestroy {

  @ViewChildren('equipmentSelects') equipmentSelects: QueryList<NgSelectComponent>;

  @ViewChild('pickupLocationSelect') pickupLocationSelect: NgSelectComponent;
  @ViewChild('dropoffLocationSelect') dropoffLocationSelect: NgSelectComponent;

  @ViewChild('pickupCityText') pickupCityText: ElementRef;
  @ViewChild('dropoffCityText') dropoffCityText: ElementRef;

  destroy$ = new Subject<null>();

  headerTitle = 'Add Quote';
  errors: string[] = [];
  isSaving = false;
  successText = 'Quote created';

  clients$: Observable<Client[]>;
  clientInput$ = new Subject<string>();
  clientLoading = false;
  clientSelected$ = new Subject<null>();

  fullLocationList: ClientBusinessLocation[] = [];

  pickupLocations$: Observable<ClientBusinessLocation[]>;
  pickupLocationInput$ = new Subject<string>();
  pickupLocationLoading = false;

  dropoffLocations$: Observable<ClientBusinessLocation[]>;
  dropoffLocationInput$ = new Subject<string>();
  dropoffLocationLoading = false;

  fullEquipmentList$: BehaviorSubject<CarrierEquipment[]> = new BehaviorSubject([]);
  equipmentLoading = false;

  equipment$: Observable<CarrierEquipment[]>[] = [];
  equipmentInput$: Subject<string>[] = [];

  mapLoaded = false;

  constructor(
    private uiService: UiService,
    private clientService: ClientService,
    private router: Router,
    private dialog: MatDialog,
    public quoteService: QuoteService,
    private snackbar: MatSnackBar,
    private location: Location,
    private carrierService: CarrierService,
    public auth: AuthService,
    private mapLoader: MapsAPILoader,
    private ngZone: NgZone,
    private geocodingService: GeocodingService
  ) {
    this.uiService.setHeaderTitle('Add New Quote');
    this.uiService.setGrayBg(true);
  }

  ngOnInit(): void {
    if (this.quoteService.quoteToEdit == null) {
      this.quoteService.quoteToEdit = new Quote();
    } else {
      this.successText = 'Quote updated';
      this.headerTitle = 'Update Quote';
    }

    if (this.quoteService.quoteToEdit.equipments.length === 0) {
      this.addEquipment();
    }

    this.loadClients();
    this.loadPickupLocations();
    this.loadDropoffLocations();
    this.loadEquipment();

    if (this.quoteService.quoteToEdit.client != null) {
      setTimeout(() => this.newClientSelected());
    }

    from(this.mapLoader.load()).subscribe(() => {
      this.mapLoaded = true;
      this.setupAutocomplete();
    });
  }

  ngOnDestroy(): void {
    this.uiService.setGrayBg(false);
    this.destroy$.next();
  }

  private setupAutocomplete() {
    let pickupAutocomplete = new google.maps.places.Autocomplete(this.pickupCityText.nativeElement, {
      componentRestrictions: { country: 'US' },
      types: ['(cities)']
    });
    google.maps.event.addListener(pickupAutocomplete, 'place_changed', () => {
      let place = pickupAutocomplete.getPlace();
      let placeAddress = this.geocodingService.deconstructGooglePlaceResult(place);
      this.ngZone.run(() => {
        this.quoteService.quoteToEdit.pickup_city = placeAddress.city;
        this.quoteService.quoteToEdit.pickup_state = placeAddress.state;
      });
    });

    let dropoffAutocomplete = new google.maps.places.Autocomplete(this.dropoffCityText.nativeElement, {
      componentRestrictions: { country: 'US' },
      types: ['(cities)']
    });
    google.maps.event.addListener(dropoffAutocomplete, 'place_changed', () => {
      let place = dropoffAutocomplete.getPlace();
      let placeAddress = this.geocodingService.deconstructGooglePlaceResult(place);
      this.ngZone.run(() => {
        this.quoteService.quoteToEdit.dropoff_city = placeAddress.city;
        this.quoteService.quoteToEdit.dropoff_state = placeAddress.state;
      });
    });
  }

  trackClientByFn(item: Client) {
    return item.id;
  }

  addClient = (name: string) => {
    const newClient = new Client();
    newClient.name = name;
    this.clientService.clientToEdit = newClient;
    this.router.navigate(['/add-client']);
  }

  newClientSelected() {
    this.clientSelected$.next();
  }

  private loadClients() {
    this.clientLoading = true;
    this.clients$ = concat(of([]), this.clientService.searchClients().pipe(
      finalize(() => this.clientLoading = false)
    ), this.clientInput$.pipe(
      filter(v => v != null && v.trim() != ''),
      tap(() => this.clientLoading = true),
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => this.clientService.searchClients(term).pipe(
        catchError(() => of([])),
        tap(() => this.clientLoading = false)
      ))
    ));
  }

  trackLocationByFn(item: ClientBusinessLocation) {
    return item.id;
  }

  addBusinessLocation = (name: string) => {
    this.pickupLocationSelect.close();
    this.dropoffLocationSelect.close();
    const dialog = this.dialog.open(AddClientBusinessLocationComponent, {
      data: {
        clientId: this.quoteService.quoteToEdit.client.id,
        address: name
      }
    });
    return new Promise((resolve, reject) => {
      dialog.afterClosed().pipe(
        takeUntil(this.destroy$)
      ).subscribe(({ created, item }) => {
        if (created) {
          this.snackbar.open('Location created', null, {
            duration: 3000
          });
          item.full_address = `${ item.label ? item.label + ' - ' : '' }${ item.address }, ${ item.city } ${ item.state }`;
          this.fullLocationList.unshift(item);
          this.pickupLocationInput$.next('');
          this.dropoffLocationInput$.next('');
          resolve(item);
        } else {
          this.snackbar.open('Failed to create location. Please try again or contact administrator.', null, {
            duration: 5000
          });
          reject('No location created');
        }
      })
    });
  }

  private loadPickupLocations() {
    this.pickupLocations$ = merge(of([]), this.pickupLocationInput$.pipe(
      filter(term => term != null),
      map(term => this.fullLocationList.filter(loc => loc.address.toLowerCase().includes(term.toLowerCase()) || loc.label?.toLowerCase()?.includes(term.toLowerCase())))
    ), this.clientSelected$.pipe(
      switchMapTo(of([]))
    ), this.clientSelected$.pipe(
      filter(() => this.quoteService.quoteToEdit.client != null),
      tap(() => this.pickupLocationLoading = true),
      switchMap(() => this.clientService.getClientLocations(this.quoteService.quoteToEdit.client.id).pipe(
        catchError((): Observable<ClientBusinessLocation[]> => of([])),
        tap(res => {
          this.pickupLocationLoading = false;
          this.fullLocationList = res;
        })
      ))
    ));
  }

  private loadDropoffLocations() {
    this.dropoffLocations$ = merge(of([]), this.dropoffLocationInput$.pipe(
      filter(term => term != null),
      map(term => this.fullLocationList.filter(loc => loc.address.toLowerCase().includes(term.toLowerCase()) || loc.label?.toLowerCase()?.includes(term.toLowerCase())))
    ), this.clientSelected$.pipe(
      switchMapTo(of([]))
    ), this.clientSelected$.pipe(
      filter(() => this.quoteService.quoteToEdit.client != null),
      tap(() => this.dropoffLocationLoading = true),
      switchMap(() => this.clientService.getClientLocations(this.quoteService.quoteToEdit.client.id).pipe(
        catchError((): Observable<ClientBusinessLocation[]> => of([])),
        tap(res => {
          this.dropoffLocationLoading = false;
          this.fullLocationList = res;
        })
      ))
    ));
  }

  addEquipment() {
    this.quoteService.quoteToEdit.equipments.push({
      name: ''
    });
    this.setupEquipmentFields();
  }

  private setupEquipmentFields() {
    const typeahead = new Subject<string>();
    this.equipmentInput$.push(typeahead);
    this.equipment$.push(concat(this.fullEquipmentList$, typeahead.pipe(
      filter(term => term != null),
      map(term => this.fullEquipmentList$.getValue().filter(e => e.name.toLowerCase().includes(term.toLowerCase())))
    )));
  }

  removeEquipment(i: number) {
    this.quoteService.quoteToEdit.equipments.splice(i, 1);
    this.equipmentInput$.splice(i, 1);
    this.equipment$.splice(i, 1);
  }

  trackEquipmentByFn(item: CarrierEquipment) {
    return item.id;
  }

  createEquipment = (name: string) => {
    this.equipmentSelects.forEach(ref => ref.close());
    this.equipmentLoading = true;
    return new Promise((resolve, reject) => {
      this.carrierService.createCarrierEquipment(name).pipe(
        finalize(() => this.equipmentLoading = false),
        takeUntil(this.destroy$)
      ).subscribe(equipmentId => {
        const equipment = {
          id: equipmentId,
          name
        };
        let equipmentList = this.fullEquipmentList$.getValue();
        equipmentList.push(equipment);
        equipmentList = equipmentList.sort((a, b) => a.name < b.name ? -1 : 1);
        this.fullEquipmentList$.next(equipmentList);
        this.equipmentInput$.forEach(input => input.next(''));
        resolve(equipment);
      }, err => {
        this.snackbar.open('Failed to create equipment. Please try again or contact administrator.', null, {
          duration: 5000
        });
        reject('No equipment created');
      });
    });
  }

  private loadEquipment() {
    this.equipmentLoading = true;
    this.carrierService.getAllEquipment().pipe(
      finalize(() => this.equipmentLoading = false),
      takeUntil(this.destroy$)
    ).subscribe(res => this.fullEquipmentList$.next(res));

    for (let _ of this.quoteService.quoteToEdit.equipments) {
      this.setupEquipmentFields();
    }
  }

  roundRate() {
    this.quoteService.quoteToEdit.rate = Math.round(this.quoteService.quoteToEdit.rate * 100) / 100;
  }

  save() {
    this.isSaving = true;

    this.quoteService.addNewQuote(this.quoteService.quoteToEdit).pipe(
      finalize(() => {
        this.ngZone.run(() => {
          this.isSaving = false;
        });
      }),
      takeUntil(this.destroy$)
    ).subscribe(res => {
      this.ngZone.run(() => {
        this.quoteService.quoteToEdit = null;
        this.errors = [];
        this.location.back();
        this.snackbar.open(this.successText, null, {
          duration: 5000
        });
      });
    }, err => {
      this.ngZone.run(() => {
        if (err.error?.errors) {
          this.errors = [].concat.apply([], Object.values(err.error.errors));
        } else if (err.error?.message) {
          this.errors = [err.error.message];
        } else {
          this.errors = ['An unexpected error has occurred. Please try again or contact administrator.'];
        }
      });
    });
  }

  cancel() {
    this.quoteService.quoteToEdit = null;
    this.location.back();
  }

}
