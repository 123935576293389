<div class="buttons-container">
  <a class="btn-secondary" (click)="goBack()">&lt; Back</a>
</div>
<div class="date-range-container">
  <label>Date Range</label>
  <input type="text" ngxDaterangepickerMd [(ngModel)]="selectedDates"
    [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [showRangeLabelOnInput]="true"
    [ranges]="ranges" [disabled]="report == null" placeholder="Select a date range"
    (datesUpdated)="datesUpdated()" [autoApply]="true" />
</div>
<div *ngIf="report == null" class="loading-text">Loading...</div>
<div class="content-container" *ngIf="report != null">
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
    <thead>
      <tr>
        <th></th>
        <th>Employee</th>
        <th>Number of loads</th>
        <th>Revenue</th>
        <th>Margin</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let employee of report?.byEmployee; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ (employee.user_info.first_name + ' ' + employee.user_info.last_name).trim() }}</td>
        <td>{{ employee.num_of_loads }}</td>
        <td>${{ employee.revenue }}</td>
        <td>${{ employee.margin }}</td>
      </tr>
      <tr *ngIf="report != null && (report.byEmployee == null || report.byEmployee.length === 0)">
        <td class="center-text" colspan="5">No data found</td>
      </tr>
    </tbody>
  </table>
</div>
