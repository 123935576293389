<div class="form-container">
  <div class="header-container">
    <h1>Add Driver</h1>
    <div class="buttons-container">
      <button class="btn-primary" (click)="save()" [disabled]="isSaving">Save</button>
      <button class="btn-secondary" (click)="cancel()" [disabled]="isSaving">Cancel</button>
    </div>
  </div>
  <div class="errors-container" *ngIf="errors.length > 0">
    <div class="error-line" *ngFor="let error of errors">{{ error }}</div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="name">Name</label>
        <input class="full-width" id="name" type="text" [(ngModel)]="driver.name" />
      </div>
      <div class="form-field-container">
        <label for="phone">Phone</label>
        <input class="full-width" id="phone" type="text" [(ngModel)]="driver.phone" />
      </div>
    </div>
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="driver_num">Driver #</label>
        <input id="driver_num" type="text" [(ngModel)]="driver.driver_num" />
      </div>
      <div class="form-field-container">
        <label for="tractor_num">Tractor #</label>
        <input id="tractor_num" type="text" [(ngModel)]="driver.tractor_num" />
      </div>
      <div class="form-field-container">
        <label for="trailer_num">Trailer #</label>
        <input id="trailer_num" type="text" [(ngModel)]="driver.trailer_num" />
      </div>
    </div>
  </div>
</div>
