<div class="form-container">
  <div class="header-container">
    <h1>{{ headerTitle }}</h1>
    <div class="buttons-container">
      <button class="btn-primary" (click)="save()" [disabled]="isSaving">Save</button>
      <button class="btn-secondary" (click)="cancel()" [disabled]="isSaving">Cancel</button>
    </div>
  </div>
  <div class="errors-container" *ngIf="errors.length > 0">
    <div class="error-line" *ngFor="let error of errors">{{ error }}</div>
  </div>
  <div class="form-group-container" *ngIf="auth.isAdmin$ | async">
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="visual_id">Load ID</label>
        <input id="visual_id" type="text" [(ngModel)]="loadService.loadToEdit.visual_id" placeholder="Auto-assigned if left blank" />
      </div>
    </div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container two-widths">
        <label>Client</label>
        <ng-select
          #clientSelect
          [items]="clients$ | async"
          bindLabel="name"
          [addTag]="addClient"
          [trackByFn]="trackClientByFn"
          [minTermLength]="2"
          [loading]="clientLoading"
          typeToSearchText="Start typing to search..."
          [typeahead]="clientInput$"
          [(ngModel)]="loadService.loadToEdit.client"
          (change)="newClientSelected()">
        </ng-select>
      </div>
      <!-- <div class="form-field-container one-width">
        <label for="status">Status</label>
        <select name="status" id="status" [(ngModel)]="loadService.loadToEdit.status">
          <option *ngFor="let status of loadStatuses" [value]="status">{{ loadStatusToLabelMapping[status] }}</option>
        </select>
      </div> -->
      <div class="form-field-container one-width">
        <label>Deadline (For status update)</label>
        <mat-form-field>
          <input matInput [ngxMatDatetimePicker]="picker" [formControl]="dateTimePicker" [(ngModel)]="loadService.loadToEdit.deadlineMoment">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker [defaultTime]="[getHours(), 0, 0]" [stepMinute]="15">
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="charge">Charge Amount</label>
        <input id="charge" type="number" step="0.01" [(ngModel)]="loadService.loadToEdit.charge" (change)="calculateMargin()" (wheel)="$event.target.blur()" />
      </div>
      <div class="form-field-container">
        <label for="client_accessorial">Client Accessorial</label>
        <input id="client_accessorial" type="number" step="0.01" [(ngModel)]="loadService.loadToEdit.client_accessorial" (change)="calculateMargin()" disabled="true" />
      </div>
      <div class="form-field-container">
        <label for="carrier_accessorial">Carrier Accessorial</label>
        <input id="carrier_accessorial" type="number" step="0.01" [(ngModel)]="loadService.loadToEdit.carrier_accessorial" (change)="calculateMargin()" disabled="true" />
      </div>
      <div class="form-field-container">
        <label for="compensation">Line Haul</label>
        <input id="compensation" type="number" step="0.01" [(ngModel)]="loadService.loadToEdit.compensation" (change)="calculateMargin()" (wheel)="$event.target.blur()" />
      </div>
      <div class="form-field-container">
        <label>Margin</label>
        <input id="margin" type="number" step="0.01" disabled="true" [value]="margin" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-field-container">
        <label>Payment Line Items</label>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Details</th>
              <th>Amount</th>
              <th>Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let lineItem of loadService.loadToEdit.charge_line_items; let i = index">
              <td>{{ i + 1 }}</td>
              <td><input type="text" [(ngModel)]="lineItem.name" /></td>
              <td><input type="number" step="0.01" [(ngModel)]="lineItem.charge" (change)="calculatePayment()" (wheel)="$event.target.blur()"></td>
              <td>
                <select (change)="calculatePayment()" [(ngModel)]="lineItem.type">
                  <option *ngFor="let type of chargeLineItemTypes" [ngValue]="type" [selected]="lineItem.type === type">{{ chargeLineItemTypeToLabelMapping[type] }}</option>
                </select>
              </td>
              <td><button (click)="removeChargeLineItem(i)">- Remove</button></td>
            </tr>
            <tr>
              <td colspan="5" class="center-text clickable" (click)="addChargeLineItem()">+ Add new line item</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <h2>Load Details</h2>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="pickup_bol_num">BOL #</label>
        <input id="pickup_bol_num" type="text" [(ngModel)]="loadService.loadToEdit.pickup_bol_num" />
      </div>
      <div class="form-field-container">
        <label for="dropoff_po_num">PO #</label>
        <input id="dropoff_po_num" type="text" [(ngModel)]="loadService.loadToEdit.dropoff_po_num" />
      </div>
    </div>
  </div>
  <h2>Pickup Details</h2>
  <div *ngFor="let stop of loadService.loadToEdit.pickup_stops; let i = index">
    <div class="form-group-container" *ngIf="stop.type === LoadStopPickup">
      <div class="form-row flex">
        <div class="stop-header">PICKUP STOP #{{ i + 1 }}</div>
        <button *ngIf="i !== 0" (click)="removePickupStop(i)">- Remove</button>
      </div>
      <div class="form-row flex">
        <div class="form-field-container two-widths">
          <label>Client Business Location</label>
          <ng-select
            #locationSelects
            [items]="pickupLocations$[i] | async"
            bindLabel="full_address"
            [addTag]="addBusinessLocation"
            [trackByFn]="trackLocationByFn"
            [loading]="locationLoading"
            typeToSearchText="None found. Start typing to add"
            [typeahead]="pickupLocationInput$[i]"
            [(ngModel)]="stop.location"
            [disabled]="!loadService.loadToEdit.client || locationLoading"
            [placeholder]="!loadService.loadToEdit.client ? 'Select a client first' : ''">
          </ng-select>
        </div>
        <div class="form-field-container one-width">
          <label for="pickup_date">Date</label>
          <input id="pickup_date" type="date" name="pickup_date" [(ngModel)]="stop.date" />
        </div>
        <div class="form-field-container">
          <label for="pickup_time">Time</label>
          <input id="pickup_time" type="text" [(ngModel)]="stop.time" />
        </div>
      </div>
      <div class="form-row flex">
        <div class="form-field-container">
          <label for="pickup_contact_name">Contact Name</label>
          <input id="pickup_contact_name" type="text" [(ngModel)]="stop.contact_name" />
        </div>
        <div class="form-field-container">
          <label for="pickup_contact_num">Contact Phone Number</label>
          <input id="pickup_contact_num" type="text" [(ngModel)]="stop.contact_phone" />
        </div>
      </div>
      <div class="form-row flex">
        <div class="form-field-container">
          <label for="pickup_weight">Weight</label>
          <input id="pickup_weight" type="text" [(ngModel)]="stop.weight" />
        </div>
        <div class="form-field-container">
          <label for="pickup_commodity">Commodity</label>
          <input id="pickup_commodity" type="text" [(ngModel)]="stop.commodity" />
        </div>
      </div>
      <div class="form-row flex">
        <div class="form-field-container two-widths">
          <label for="pickup_details">Pickup Description</label>
          <input id="pickup_details" type="text" [(ngModel)]="stop.details" />
        </div>
        <!-- <div class="form-field-container two-widths">
          <label for="pickup_shipment_description">Pickup Notes</label>
          <input id="pickup_shipment_description" type="text" [(ngModel)]="loadService.loadToEdit.pickup_shipment_description" />
        </div> -->
      </div>
      <div class="form-row flex">
        <div class="form-field-container two-widths">
          <label for="pickup_special_instructions">Special Instructions</label>
          <textarea
            name="pickup_special_instructions"
            id="pickup_special_instructions"
            cols="30"
            rows="10"
            [(ngModel)]="stop.special_instructions">
          </textarea>
        </div>
      </div>
    </div>
  </div>
  <button (click)="addPickupStop()">+ Add Pickup Stop</button>
  <h2>Dropoff Details</h2>
  <div *ngFor="let stop of loadService.loadToEdit.dropoff_stops; let i = index">
    <div class="form-group-container" *ngIf="stop.type === LoadStopDropoff">
      <div class="form-row flex">
        <div class="stop-header">DROPOFF STOP #{{ i + 1 }}</div>
        <button *ngIf="i !== 0" (click)="removeDropoffStop(i)">- Remove</button>
      </div>
      <div class="form-row flex">
        <div class="form-field-container two-widths">
          <label>Client Business Location</label>
          <ng-select
            #locationSelects
            [items]="dropoffLocations$[i] | async"
            bindLabel="full_address"
            [addTag]="addBusinessLocation"
            [trackByFn]="trackLocationByFn"
            [loading]="locationLoading"
            typeToSearchText="None found. Start typing to add"
            [typeahead]="dropoffLocationInput$[i]"
            [(ngModel)]="stop.location"
            [disabled]="!loadService.loadToEdit.client || locationLoading"
            [placeholder]="!loadService.loadToEdit.client ? 'Select a client first' : ''">
          </ng-select>
        </div>
        <div class="form-field-container one-width">
          <label for="dropoff_date">Date</label>
          <input id="dropoff_date" type="date" name="dropoff_date" [(ngModel)]="stop.date" />
        </div>
        <div class="form-field-container">
          <label for="dropoff_time">Time</label>
          <input id="dropoff_time" type="text" [(ngModel)]="stop.time" />
        </div>
      </div>
      <div class="form-row flex">
        <div class="form-field-container">
          <label for="dropoff_contact_name">Contact Name</label>
          <input id="dropoff_contact_name" type="text" [(ngModel)]="stop.contact_name" />
        </div>
        <div class="form-field-container">
          <label for="dropoff_contact_num">Contact Phone Number</label>
          <input id="dropoff_contact_num" type="text" [(ngModel)]="stop.contact_phone" />
        </div>
      </div>
      <div class="form-row flex">
        <div class="form-field-container">
          <label for="dropoff_weight">Weight</label>
          <input id="dropoff_weight" type="text" [(ngModel)]="stop.weight" />
        </div>
        <div class="form-field-container">
          <label for="dropoff_commodity">Commodity</label>
          <input id="dropoff_commodity" type="text" [(ngModel)]="stop.commodity" />
        </div>
      </div>
      <div class="form-row flex">
        <div class="form-field-container two-widths">
          <label for="dropoff_details">Dropoff Description</label>
          <input id="dropoff_details" type="text" [(ngModel)]="stop.details" />
        </div>
        <!-- <div class="form-field-container two-widths">
          <label for="dropoff_shipment_description">Dropoff Notes</label>
          <input id="dropoff_shipment_description" type="text" [(ngModel)]="loadService.loadToEdit.dropoff_shipment_description" />
        </div> -->
      </div>
      <div class="form-row flex">
        <div class="form-field-container two-widths">
          <label for="dropoff_special_instructions">Special Instructions</label>
          <textarea
            name="dropoff_special_instructions"
            id="dropoff_special_instructions"
            cols="30"
            rows="10"
            [(ngModel)]="stop.special_instructions">
          </textarea>
        </div>
      </div>
    </div>
  </div>
  <button (click)="addDropoffStop()">+ Add Dropoff Stop</button>
  <h2>Equipment Required</h2>
  <div class="form-group-container">
    <div class="form-row flex" *ngFor="let equipment of loadService.loadToEdit.equipments; let i = index">
      <div class="form-field-container two-widths">
        <ng-select
          #equipmentSelects
          [items]="equipment$[i] | async"
          bindLabel="name"
          [addTag]="(auth.isOps$ | async) ? createEquipment : null"
          [trackByFn]="trackEquipmentByFn"
          [loading]="equipmentLoading"
          [typeahead]="equipmentInput$[i]"
          [(ngModel)]="loadService.loadToEdit.equipments[i]"
          [disabled]="equipmentLoading">
        </ng-select>
      </div>
    </div>
  </div>
  <h2>Attachments</h2>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container flex-grow">
        <label for="load_tender">Load Tender</label>
        <file-pond #loadTenderPond [options]="pondOptions"
          [files]="loadService.loadToEdit.load_tender_url"
          (onremovefile)="loadTenderRemoved()">
        </file-pond>
      </div>
    </div>
  </div>
  <h2>Carrier Details</h2>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container one-width">
        <label>Carrier</label>
        <button *ngIf="loadService.loadToEdit.carrier == null" (click)="addCarrier()">+ New Carrier</button>
        <button style="margin-top: 8px;" *ngIf="loadService.loadToEdit.carrier == null" (click)="searchCarriers()">Search Carriers</button>
        <div class="carrier-display-container" *ngIf="loadService.loadToEdit.carrier != null">
          {{ loadService.loadToEdit.carrier.business_name }}
          <button class="btn-icon" (click)="clearCarrier()">&times;</button>
        </div>
      </div>
      <div class="form-field-container one-width">
        <label>Driver</label>
        <ng-select
          #driverSelect
          [items]="drivers$ | async"
          bindLabel="name"
          [addTag]="addDriver"
          [trackByFn]="trackDriverByFn"
          [loading]="driverLoading"
          typeToSearchText="None found. Start typing to add"
          [typeahead]="driverInput$"
          [(ngModel)]="loadService.loadToEdit.carrier_driver"
          [disabled]="!loadService.loadToEdit.carrier || driverLoading"
          [placeholder]="!loadService.loadToEdit.carrier ? 'Select a carrier first' : ''">
        </ng-select>
      </div>
      <div class="form-field-container one-width">
        <label>Booking Agent</label>
        <ng-select
          #bookingAgentSelect
          [items]="bookingAgents$ | async"
          bindLabel="name"
          [addTag]="addBookingAgent"
          [trackByFn]="trackBookingAgentByFn"
          [loading]="bookingAgentLoading"
          typeToSearchText="None found. Start typing to add"
          [typeahead]="bookingAgentInput$"
          [(ngModel)]="loadService.loadToEdit.carrier_booking_agent"
          [disabled]="!loadService.loadToEdit.carrier || bookingAgentLoading"
          [placeholder]="!loadService.loadToEdit.carrier ? 'Select a carrier first' : ''">
        </ng-select>
      </div>
    </div>
  </div>
</div>
