<div class="main">
  <div class="background"></div>
  <div class="honeycomb-bg-gradient"></div>
  <div class="honeycomb-bg"></div>
  <div class="container">
    <a [routerLink]="['/login']"><img class="logo-img" src="/assets/images/logo.png" /></a>
    <div class="login-form-container">
      <h1>{{ (headerTitle | async).slice(0, -1) }}<span class="orange">{{ (headerTitle | async).slice(-1) }}</span></h1>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
