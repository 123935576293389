<div class="buttons-container">
  <a class="btn-secondary" (click)="goBack()">&lt; Back</a>
</div>
<div class="row">
  <div class="date-range-container">
    <label>Date Range</label>
    <input type="text" ngxDaterangepickerMd [(ngModel)]="selectedDates"
      [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [showRangeLabelOnInput]="true"
      [ranges]="ranges" [disabled]="report == null" placeholder="Select a date range"
      (datesUpdated)="datesUpdated()" [autoApply]="true" />
  </div>
  <div class="equipment-search-container">
    <label>Equipment</label>
    <ng-select
      [items]="equipment$ | async"
      bindLabel="name"
      [trackByFn]="trackEquipmentByFn"
      [loading]="equipmentLoading"
      [typeahead]="equipmentInput$"
      [(ngModel)]="selectedEquipment"
      (change)="equipmentSelected($event)"
      (clear)="equipmentCleared()"
      [disabled]="equipmentLoading">
    </ng-select>
    <!-- <input type="text" [disabled]="report == null"
      placeholder="Start typing to filter" (input)="equipmentInput($event.target.value)" /> -->
  </div>
</div>
<div *ngIf="report == null" class="loading-text">Loading...</div>
<div class="content-container" *ngIf="report != null">
  <div class="row">
    <div class="property-container"><span class="label">Total loads: </span>{{ report?.totalCount }}</div>
  </div>
  <div class="row">
    <h2>Pickup by State</h2>
  </div>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
    <thead>
      <tr>
        <th></th>
        <th>State</th>
        <th>Number of loads</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of report?.pickupByState; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ row.state }}</td>
        <td>{{ row.num_of_loads }}</td>
      </tr>
      <tr *ngIf="report != null && (report.pickupByState == null || report.pickupByState.length === 0)">
        <td class="center-text" colspan="3">No data found</td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <h2>Dropoff by State</h2>
  </div>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
    <thead>
      <tr>
        <th></th>
        <th>State</th>
        <th>Number of loads</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of report?.dropoffByState; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ row.state }}</td>
        <td>{{ row.num_of_loads }}</td>
      </tr>
      <tr *ngIf="report != null && (report.dropoffByState == null || report.dropoffByState.length === 0)">
        <td class="center-text" colspan="3">No data found</td>
      </tr>
    </tbody>
  </table>
</div>
