import { Paperwork } from "./carrier-paperwork.model";
import { ClientBusinessLocation } from "./client-business-location.model";
import { ClientRep } from "./client-rep.model";
import { User } from "./user.model";

export class Client {
  id: number;
  name: string;
  address: string;
  owner_id: number;
  owner: User;
  reps: ClientRep[];
  business_locations: ClientBusinessLocation[];
  revenue?: number;
  carrier_accessorial?: number;
  client_accessorial?: number;
  compensation?: number;
  margin?: number;
  last_load_days_ago: number;
  active_loads: number;
  completed_loads: number;
  documents: Paperwork[];
  net_terms?: number;

  constructor() {
    this.reps = [];
    this.business_locations = [];
  }
}
