<div class="buttons-container">
  <a class="btn-secondary" [routerLink]="['/employees']">&lt; Back</a>
  <div class="right-buttons-container">
    <button class="btn-secondary" (click)="logIssue()" *ngIf="auth.isOps$ | async" [disabled]="isLoading">Log Issue</button>
    <button class="btn-primary" (click)="goToEditEmployee()" [disabled]="isLoading">Edit Employee</button>
    <button class="btn-round btn-delete" (click)="deleteEmployee()" [disabled]="isLoading" *ngIf="auth.isAdmin$ | async"><mat-icon>delete_outlined</mat-icon></button>
  </div>
</div>
<div *ngIf="employee == null" class="loading-text">Loading...</div>
<div class="content-container" *ngIf="employee != null">
  <div class="row">
    <h1>{{ employee?.user_info?.first_name }} {{ employee?.user_info?.last_name }}</h1>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Email: </span>{{ employee?.email }}</div>
    <div class="property-container"><span class="label">Phone: </span>{{ employee?.user_info?.phone }}</div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Date of Birth: </span>{{ employee?.user_info?.date_of_birth }}</div>
    <div class="property-container"><span class="label">Salary: </span>${{ formatNumber(employee?.user_info?.salary) }}</div>
    <div class="property-container"><span class="label">Role: </span>{{ roleToLabelMapping[employee?.role] }}</div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Revenue: </span>${{ formatNumber(employee?.revenue) }}</div>
    <div class="property-container"><span class="label">Margin: </span>${{ formatNumber(employee?.margin) }}</div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Address: </span>{{ getAddress(employee) }}</div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Notes: </span>{{ employee?.user_info?.notes ? employee.user_info.notes : '-' }}</div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Paperwork:</span></div>
  </div>
  <div class="row" *ngFor="let paperwork of employee?.user_info?.employee_paperwork">
    <a [href]="paperwork.file_url" target="_blank">{{ paperwork.description }} <mat-icon>attachment</mat-icon></a>
  </div>
  <div class="row">
    <h2>Loads</h2>
  </div>
  <app-loads-table [employeeId]="id"></app-loads-table>
  <hr />
  <div class="row">
    <h2>Issues Logged</h2>
  </div>
  <p class="center-text" *ngIf="employee.employee_issues?.length === 0">No issues logged yet</p>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover" *ngIf="employee.employee_issues?.length > 0">
    <thead>
      <tr>
        <th>Load ID</th>
        <th>Notes</th>
        <th>Date Logged</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let issue of employee.employee_issues">
        <td>{{ issue.for_load?.visual_id ? issue.for_load.visual_id : '-' }}</td>
        <td>{{ issue.notes }}</td>
        <td>{{ formatDate(issue.created_at) }}</td>
      </tr>
    </tbody>
  </table>
</div>
