<div class="container" *ngIf="token != null">
  <div class="password-input-container">
    <input placeholder="Password" [type]="isPasswordVisible ? 'text' : 'password'" [(ngModel)]="password" />
    <div class="icon-container" (click)="togglePasswordVisibility()">
      <mat-icon>{{ isPasswordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
    </div>
  </div>
  <div class="password-input-container">
    <input #passwordConfirmInput="ngModel" placeholder="Confirm password" [type]="isPasswordVisible ? 'text' : 'password'" [(ngModel)]="passwordConfirm" (change)="checkPasswordConfirm(passwordConfirmInput)" />
    <div class="icon-container" (click)="togglePasswordVisibility()">
      <mat-icon>{{ isPasswordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
    </div>
  </div>
  <div *ngIf="passwordConfirmInput.invalid && (passwordConfirmInput.dirty || passwordConfirmInput.touched)" class="alert">Make sure both passwords match</div>
  <button class="btn-primary" [disabled]="isLoading" (click)="resetPassword()">Reset password</button>
  <p class="error-results alert">{{ errorResults }}</p>
</div>
<div class="container" *ngIf="token == null">
  <div class="alert">Invalid password reset link</div>
</div>
