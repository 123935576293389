<div class="buttons-container">
  <input type="text" placeholder="Type to filter results" (input)="filterQueryChanged($event.target.value)" />
  <a class="btn-primary" [routerLink]="['/add-client']">+ New client</a>
</div>
<div class="table-container">
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover display nowrap">
    <thead>
      <tr>
        <th>#</th>
        <th>Name</th>
        <th>Status</th>
        <th>Address</th>
        <th>Active Loads</th>
        <th>Completed Loads</th>
        <th>Revenue</th>
        <th>Margin</th>
        <th></th>
      </tr>
    </thead>
    <tbody *ngIf="clients?.length !== 0">
      <tr class="clickable" *ngFor="let client of clients" (click)="goToClient(client.id)">
        <td>{{ client.id }}</td>
        <td>{{ client.name }}</td>
        <td *ngIf="client.last_load_days_ago <= 10">Active</td>
        <td *ngIf="client.last_load_days_ago > 10" class="stale-cell">Stale {{ client.last_load_days_ago }} days</td>
        <td>{{ client.address }}</td>
        <td>{{ client.active_loads }}</td>
        <td>{{ client.completed_loads }}</td>
        <td>${{ formatMoney(client.revenue) }}</td>
        <td>${{ formatMoney(client.margin) }}</td>
        <td>
          <button class="btn-secondary" (click)="sendManagmentAlert($event, client)">Send Alert</button>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="clients?.length === 0">
      <tr>
        <td colspan="9">No data found</td>
      </tr>
    </tbody>
  </table>
</div>
