import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LoadFilter } from 'src/app/enums/load-filter.enum';
import { LoadInvoicingStatus, LoadInvoicingStatusToLabelMapping } from 'src/app/enums/load-invoicing-status.enum';
import { LoadStatus, LoadStatusToLabelMapping } from 'src/app/enums/load-status.enum';
import { LoadStop } from 'src/app/models/load-stop.model';
import { Load } from 'src/app/models/load.model';
import { AuthService } from 'src/app/services/auth.service';
import { LoadService } from 'src/app/services/load.service';
import { UiService } from 'src/app/services/ui.service';
import { DateTimePickerComponent } from '../date-time-picker/date-time-picker.component';
import { PodUploadComponent } from '../pod-upload/pod-upload.component';

@Component({
  selector: 'app-loads-table',
  templateUrl: './loads-table.component.html',
  styleUrls: ['./loads-table.component.scss']
})
export class LoadsTableComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input('carrierId') public carrierId: number;
  @Input('clientId') public clientId: number;
  @Input('employeeId') public employeeId: number;

  @ViewChildren(DataTableDirective) private dtElems: QueryList<DataTableDirective>;

  currentQuery = '';
  filterQuery$ = new Subject<string>();
  filterQueryDebounced$ = new Observable<string>();

  destroy$ = new Subject<null>();

  selectedFilter$: BehaviorSubject<LoadFilter> = new BehaviorSubject(null);
  LoadFilter = LoadFilter;

  loadStatuses = Object.values(LoadStatus).filter(v => typeof v === 'number');
  loadStatusToLabelMapping = LoadStatusToLabelMapping;

  loadInvoicingStatuses = Object.values(LoadInvoicingStatus).filter(v => typeof v === 'number');
  loadInvoicingStatusToLabelMapping = LoadInvoicingStatusToLabelMapping;

  dtOptions: DataTables.Settings = {};
  dtReceivableOptions: DataTables.Settings = {};
  dtPayableOptions: DataTables.Settings = {};
  dtInvoicingOptions: DataTables.Settings = {};

  loads: Load[];
  statusSelectLoading: boolean[] = [];
  invoicingStatusSelectLoading: boolean[] = [];

  constructor(
    private loadService: LoadService,
    private dialog: MatDialog,
    private uiService: UiService,
    public auth: AuthService,
    private router: Router,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.loadService.dashboardLoadCount = null;

    this.auth.isOps$.subscribe(isOps => {
      let dtColumns = [
        { name: 'visual_id', orderable: true },
        { name: 'pickup_date', orderable: false },
        { name: 'dropoff_date', orderable: false },
        { name: 'status', orderable: true },
        { name: 'client', orderable: false },
        { name: 'carrier', orderable: false },
        { name: 'pickup_location', orderable: false },
        { name: 'dropoff_location', orderable: false },
        { name: 'revenue', orderable: true },
        { name: 'client_accessorial', orderable: true },
        { name: 'carrier_accessorial', orderable: true },
        { name: 'compensation', orderable: true },
        { name: 'margin', orderable: true },
        { name: 'deadline', orderable: true },
        { name: 'requires_assistance', orderable: false },
      ];

      if (isOps) {
        dtColumns.splice(4, 0, { name: 'invoicing_status', orderable: true });
      }

      this.dtOptions = this.buildDtOptions(dtColumns);

      dtColumns = [
        { name: 'visual_id', orderable: true },
        { name: 'client_payment_due_date', orderable: true },
        { name: 'invoicing_status', orderable: false },
        { name: 'client', orderable: false },
        { name: 'client_accessorial', orderable: false },
        { name: 'compensation', orderable: false },
      ];

      this.dtReceivableOptions = this.buildDtOptions(dtColumns);

      dtColumns = [
        { name: 'visual_id', orderable: true },
        { name: 'carrier_payment_due_date', orderable: true },
        { name: 'invoicing_status', orderable: false },
        { name: 'carrier', orderable: false },
        { name: 'carrier_accessorial', orderable: false },
        { name: 'revenue', orderable: false },
      ];

      this.dtPayableOptions = this.buildDtOptions(dtColumns);

      dtColumns = [
        { name: 'visual_id', orderable: true },
        { name: 'invoicing_status', orderable: false },
        { name: 'client', orderable: false },
        { name: 'carrier', orderable: false },
        { name: 'revenue', orderable: false },
        { name: 'client_accessorial', orderable: false },
        { name: 'carrier_accessorial', orderable: false },
        { name: 'compensation', orderable: false },
        { name: 'margin', orderable: false }
      ]

      this.dtInvoicingOptions = this.buildDtOptions(dtColumns);
    });

    this.filterQueryDebounced$ = this.filterQuery$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(query => this.currentQuery = query),
      takeUntil(this.destroy$)
    );
  }

  ngAfterViewInit(): void {
    this.selectedFilter$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.getActiveDtElem().then(dtElem => {
        dtElem.dtInstance.then((dtInstance: DataTables.Api) => {
          this.loads = null;
          dtInstance.ajax.reload();
        });
      });
    });

    this.filterQueryDebounced$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(_ => {
      this.getActiveDtElem().then(dtElem => {
        dtElem.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.ajax.reload();
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  optionOnChange(id: number, newStatus: number | LoadStatus, selectElem: HTMLSelectElement) {
    newStatus = LoadStatus[LoadStatus[newStatus]];
    if (newStatus !== LoadStatus.PodDelivered) {
      const dialog = this.dialog.open(DateTimePickerComponent, {
        disableClose: true,
        data: {
          showDeadlinePicker: true,
          showNotesTextArea: true
        }
      });
      dialog.afterClosed().pipe(
        takeUntil(this.destroy$)
      ).subscribe(({ proceed, result, notes }) => {
        if (proceed) {
          const i = this.loads.findIndex(load => load.id === id);
          const prevStatus = this.loads[i].status;
          this.loads[i].status = newStatus;
          this.statusSelectLoading[i] = true;
          this.loadService.changeLoadStatus(id, newStatus, result, notes).pipe(
            finalize(() => this.statusSelectLoading[i] = false),
            takeUntil(this.destroy$)
          ).subscribe(success => {
            if (!success) {
              selectElem.selectedIndex = prevStatus;
              this.loads[i].status = prevStatus;
            } else {
              this.getActiveDtElem().then(dtElem => {
                dtElem.dtInstance.then((dtInstance: DataTables.Api) => {
                  dtInstance.ajax.reload();
                });
              });
            }
          }, err => {
            selectElem.selectedIndex = prevStatus;
            this.loads[i].status = prevStatus;
            if (err.error?.message) {
              this.snackbar.open(err.error.message, null, {
                duration: 5000
              });
            }
          });
        } else {
          this.revertLoadStatus(id, selectElem);
        }
      });
    } else {
      const dialog = this.dialog.open(PodUploadComponent, {
        disableClose: true,
        data: {
          showNotesTextArea: true
        }
      });
      dialog.afterClosed().pipe(
        takeUntil(this.destroy$)
      ).subscribe(({ proceed, result, notes }) => {
        if (proceed) {
          const i = this.loads.findIndex(load => load.id === id);
          const prevStatus = this.loads[i].status;
          this.loads[i].status = newStatus;
          this.statusSelectLoading[i] = true;
          this.loadService.markAsPodDelivered(id, result, notes).pipe(
            finalize(() => this.statusSelectLoading[i] = false),
            takeUntil(this.destroy$)
          ).subscribe(success => {
            if (!success) {
              selectElem.selectedIndex = prevStatus;
              this.loads[i].status = prevStatus;
            } else {
              this.getActiveDtElem().then(dtElem => {
                dtElem.dtInstance.then((dtInstance: DataTables.Api) => {
                  dtInstance.ajax.reload();
                });
              });
            }
          }, err => {
            selectElem.selectedIndex = prevStatus;
            this.loads[i].status = prevStatus;
            if (err.error?.message) {
              this.snackbar.open(err.error.message, null, {
                duration: 5000
              });
            }
          });
        } else {
          this.revertLoadStatus(id, selectElem);
        }
      });
    }
  }

  invoicingOptionOnChange(id: number, newStatus: number | LoadInvoicingStatus, selectElem: HTMLSelectElement) {
    newStatus = LoadInvoicingStatus[LoadInvoicingStatus[newStatus]];
    const i = this.loads.findIndex(load => load.id === id);
    if (newStatus === LoadInvoicingStatus.InvoicedClient || newStatus === LoadInvoicingStatus.CarrierInvoiceReceived) {
      const dialog = this.dialog.open(DateTimePickerComponent, {
        disableClose: true,
        data: {
          showDeadlinePicker: true,
          showNotesTextArea: true,
          showFileUpload: true,
          showTime: false,
          titleLabel: 'Set due date for payment',
          subtitleLabel: newStatus === LoadInvoicingStatus.InvoicedClient ? `Client net terms: ${ this.loads[i].client.net_terms ?? '-' } days` : '',
          defaultMoment: newStatus === LoadInvoicingStatus.InvoicedClient && this.loads[i].client.net_terms != null ? moment().add(this.loads[i].client.net_terms, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) : null
        }
      });
      dialog.afterClosed().pipe(
        takeUntil(this.destroy$)
      ).subscribe(({ proceed, result, notes, file }: { proceed: boolean, result: moment.Moment, notes?: string, file?: File }) => {
        if (proceed) {
          const prevStatus = this.loads[i].invoicing_status;
          this.loads[i].invoicing_status = newStatus;
          this.invoicingStatusSelectLoading[i] = true;
          this.loadService.changeLoadInvoicingStatus(id, newStatus, notes, result, file).pipe(
            finalize(() => this.invoicingStatusSelectLoading[i] = false),
            takeUntil(this.destroy$)
          ).subscribe(success => {
            if (!success) {
              selectElem.selectedIndex = prevStatus;
              this.loads[i].invoicing_status = prevStatus;
            } else {
              this.getActiveDtElem().then(dtElem => {
                dtElem.dtInstance.then((dtInstance: DataTables.Api) => {
                  dtInstance.ajax.reload();
                });
              });
            }
          }, err => {
            selectElem.selectedIndex = prevStatus;
            this.loads[i].invoicing_status = prevStatus;
          });
        } else {
          const prevStatus = this.loads[i].invoicing_status;
          selectElem.selectedIndex = prevStatus;
        }
      });
    } else {
      const dialog = this.dialog.open(DateTimePickerComponent, {
        disableClose: true,
        data: {
          showDeadlinePicker: false,
          showNotesTextArea: true
        }
      });
      dialog.afterClosed().pipe(
        takeUntil(this.destroy$)
      ).subscribe(({ proceed, result, notes }: { proceed: boolean, result: moment.Moment, notes?: string }) => {
        if (proceed) {
          const prevStatus = this.loads[i].invoicing_status;
          this.loads[i].invoicing_status = newStatus;
          this.invoicingStatusSelectLoading[i] = true;
          this.loadService.changeLoadInvoicingStatus(id, newStatus, notes).pipe(
            finalize(() => this.invoicingStatusSelectLoading[i] = false),
            takeUntil(this.destroy$)
          ).subscribe(success => {
            if (!success) {
              selectElem.selectedIndex = prevStatus;
              this.loads[i].invoicing_status = prevStatus;
            } else {
              this.getActiveDtElem().then(dtElem => {
                dtElem.dtInstance.then((dtInstance: DataTables.Api) => {
                  dtInstance.ajax.reload();
                });
              });
            }
          }, err => {
            selectElem.selectedIndex = prevStatus;
            this.loads[i].invoicing_status = prevStatus;
            if (err.error?.message) {
              this.snackbar.open(err.error.message, null, {
                duration: 5000
              });
            }
          });
        } else {
          const i = this.loads.findIndex(load => load.id === id);
          const prevStatus = this.loads[i].invoicing_status;
          selectElem.selectedIndex = prevStatus;
          this.loads[i].invoicing_status = prevStatus;
        }
      });
    }
  }

  hasPassedDeadline(deadline: string): boolean {
    const dateMoment = moment.utc(deadline);
    return dateMoment.isBefore(moment());
  }

  checkboxOnChange(loadId: number, value: boolean) {
    this.loadService.updateRequiresAssistance(loadId, value).pipe(
      takeUntil(this.destroy$)
    ).subscribe();
  }

  goToLoad(id: number) {
    this.router.navigate(['/load', id]);
  }

  formatDate(date: moment.Moment) {
    return moment.utc(date).format('YYYY-MM-DD');
  }

  formatDateTime(deadline: moment.Moment) {
    return moment.utc(deadline).local().format('YYYY-MM-DD HH:mm');
  }

  filterQueryChanged(query: string) {
    this.filterQuery$.next(query);
  }

  formatNumber(n: number): string {
    return n ? n.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    }) : '0';
  }

  firstPickupStop(load: Load): LoadStop {
    return load.pickup_stops != null && load.pickup_stops.length > 0 ? load.pickup_stops[0] : null;
  }

  firstDropoffStop(load: Load): LoadStop {
    return load.dropoff_stops != null && load.dropoff_stops.length > 0 ? load.dropoff_stops[0] : null;
  }

  private revertLoadStatus(id: number, selectElem: HTMLSelectElement) {
    const i = this.loads.findIndex(load => load.id === id);
    const prevStatus = this.loads[i].status;
    selectElem.selectedIndex = prevStatus;
    this.loads[i].status = prevStatus;
  }

  private buildDtOptions(columns) {
    return {
      pagingType: 'simple_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      lengthChange: false,
      searching : false,
      scrollY: '100%',
      scrollX: true,
      scrollCollapse: true,
      autoWidth: false,
      columns,
      order: [[0, 'asc']],
      deferLoading: 0,
      ajax: (dtParams, callback) => {
        this.route.data.pipe(switchMap(data => {
          if (this.carrierId != null) {
            return this.loadService.getLoadsForCarrier(this.carrierId, dtParams, this.selectedFilter$.getValue(), this.currentQuery);
          } else if (this.clientId != null) {
            return this.loadService.getLoadsForClient(this.clientId, dtParams, this.selectedFilter$.getValue(), this.currentQuery);
          } else if (this.employeeId != null) {
            return this.loadService.getLoadsForEmployee(this.employeeId, dtParams, this.selectedFilter$.getValue(), this.currentQuery);
          } else if (data.allLoads) {
            this.uiService.setHeaderTitle('Loads List (all)');
            return this.loadService.getAllLoads(dtParams, this.selectedFilter$.getValue(), this.currentQuery);
          } else {
            this.uiService.setHeaderTitle('Loads List');
            return this.loadService.getMyLoads(dtParams, this.selectedFilter$.getValue(), this.currentQuery);
          }
        }), takeUntil(this.destroy$), takeUntil(this.filterQueryDebounced$)).subscribe(res => {
          this.loads = res.data;
          this.loadService.dashboardLoadCount = res.recordsTotal;
          this.statusSelectLoading = Array(res.data.length).fill(false);
          this.invoicingStatusSelectLoading = Array(res.data.length).fill(false);
          callback({
            recordsTotal: res.recordsTotal,
            recordsFiltered: res.recordsFiltered,
            data: []
          });
        });
      },
      drawCallback: () => {
        setTimeout(() => {
          this.getActiveDtElem().then(dtElem => {
            dtElem.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.columns.adjust();
            });
          });
        });
      },
    };
  }

  private async getActiveDtElem(): Promise<DataTableDirective> {
    return this.auth.isOps$.pipe(
      first(),
      map(async isOps => {
        const selectedReceivable = this.selectedFilter$.value === LoadFilter.AccountsReceivable;
        const selectedPayable = this.selectedFilter$.value === LoadFilter.AccountsPayable;
        const selectedInvoicing = isOps && this.selectedFilter$.value === LoadFilter.Active;
        let activeDtElem: DataTableDirective;
        for (const dtElem of this.dtElems) {
          const dtInstance = await dtElem.dtInstance;
          const elem = dtInstance.table(0).node() as HTMLElement;
          const tableReceivable = elem.id === 'table-receivable';
          const tablePayable = elem.id === 'table-payable';
          const tableInvoicing = elem.id === 'table-invoicing';
          if (selectedReceivable && tableReceivable ||
            selectedPayable && tablePayable ||
            selectedInvoicing && tableInvoicing ||
            !selectedReceivable && !selectedPayable && !selectedInvoicing && !tableReceivable && !tablePayable && !tableInvoicing
          ) {
            activeDtElem = dtElem;
          }
        }
        return activeDtElem;
      })
    ).toPromise();
  }

}
