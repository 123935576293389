import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { FilePond, FilePondOptions } from 'filepond';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadService } from 'src/app/services/load.service';
import { finalize } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-send-rate-con-email',
  templateUrl: './send-rate-con-email.component.html',
  styleUrls: ['./send-rate-con-email.component.scss']
})
export class SendRateConEmailComponent implements OnInit {

  @ViewChild('attachmentsPond') attachmentsPond: FilePond;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  displaySkip = true;

  loadId: number;
  emailsTo = [];
  emailsCc = [];
  emailContent = 'Your rate con is attached to this email.\n\nThank you.';

  isLoading = false;
  errors = [];

  pondOptions: FilePondOptions = {
    labelIdle: 'Drop files here',
    allowMultiple: true,
    credits: false
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<SendRateConEmailComponent>,
    private loadService: LoadService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    if (this.data.emailsTo == null) {
      this.data.emailsTo = [];
    }
    if (this.data.emailsCc == null) {
      this.data.emailsCc = [];
    }

    this.loadId = this.data.loadId;
    this.emailsTo = this.emailsTo.concat(this.data.emailsTo);
    this.emailsCc = this.emailsCc.concat(this.data.emailsCc);
    this.displaySkip = this.data.displaySkip ?? true;
  }

  addEmailTo(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.emailsTo.push(value);
    }
    event.input.value = '';
  }

  removeEmailTo(email: string): void {
    const index = this.emailsTo.indexOf(email);
    if (index >= 0) {
      this.emailsTo.splice(index, 1);
    }
  }

  addEmailCc(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.emailsCc.push(value);
    }
    event.input.value = '';
  }

  removeEmailCc(email: string): void {
    const index = this.emailsCc.indexOf(email);
    if (index >= 0) {
      this.emailsCc.splice(index, 1);
    }
  }

  cancel() {
    this.dialogRef.close({ skipped: true });
  }

  send() {
    if (this.emailsTo.length === 0) {
      this.errors.push('The "to" field should contain at least one email');
      return;
    }
    this.isLoading = true;
    this.errors = [];
    let files = this.attachmentsPond.getFiles().map(file => file.file as File);
    this.loadService.sendRateConEmail(this.loadId, this.emailsTo, this.emailsCc, this.emailContent, files).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      this.snackbar.open('Rate con email sent', null, {
        duration: 3000
      });
      this.dialogRef.close({ success: true });
    }, err => {
      console.log(err);
      if (err.error?.errors) {
        this.errors = [].concat.apply([], Object.values(err.error.errors));
      } else if (err.error?.message) {
        this.errors = [err.error.message];
      } else {
        this.errors = ['An unexpected error has occurred. Please try again or contact administrator.'];
      }
    });
  }

}
