<div class="buttons-container">
  <a class="btn-secondary" (click)="goBack()">&lt; Back</a>
  <div class="right-buttons-container">
    <button class="btn-secondary" (click)="convertToLoad()" [disabled]="isLoading">Convert To Load</button>
    <button class="btn-primary" (click)="goToEditQuote()" [disabled]="isLoading">Edit Quote</button>
    <button class="btn-round btn-delete" (click)="deleteQuote()" [disabled]="isLoading" *ngIf="(auth.isAdmin$ | async) || (auth.userId$ | async) === quote?.owner_id"><mat-icon>delete_outlined</mat-icon></button>
  </div>
</div>
<div *ngIf="quote == null || isLoading" class="loading-text">Loading...</div>
<div class="content-container" *ngIf="quote != null && !isLoading">
  <div class="row">
    <h1><span class="label">Quote #</span>{{ quote?.id }}</h1>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Owner: </span>{{ quote?.owner.user_info.first_name + ' ' + quote?.owner.user_info.last_name }}</div>
    <div class="property-container"><span class="label">Client: </span>{{ quote?.client.name }}</div>
    <div class="property-container"><span class="label">Rate: </span>${{ quote?.rate }}</div>
  </div>
  <hr />
  <div class="row">
    <h2>Pickup Details</h2>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Location: </span>{{ quoteService.getPickupLocation(quote) }}</div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Notes: </span>{{ quote?.pickup_shipment_description ? quote.pickup_shipment_description : '-' }}</div>
  </div>
  <hr />
  <div class="row">
    <h2>Dropoff Details</h2>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Location: </span>{{ quoteService.getDropoffLocation(quote) }}</div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Notes: </span>{{ quote?.dropoff_shipment_description ? quote.dropoff_shipment_description : '-' }}</div>
  </div>
  <hr />
  <div class="row">
    <h2>Equipment</h2>
  </div>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
    <thead>
      <tr *ngIf="quote?.equipments != null && quote?.equipments.length > 0">
        <th></th>
        <th>Name</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let equipment of quote?.equipments; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ equipment.name }}</td>
      </tr>
      <tr *ngIf="quote != null && (quote.equipments == null || quote.equipments.length === 0)">
        <td class="center-text" colspan="2">No data found</td>
      </tr>
    </tbody>
  </table>
</div>
