export enum LoadInvoicingStatus {
  PendingInvoice = 0,
  InvoicedClient = 1,
  CarrierInvoiceReceived = 2,
  ClientPaid = 3,
  CarrierPaid = 4,
}

export const LoadInvoicingStatusToLabelMapping: Record<LoadInvoicingStatus, string> = {
  [LoadInvoicingStatus.PendingInvoice]: 'Pending Invoice',
  [LoadInvoicingStatus.InvoicedClient]: 'Invoiced Client',
  [LoadInvoicingStatus.CarrierInvoiceReceived]: 'Carrier Invoice Received',
  [LoadInvoicingStatus.ClientPaid]: 'Client Paid',
  [LoadInvoicingStatus.CarrierPaid]: 'Carrier Paid',
}
