import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private key = 'auth_token';
  private issuer = {
    login: `${ environment.apiUrl }/auth/login`,
    refresh: `${ environment.apiUrl }/auth/refresh`,
    register: `${ environment.apiUrl }/auth/register`,
  };

  constructor() { }

  setToken(token: string) {
    localStorage.setItem(this.key, token);
  }

  getToken(): string {
    return localStorage.getItem(this.key);
  }

  removeToken() {
    localStorage.removeItem(this.key);
  }

  isLoggedIn(): boolean {
    const token = this.getToken();
    if (token) {
      const payload = this.payload(token);
      if (payload) {
        return Object.values(this.issuer).indexOf(payload.iss) > -1
          ? true
          : false;
      }
    } else {
      return false;
    }
  }

  private payload(token: any) {
    const jwtPayload = token.split('.')[1];
    return JSON.parse(atob(jwtPayload));
  }
}
