import { MapsAPILoader } from '@agm/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilePond, FilePondErrorDescription, FilePondFile, FilePondOptions } from 'filepond';
import { from, Subject } from 'rxjs';
import { finalize, takeUntil, map } from 'rxjs/operators';
import { Role, RoleToLabelMapping } from 'src/app/enums/role.enum';
import { Paperwork } from 'src/app/models/carrier-paperwork.model';
import { UserCreate } from 'src/app/models/user-create.model';
import { AuthService } from 'src/app/services/auth.service';
import { GeocodingService } from 'src/app/services/geocoding.service';
import { UiService } from 'src/app/services/ui.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss']
})
export class AddEmployeeComponent implements OnInit, OnDestroy {

  @ViewChild('pond') pond: FilePond;
  @ViewChild('addressText') addressText: ElementRef;

  currentPaperwork: Paperwork[] = [];

  pondOptions: FilePondOptions = {
    allowMultiple: true,
    labelIdle: 'Drop paperwork files here',
    credits: false
  };

  roles = this.auth.isAdmin$.pipe(
    map(isAdmin => {
      let roles = Object.values(Role).filter(v => typeof v === 'number');
      if (!isAdmin) {
        roles = roles.filter(v => v !== Role.Admin);
      }
      return roles;
    })
  );
  roleToLabelMapping = RoleToLabelMapping;

  employee = new UserCreate();

  headerTitle = 'Add Employee';
  errors: string[] = [];
  isSaving = false;
  successText = 'Employee created';

  mapLoaded = false;

  destroy$ = new Subject<null>();

  constructor(
    private uiService: UiService,
    private location: Location,
    private httpClient: HttpClient,
    private userService: UserService,
    private snackbar: MatSnackBar,
    private mapLoader: MapsAPILoader,
    private ngZone: NgZone,
    private geocodingService: GeocodingService,
    private auth: AuthService
  ) {
    if (this.userService.userToEdit != null) {
      this.employee = new UserCreate(this.userService.userToEdit);
      this.userService.userToEdit = null;
      this.uiService.setHeaderTitle('Edit Employee');
      this.headerTitle = 'Edit Employee';
      this.successText = 'Employee updated';
    } else {
      this.uiService.setHeaderTitle('Add New Employee');
    }
    this.uiService.setGrayBg(true);
  }

  ngOnInit(): void {
    this.currentPaperwork = [...this.employee.paperwork];

    from(this.mapLoader.load()).subscribe(() => {
      this.mapLoaded = true;
      this.setupAutocomplete();
    });
  }

  ngOnDestroy(): void {
    this.uiService.setGrayBg(false);
    this.destroy$.next();
  }

  private setupAutocomplete() {
    let addressAutocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement, {
      componentRestrictions: { country: 'US' }
    });
    google.maps.event.addListener(addressAutocomplete, 'place_changed', () => {
      let place = addressAutocomplete.getPlace();
      let placeAddress = this.geocodingService.deconstructGooglePlaceResult(place);
      this.ngZone.run(() => {
        this.employee.address = placeAddress.address;
        this.employee.city = placeAddress.city;
        this.employee.state = placeAddress.state;
        this.employee.zip = placeAddress.zip;
      });
    });
  }

  onAddedPaperwork(e: { error: FilePondErrorDescription, file: FilePondFile }) {
    if (!e.error) {
      this.currentPaperwork.push({
        file: e.file,
        description: ''
      });
    }
  }

  removePaperwork(i: number) {
    if (this.currentPaperwork[i].file_url == null) {
      let nthOfPond = -1;
      for (let j = 0; j < this.currentPaperwork.length; j++) {
        if (this.currentPaperwork[j].file_url == null) {
          nthOfPond++;
          if (j === i) {
            break;
          }
        }
      }
      this.pond.removeFile(nthOfPond);
    }
    this.currentPaperwork.splice(i, 1);
  }

  getFilename(paperwork: Paperwork) {
    return paperwork.file?.filename ?? this.getFilenameFromUrl(paperwork.file_url);
  }

  roundSalary() {
    this.employee.salary = Math.round(this.employee.salary * 100) / 100;
  }

  private getFilenameFromUrl(url: string): string {
    let parts = url.split('/');
    return parts[parts.length - 1];
  }

  cancel() {
    this.location.back();
  }

  save() {
    this.isSaving = true;

    let formData = new FormData();
    this.employee.paperwork = this.currentPaperwork.filter(paperwork => paperwork.id != null);
    Object.keys(this.employee).forEach(key => {
      if (typeof this.employee[key] === 'object' && this.employee[key] != null) {
        Object.keys(this.employee[key]).forEach(key2 => {
          if (typeof this.employee[key][key2] === 'object' && this.employee[key][key2] != null) {
            Object.keys(this.employee[key][key2]).forEach(key3 => {
              formData.append(`${ key }[${ key2 }][${ key3 }]`, this.employee[key][key2][key3] ?? '');
            });
          } else {
            formData.append(`${ key }[${ key2 }]`, this.employee[key][key2] ?? '');
          }
        });
      } else {
        formData.append(key, this.employee[key] ?? '');
      }
    });
    const pondFiles = this.pond.getFiles();
    if (pondFiles.length > 0) {
      let numOfExistingPaperwork = this.employee.paperwork.length;
      for (let i = numOfExistingPaperwork; i < pondFiles.length + numOfExistingPaperwork; i++) {
        formData.append(`paperwork[${ i }][file]`, pondFiles[i].file);
        formData.append(`paperwork[${ i }][description]`, this.currentPaperwork[i].description);
      }
    }

    this.userService.addNewEmployee(formData).pipe(
      finalize(() => {
        this.ngZone.run(() => {
          this.isSaving = false;
        });
      }),
      takeUntil(this.destroy$)
    ).subscribe(res => {
      this.ngZone.run(() => {
        this.errors = [];
        this.location.back();
        this.snackbar.open(this.successText, null, {
          duration: 5000
        });
      });
    }, err => {
      this.ngZone.run(() => {
        if (err.error?.errors) {
          this.errors = [].concat.apply([], Object.values(err.error.errors));
        } else if (err.error?.message) {
          this.errors = [err.error.message];
        } else {
          this.errors = ['An unexpected error has occurred. Please try again or contact administrator.'];
        }
      });
    });
  }

}
