import { MapsAPILoader } from '@agm/core';
import { Location } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, NgZone, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FilePond, FilePondErrorDescription, FilePondFile, FilePondOptions } from 'filepond';
import { BehaviorSubject, concat, forkJoin, from, Observable, Subject } from 'rxjs';
import { finalize, map, switchMap, tap, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CarrierEquipment } from 'src/app/models/carrier-equipment.model';
import { Paperwork } from 'src/app/models/carrier-paperwork.model';
import { Carrier } from 'src/app/models/carrier.model';
import { FmcsaCarrierResponse } from 'src/app/models/fmcsa-carrier-response.model';
import { AuthService } from 'src/app/services/auth.service';
import { CarrierService } from 'src/app/services/carrier.service';
import { FmcsaService } from 'src/app/services/fmcsa.service';
import { GeocodingService } from 'src/app/services/geocoding.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-add-carrier',
  templateUrl: './add-carrier.component.html',
  styleUrls: ['./add-carrier.component.scss']
})
export class AddCarrierComponent implements OnInit, OnDestroy {

  @ViewChildren('equipmentSelects') equipmentSelects: QueryList<NgSelectComponent>;

  @ViewChild('carrierPacketPond') carrierPacketPond: FilePond;
  @ViewChild('insurancePond') insurancePond: FilePond;
  @ViewChild('paperworkPond') paperworkPond: FilePond;

  @ViewChildren('addressText') addressTexts: QueryList<ElementRef>;

  destroy$ = new Subject<null>();

  pondOptions: FilePondOptions = {
    labelIdle: 'Drop file here',
    allowMultiple: false,
    maxFiles: 1,
    credits: false,
    server: {
      load: (source, load, error, progress, abort, headers) => {
        this.httpClient.get(source, {
          responseType: 'blob',
          observe: 'response'
        }).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: HttpResponse<Blob>) => {
          load(res.body);
        });
      }
    }
  };

  currentPaperwork: Paperwork[] = [];

  paperworkPondOptions: FilePondOptions = {
    allowMultiple: true,
    labelIdle: 'Drop paperwork files here',
    credits: false,
    server: {
      load: (source, load, error, progress, abort, headers) => {
        this.httpClient.get(source, {
          responseType: 'blob',
          observe: 'response'
        }).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: HttpResponse<Blob>) => {
          load(res.body);
        });
      }
    }
  };

  carrier = new Carrier();
  existingCarrierPacket = [];

  fullEquipmentList$: BehaviorSubject<CarrierEquipment[]> = new BehaviorSubject([]);
  equipmentLoading = false;

  equipment$: Observable<CarrierEquipment[]>[] = [];
  equipmentInput$: Subject<string>[] = [];

  headerTitle = 'Add Carrier';
  errors: string[] = [];
  isSaving = false;
  isSearchingFmcsa = false;
  successText = 'Carrier created';

  mapLoaded = false;

  constructor(
    private uiService: UiService,
    private location: Location,
    private carrierService: CarrierService,
    private snackbar: MatSnackBar,
    private httpClient: HttpClient,
    private geocodingService: GeocodingService,
    private fmcsaService: FmcsaService,
    public auth: AuthService,
    private mapLoader: MapsAPILoader,
    private ngZone: NgZone,
    @Optional() private dialogRef: MatDialogRef<AddCarrierComponent>
  ) {
    if (this.carrierService.carrierToEdit != null) {
      this.carrier = this.carrierService.carrierToEdit;
      this.carrierService.carrierToEdit = null;
      this.uiService.setHeaderTitle('Edit Carrier');
      this.headerTitle = 'Edit Carrier';
      this.successText = 'Carrier updated';
    } else {
      this.uiService.setHeaderTitle('Add New Carrier');
    }
    this.uiService.setGrayBg(true);
  }

  ngOnInit(): void {
    this.currentPaperwork = [...this.carrier.paperwork];
    this.loadEquipment();

    from(this.mapLoader.load()).subscribe(() => {
      this.mapLoaded = true;
    });
  }

  ngOnDestroy(): void {
    this.uiService.setGrayBg(false);
    this.destroy$.next();
  }

  addAddress() {
    let is_hq = false;
    if (this.carrier.addresses.length === 0) {
      is_hq = true;
    }
    this.carrier.addresses.push({
      address: '',
      city: '',
      state: '',
      zip: '',
      is_hq
    });

    setTimeout(() => {
      let elem = this.addressTexts.last.nativeElement;
      let addressAutocomplete = new google.maps.places.Autocomplete(elem, {
        componentRestrictions: { country: 'US' }
      });
      google.maps.event.addListener(addressAutocomplete, 'place_changed', () => {
        let place = addressAutocomplete.getPlace();
        let placeAddress = this.geocodingService.deconstructGooglePlaceResult(place);
        let i;
        this.addressTexts.forEach((ref, idx) => {
          if (ref.nativeElement == elem) i = idx;
        });
        if (i != null) {
          this.ngZone.run(() => {
            this.carrier.addresses[i].address = placeAddress.address;
            this.carrier.addresses[i].city = placeAddress.city;
            this.carrier.addresses[i].state = placeAddress.state;
            this.carrier.addresses[i].zip = placeAddress.zip;
          });
        }
      });
    });
  }

  hqChecked(i: number) {
    if (this.carrier.addresses[i].is_hq) {
      for (let j = 0; j < this.carrier.addresses.length; j++) {
        if (j === i) continue;
        this.carrier.addresses[j].is_hq = false;
      }
    } else {
      if (this.carrier.addresses.length > 0) {
        this.carrier.addresses[0].is_hq = true;
      }
    }
  }

  removeAddress(i: number) {
    let changeHq = false;
    if (this.carrier.addresses[i].is_hq) {
      changeHq = true;
    }
    this.carrier.addresses.splice(i, 1);
    if (changeHq && this.carrier.addresses.length > 0) {
      this.carrier.addresses[0].is_hq = true;
    }
  }

  addAgent() {
    this.carrier.booking_agents.push({
      name: '',
      email: '',
      phone: ''
    });
  }

  removeAgent(i: number) {
    this.carrier.booking_agents.splice(i, 1);
  }

  addDriver() {
    this.carrier.drivers.push({
      name: '',
      phone: '',
      driver_num: '',
      tractor_num: '',
      trailer_num: ''
    });
  }

  removeDriver(i: number) {
    this.carrier.drivers.splice(i, 1);
  }

  addEquipment() {
    this.carrier.equipments.push({
      name: ''
    });
    this.setupEquipmentFields();
  }

  private setupEquipmentFields() {
    const typeahead = new Subject<string>();
    this.equipmentInput$.push(typeahead);
    this.equipment$.push(concat(this.fullEquipmentList$, typeahead.pipe(
      distinctUntilChanged(),
      map(term => this.fullEquipmentList$.getValue().filter(e => e.name.toLowerCase().includes(term.toLowerCase())))
    )));
  }

  removeEquipment(i: number) {
    this.carrier.equipments.splice(i, 1);
    this.equipmentInput$.splice(i, 1);
    this.equipment$.splice(i, 1);
  }

  trackEquipmentByFn(item: CarrierEquipment) {
    return item.id;
  }

  createEquipment = (name: string) => {
    this.equipmentSelects.forEach(ref => ref.close());
    this.equipmentLoading = true;
    return new Promise((resolve, reject) => {
      this.carrierService.createCarrierEquipment(name).pipe(
        finalize(() => this.equipmentLoading = false),
        takeUntil(this.destroy$)
      ).subscribe(equipmentId => {
        const equipment = {
          id: equipmentId,
          name
        };
        let equipmentList = this.fullEquipmentList$.getValue();
        equipmentList.push(equipment);
        equipmentList = equipmentList.sort((a, b) => a.name < b.name ? -1 : 1);
        this.fullEquipmentList$.next(equipmentList);
        resolve(equipment);
      }, err => {
        this.snackbar.open('Failed to create equipment. Please try again or contact administrator.', null, {
          duration: 5000
        });
        reject('No equipment created');
      });
    });
  }

  private loadEquipment() {
    this.equipmentLoading = true;
    this.carrierService.getAllEquipment().pipe(
      finalize(() => this.equipmentLoading = false),
      takeUntil(this.destroy$)
    ).subscribe(res => this.fullEquipmentList$.next(res));

    for (let _ of this.carrier.equipments) {
      this.setupEquipmentFields();
    }
  }

  onAddedPaperwork(e: { error: FilePondErrorDescription, file: FilePondFile }) {
    if (!e.error) {
      this.currentPaperwork.push({
        file: e.file,
        description: ''
      });
    }
  }

  removePaperwork(i: number) {
    if (this.currentPaperwork[i].file_url == null) {
      let nthOfPond = -1;
      for (let j = 0; j < this.currentPaperwork.length; j++) {
        if (this.currentPaperwork[j].file_url == null) {
          nthOfPond++;
          if (j === i) {
            break;
          }
        }
      }
      this.paperworkPond.removeFile(nthOfPond);
    }
    this.currentPaperwork.splice(i, 1);
  }

  cancel() {
    if (this.dialogRef == null) {
      this.location.back();
    } else {
      this.dialogRef.close();
    }
  }

  save() {
    this.isSaving = true;
    this.errors = [];

    let latLngObs: Observable<google.maps.GeocoderResult[]>[] = [];

    for (let loc of this.carrier.addresses) {
      let { address, city, state, zip } = loc;
      if (address.trim() === '' || city.trim() === '' || state.trim() === '' || zip.trim() === '') {
        this.errors.push('All address fields must be filled in');
        this.isSaving = false;
        return;
      }
      const fullAddress = `${ address }, ${ city } ${ state } ${ zip }`;
      latLngObs.push(this.geocodingService.getLatLng(fullAddress));
    }

    if (latLngObs.length === 0) {
      latLngObs.push(new Observable(observer => {
        observer.next(null);
        observer.complete();
      }));
    }

    forkJoin(latLngObs).pipe(
      tap(([...latLngArr]) => {
        for (let i = 0; i < latLngArr.length; i++) {
          let res = latLngArr[i];
          if (res == null) {
            continue;
          }
          if (res.length) {
            const locResult = res[0].geometry.location;
            this.carrier.addresses[i].lat = locResult.lat();
            this.carrier.addresses[i].lng = locResult.lng();
            let foundLocality = false;
            for (const c of res[0].address_components) {
              const types = c.types;
              if (types.includes('administrative_area_level_1')) {
                this.carrier.addresses[i].state = c.short_name;
              } else if (types.includes('locality')) {
                this.carrier.addresses[i].city = c.long_name;
                foundLocality = true;
              } else if (types.includes('administrative_area_level_3') && !foundLocality) { // do not overwrite locality
                this.carrier.addresses[i].city = c.long_name;
              }
            }
          } else {
            this.errors.push(`Address #${ i+1 } (${ this.carrier.addresses[i].address }) could not be pinned to a set of coordinates. Please check to see that it is a valid address`);
            throw new Error();
          }
        }
      }),
      map(() => {
        let formData = new FormData();
        if (this.carrierPacketPond.getFile() != null) {
          formData.append('carrier_packet', this.carrierPacketPond.getFile().file);
        }
        if (this.insurancePond.getFile() != null) {
          formData.append('insurance', this.insurancePond.getFile().file);
        }
        this.carrier.paperwork = [];
        this.carrier.paperwork_ids = this.currentPaperwork.filter(paperwork => paperwork.id != null).map(paperwork => paperwork.id);
        Object.keys(this.carrier).forEach(key => {
          if (typeof this.carrier[key] === 'object' && this.carrier[key] != null) {
            Object.keys(this.carrier[key]).forEach(key2 => {
              if (typeof this.carrier[key][key2] === 'object' && this.carrier[key][key2] != null) {
                Object.keys(this.carrier[key][key2]).forEach(key3 => {
                  if (this.carrier[key][key2][key3] != null) {
                    formData.append(`${ key }[${ key2 }][${ key3 }]`, this.carrier[key][key2][key3]);
                  }
                });
              } else if (this.carrier[key][key2] != null) {
                formData.append(`${ key }[${ key2 }]`, this.carrier[key][key2]);
              }
            });
          } else if (this.carrier[key] != null) {
            formData.append(key, this.carrier[key]);
          }
        });
        const addedPaperwork = this.currentPaperwork.filter(paperwork => paperwork.id == null);
        const paperworkPondFiles = this.paperworkPond.getFiles();
        if (paperworkPondFiles.length > 0) {
          for (let i = 0; i < paperworkPondFiles.length; i++) {
            formData.append(`paperwork[${ i }][file]`, paperworkPondFiles[i].file);
            formData.append(`paperwork[${ i }][description]`, addedPaperwork[i].description);
          }
        }
        return formData;
      }),
      switchMap(data => this.carrierService.addNewCarrier(data)),
      finalize(() => {
        this.ngZone.run(() => {
          this.isSaving = false;
        });
      }),
      takeUntil(this.destroy$)
    ).subscribe(res => {
      this.ngZone.run(() => {
        this.errors = [];
        if (this.dialogRef == null) {
          this.location.back();
          this.snackbar.open(this.successText, null, {
            duration: 5000
          });
        } else {
          this.carrier.id = res;
          this.dialogRef.close({ created: true, item: this.carrier });
        }
      });
    }, err => {
      this.ngZone.run(() => {
        if (err.error?.errors) {
          this.errors = [].concat.apply([], Object.values(err.error.errors));
        } else if (err.error?.message) {
          this.errors = [err.error.message];
        } else if (this.errors.length === 0) {
          this.errors = ['An unexpected error has occurred. Please try again or contact administrator.'];
        }
      });
    });
  }

  carrierPacketRemoved() {
    this.carrier.carrier_packet_url = null;
  }

  insuranceRemoved() {
    this.carrier.insurance_url = null;
  }

  getFilename(paperwork: Paperwork) {
    return paperwork.file?.filename ?? this.getFilenameFromUrl(paperwork.file_url);
  }

  private getFilenameFromUrl(url: string): string {
    let parts = url.split('/');
    return parts[parts.length - 1];
  }

  searchFmcsaCarrierByMc() {
    this.searchFmcsaCarrier(this.fmcsaService.getCarriersByMcNum(this.carrier.mc_num));
  }

  searchFmcsaCarrierByDot() {
    this.searchFmcsaCarrier(this.fmcsaService.getCarriersByDotNum(this.carrier.dot_num));
  }

  private searchFmcsaCarrier(searchResult: Observable<FmcsaCarrierResponse>) {
    this.isSearchingFmcsa = true;
    const snackbarRef = this.snackbar.open('Searching FMCSA database for carrier...', null, {
      duration: 3000
    });
    searchResult.pipe(
      finalize(() => {
        this.isSearchingFmcsa = false;
        snackbarRef.dismiss();
      }),
      takeUntil(this.destroy$)
    ).subscribe(res => {
      let fmcsaCarrier = null;
      if (Array.isArray(res.content) && res.content.length > 0) {
        fmcsaCarrier = res.content[0].carrier;
      } else if (!Array.isArray(res.content)) {
        fmcsaCarrier = res.content.carrier;
      }
      if (fmcsaCarrier != null) {
        this.carrier.company_name = fmcsaCarrier.legalName;
        this.carrier.business_name = fmcsaCarrier.legalName;
        this.carrier.power_units = fmcsaCarrier.totalPowerUnits;
        const i = this.carrier.addresses.findIndex(address => address.is_hq);
        this.carrier.addresses.splice(i, 1);
        this.carrier.addresses.unshift({
          is_hq: true,
          address: fmcsaCarrier.phyStreet,
          city: fmcsaCarrier.phyCity,
          state: fmcsaCarrier.phyState,
          zip: fmcsaCarrier.phyZipcode
        });
      } else {
        this.showFmcsaCarrierNotFoundSnackbar();
      }
    }, err => {
      this.showFmcsaCarrierNotFoundSnackbar();
    })
  }

  private showFmcsaCarrierNotFoundSnackbar() {
    this.snackbar.open('Not found in FMCSA database', null, {
      duration: 5000
    });
  }

}
