import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CarrierNote } from 'src/app/models/carrier-note.model';
import { CarrierService } from 'src/app/services/carrier.service';

@Component({
  selector: 'app-add-carrier-note',
  templateUrl: './add-carrier-note.component.html',
  styleUrls: ['./add-carrier-note.component.scss']
})
export class AddCarrierNoteComponent implements OnInit, OnDestroy {

  note: CarrierNote;
  isAdding = false;

  destroy$ = new Subject<null>();

  errors: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AddCarrierNoteComponent>,
    private carrierService: CarrierService
  ) { }

  ngOnInit(): void {
    this.note = new CarrierNote();
    if (this.data.id != null) {
      this.note.id = this.data.id;
      this.note.content = this.data.content;
    }
    this.note.carrier_id = this.data.carrier_id;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  addNote() {
    this.isAdding = true;
    this.carrierService.addOrEditCarrierNote(this.note).pipe(
      finalize(() => this.isAdding = false),
      takeUntil(this.destroy$)
    ).subscribe(res => {
      this.dialogRef.close({ success: true, note: res });
    }, err => {
      if (err.error?.errors) {
        this.errors = [].concat.apply([], Object.values(err.error.errors));
      } else if (err.error?.message) {
        this.errors = [err.error.message];
      } else {
        this.errors = ['An unexpected error has occurred. Please try again or contact administrator.'];
      }
    });
  }

}
