<div class="buttons-container">
  <a class="btn-secondary" [routerLink]="['/clients']">&lt; Back</a>
  <div class="right-buttons-container">
    <button class="btn-secondary" (click)="addLoad()" [disabled]="isLoading">Add Load</button>
    <button class="btn-secondary" (click)="changeOwner()" *ngIf="auth.isAdmin$ | async" [disabled]="isLoading">Change Owner</button>
    <button class="btn-primary" (click)="goToEditClient()" [disabled]="isLoading">Edit Client</button>
    <button class="btn-round btn-delete" (click)="deleteClient()" [disabled]="isLoading" *ngIf="(auth.isAdmin$ | async) || (auth.userId$ | async) === client?.owner?.id"><mat-icon>delete_outlined</mat-icon></button>
  </div>
</div>
<div *ngIf="client == null" class="loading-text">Loading...</div>
<div class="content-container" *ngIf="client != null">
  <div class="row">
    <h1>{{ client?.name }}</h1>
    <span class="status-pill" [class.stale]="client.last_load_days_ago > 10">{{ client.last_load_days_ago <= 10 ? 'Active' : 'Stale ' + client.last_load_days_ago + ' days' }}</span>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Net Terms: </span>{{ client?.net_terms ? client.net_terms : '-' }}</div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Address: </span>{{ client?.address }}</div>
    <div class="property-container"><span class="label">Owner: </span>{{ client?.owner.user_info.first_name + ' ' + client?.owner.user_info.last_name }}</div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Revenue: </span>${{ client?.revenue?.toFixed(2) }}</div>
    <div class="property-container"><span class="label">Client Accessorial: </span>${{ client?.client_accessorial?.toFixed(2) }}</div>
    <div class="property-container"><span class="label">Carrier Accessorial: </span>${{ client?.carrier_accessorial?.toFixed(2) }}</div>
    <div class="property-container"><span class="label">Line Haul: </span>${{ client?.compensation?.toFixed(2) }}</div>
    <div class="property-container"><span class="label">Margin: </span>${{ client?.margin?.toFixed(2) }}</div>
  </div>
  <div class="row">
    <h2>Representatives</h2>
  </div>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
    <thead>
      <tr>
        <th></th>
        <th>Name</th>
        <th>Email</th>
        <th>Phone</th>
        <th>Title</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let rep of client?.reps; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ rep.name }}</td>
        <td>{{ rep.email ? rep.email : '-' }}</td>
        <td>{{ rep.phone ? rep.phone : '-' }}</td>
        <td>{{ rep.title ? rep.title : '-' }}</td>
      </tr>
      <tr *ngIf="client != null && (client.reps == null || client.reps.length === 0)">
        <td class="center-text" colspan="5">No data found</td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <h2>Business Locations</h2>
  </div>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
    <thead>
      <tr>
        <th></th>
        <th>Label</th>
        <th>Address</th>
        <th>City</th>
        <th>State</th>
        <th>ZIP</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let location of client?.business_locations; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ location.label }}</td>
        <td>{{ location.address }}</td>
        <td>{{ location.city }}</td>
        <td>{{ location.state }}</td>
        <td>{{ location.zip }}</td>
      </tr>
      <tr *ngIf="client != null && (client.business_locations == null || client.business_locations.length === 0)">
        <td class="center-text" colspan="6">No data found</td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <h2>Loads</h2>
  </div>
  <app-loads-table [clientId]="id"></app-loads-table>
  <div class="row">
    <h2>Documents</h2>
  </div>
  <div class="upload-container">
    <file-pond #uploadPond [options]="pondOptions"
      (onaddfile)="fileAdded()">
    </file-pond>
  </div>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover display nowrap">
    <thead>
      <tr>
        <th>Document #</th>
        <th>Description</th>
        <th>Upload Date</th>
        <th></th>
        <th *ngIf="auth.isAdmin$ | async"></th>
      </tr>
    </thead>
    <tbody *ngIf="client?.documents?.length !== 0">
      <tr *ngFor="let document of client?.documents; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ document.description ? document.description : '-' }}</td>
        <td>{{ formatDate(document.created_at) }}</td>
        <td><a href="{{ document.file_url }}" target="_blank">Link</a></td>
        <td *ngIf="auth.isAdmin$ | async">
          <button class="btn-round btn-delete" (click)="deleteDocument(document.id)"><mat-icon>delete_outlined</mat-icon></button>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="client?.documents?.length === 0">
      <tr>
        <td colspan="4" *ngIf="!(auth.isAdmin$ | async)">No data found</td>
        <td colspan="5" *ngIf="auth.isAdmin$ | async">No data found</td>
      </tr>
    </tbody>
  </table>
</div>
