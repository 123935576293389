import { Role } from "../enums/role.enum";
import { Paperwork } from "./carrier-paperwork.model";
import { User } from "./user.model";

export class UserCreate {
  id?: number;
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  salary: number;
  notes: string;
  paperwork: Paperwork[];
  role: Role;

  constructor(user?: User) {
    this.paperwork = [];
    this.role = Role.Employee;
    if (user) {
      this.id = user.id;
      this.email = user.email;
      this.phone = user.user_info.phone;
      this.first_name = user.user_info.first_name;
      this.last_name = user.user_info.last_name;
      this.date_of_birth = user.user_info.date_of_birth;
      this.address = user.user_info.address;
      this.city = user.user_info.city;
      this.state = user.user_info.state;
      this.zip = user.user_info.zip;
      this.salary = user.user_info.salary;
      this.notes = user.user_info.notes;
      this.paperwork = user.user_info.employee_paperwork;
      this.role = user.role;
    }
  }
}
