import { FilePondFile, FilePondOptions } from "filepond";
import { CarrierAddress } from "./carrier-address.model";
import { CarrierBookingAgent } from "./carrier-booking-agent.model";
import { CarrierChange } from "./carrier-change.model";
import { CarrierDriver } from "./carrier-driver.model";
import { CarrierEquipment } from "./carrier-equipment.model";
import { CarrierNote } from "./carrier-note.model";
import { Paperwork } from "./carrier-paperwork.model";

export class Carrier {
  id: number;
  mc_num?: string;
  dot_num?: string;
  company_name: string;
  business_name: string;
  email: string;
  carrier_packet?: FilePondFile;
  carrier_packet_url?: string;
  carrier_packet_expiry?: string;
  insurance?: FilePondFile;
  insurance_url?: string;
  insurance_expiry?: string;
  addresses: CarrierAddress[];
  booking_agents: CarrierBookingAgent[];
  equipments: CarrierEquipment[];
  drivers: CarrierDriver[];
  paperwork: Paperwork[];
  paperwork_ids?: number[];
  power_units: number;
  search_result_from_address?: CarrierAddress;
  search_result_to_address?: CarrierAddress;
  notes: CarrierNote[];
  is_blacklisted: boolean;
  changes: CarrierChange[];

  constructor() {
    this.addresses = [];
    this.booking_agents = [];
    this.equipments = [];
    this.drivers = [];
    this.paperwork = [];
    this.notes = [];
    this.changes = [];
  }
}
