<div class="form-container">
  <div class="header-container">
    <h1>Add Document</h1>
  </div>
  <div class="errors-container" *ngIf="errors.length > 0">
    <div class="error-line" *ngFor="let error of errors">{{ error }}</div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div>Uploaded file: {{ filename }}</div>
    </div>
    <div class="form-row flex">
      <div class="form-field-container full-width">
        <label>Document description</label>
        <input type="text" [(ngModel)]="description" />
      </div>
    </div>
    <div class="form-row flex flex-end">
      <button class="btn-primary" (click)="saveDocument()" [disabled]="isAdding">Upload</button>
    </div>
  </div>
</div>
