<div *ngIf="showDeadlinePicker">
  <h2>{{ titleLabel }}</h2>
  <p>{{ subtitleLabel }}</p>
  <mat-form-field>
    <input matInput [ngxMatDatetimePicker]="picker" [placeholder]="showTime ? 'Select date & time' : 'Select date'" [formControl]="dateTimePicker">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker [defaultTime]="showTime ? [getHours(), 0, 0] : [0, 0, 0]" [stepMinute]="15" [hideTime]="!showTime">
    </ngx-mat-datetime-picker>
  </mat-form-field>
</div>
<div *ngIf="showFileUpload">
  <file-pond #filePond [options]="pondOptions"></file-pond>
</div>
<textarea *ngIf="showNotesTextArea" rows="3" placeholder="Notes" [(ngModel)]="notes"></textarea>
<div class="buttons-container">
  <button class="btn-secondary" (click)="cancel()">Cancel</button>
  <button class="btn-primary" (click)="proceed()" [disabled]="showDeadlinePicker && !dateTimePicker.value">{{ submitButtonLabel }}</button>
</div>
