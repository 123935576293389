<div class="buttons-container">
  <a class="btn-secondary" (click)="goBack()">&lt; Back</a>
</div>
<div class="date-range-container">
  <label>Date Range</label>
  <input type="text" ngxDaterangepickerMd [(ngModel)]="selectedDates"
    [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [showRangeLabelOnInput]="true"
    [ranges]="ranges" [disabled]="report == null" placeholder="Select a date range"
    (datesUpdated)="datesUpdated()" [autoApply]="true" />
</div>
<div *ngIf="report == null" class="loading-text">Loading...</div>
<div class="content-container" *ngIf="report != null">
  <div class="row">
    <div class="property-container"><span class="label">Total revenue: </span>${{ formatNumber(report?.totalRevenue) }}</div>
    <div class="property-container"><span class="label">Total margin: </span>${{ formatNumber(report?.totalMargin) }}</div>
  </div>
  <div class="row">
    <h2>By Employee</h2>
  </div>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
    <thead>
      <tr>
        <th></th>
        <th>Name</th>
        <th>Revenue</th>
        <th>Margin</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let employee of report?.byEmployee; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ employee.user_info?.first_name + ' ' + employee.user_info?.last_name }}</td>
        <td>${{ formatNumber(employee.revenue) }}</td>
        <td>${{ formatNumber(employee.margin) }}</td>
      </tr>
      <tr *ngIf="report != null && (report.byEmployee == null || report.byEmployee.length === 0)">
        <td class="center-text" colspan="4">No data found</td>
      </tr>
    </tbody>
  </table>
</div>
