import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CarrierBookingAgent } from '../models/carrier-booking-agent.model';
import { CarrierDriver } from '../models/carrier-driver.model';
import { CarrierEquipment } from '../models/carrier-equipment.model';
import { CarrierNote } from '../models/carrier-note.model';
import { CarrierSearch } from '../models/carrier-search.model';
import { Carrier } from '../models/carrier.model';
import { DtResponse } from '../models/dt-response.model';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {

  carrierToEdit: Carrier;

  constructor(
    private http: HttpClient
  ) { }

  getAllCarriers(dtParams: any, searchQuery: string = ''): Observable<DtResponse<Carrier>> {
    return this.http.post<DtResponse<Carrier>>(`${ environment.apiUrl }/carrier/all-carriers`, {
      ...dtParams,
      search_query: searchQuery
    });
  }

  getCarrierDetails(id: number): Observable<Carrier> {
    return this.http.get<Carrier>(`${ environment.apiUrl }/carrier/${ id }`).pipe(
      map(carrier => {
        carrier.carrier_packet_expiry = carrier.carrier_packet_expiry?.slice(0, 10);
        carrier.insurance_expiry = carrier.insurance_expiry?.slice(0, 10);
        return carrier;
      })
    )
  }

  addNewCarrier(carrier: Carrier | FormData): Observable<number> {
    return this.http.post<number>(`${ environment.apiUrl }/carrier/create-update`, carrier);
  }

  searchCarriers(model: CarrierSearch): Observable<Carrier[]> {
    return this.http.post<Carrier[]>(`${ environment.apiUrl }/carrier/search`, model);
  }

  getDrivers(carrierId: number, term?: string, skip: number = 0, take: number = -1): Observable<CarrierDriver[]> {
    let params: any = {
      carrier_id: carrierId.toString(),
      skip: skip.toString(),
      take: take.toString()
    };

    if (term != null) {
      params.term = term;
    }

    return this.http.get<CarrierDriver[]>(`${ environment.apiUrl }/carrier/get-drivers`, { params });
  }

  getBookingAgents(carrierId: number, term?: string, skip: number = 0, take: number = -1): Observable<CarrierBookingAgent[]> {
    let params: any = {
      carrier_id: carrierId.toString(),
      skip: skip.toString(),
      take: take.toString()
    };

    if (term != null) {
      params.term = term;
    }

    return this.http.get<CarrierBookingAgent[]>(`${ environment.apiUrl }/carrier/get-booking-agents`, { params });
  }

  addNewBookingAgent(carrierId: number, agent: CarrierBookingAgent): Observable<number> {
    return this.http.post<number>(`${ environment.apiUrl }/carrier/${ carrierId }/create-booking-agent`, agent);
  }

  addNewDriver(carrierId: number, driver: CarrierDriver): Observable<number> {
    return this.http.post<number>(`${ environment.apiUrl }/carrier/${ carrierId }/create-driver`, driver);
  }

  addOrEditCarrierNote(note: CarrierNote): Observable<CarrierNote> {
    return this.http.post<CarrierNote>(`${ environment.apiUrl }/carrier/create-update-note`, note);
  }

  getAllEquipment(): Observable<CarrierEquipment[]> {
    return this.http.get<CarrierEquipment[]>(`${ environment.apiUrl }/carrier/get-all-equipment`);
  }

  createCarrierEquipment(name: string): Observable<number> {
    return this.http.post<number>(`${ environment.apiUrl }/carrier/create-equipment`, {
      name
    });
  }

  getCarrierStatus(carrier: Carrier): 'Active' | 'Inactive' | 'Blacklisted' | 'Pending Admin Approval' {
    if (carrier.is_blacklisted) {
      return 'Blacklisted';
    }
    if (carrier.changes.filter(c => c.approved_at == null).length > 0) {
      return 'Pending Admin Approval';
    }
    if (carrier.carrier_packet_expiry == null || carrier.insurance_expiry == null) {
      return 'Inactive';
    }
    const carrierPacketExpiry = moment.utc(carrier.carrier_packet_expiry);
    const insuranceExpiry = moment.utc(carrier.insurance_expiry);
    if (carrierPacketExpiry.isBefore(moment()) || insuranceExpiry.isBefore(moment())) {
      return 'Inactive';
    }
    return 'Active';
  }

  deleteCarrier(id: number): Observable<boolean> {
    return this.http.post<boolean>(`${ environment.apiUrl }/carrier/delete`, {
      id
    });
  }

  blacklistCarrier(id: number, shouldBlacklist: boolean): Observable<boolean> {
    return this.http.post<boolean>(`${ environment.apiUrl }/carrier/blacklist`, {
      id,
      should_blacklist: shouldBlacklist
    });
  }

  approveChange(id: number): Observable<boolean> {
    return this.http.post<boolean>(`${ environment.apiUrl }/carrier/approve-change`, {
      id
    });
  }

}
