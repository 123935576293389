import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FilePondOptions } from 'filepond';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import { filter, finalize, switchMapTo, takeUntil, tap } from 'rxjs/operators';
import { UpdateOwnerComponentType } from 'src/app/enums/update-owner-component.type.enum';
import { Client } from 'src/app/models/client.model';
import { ConfirmDialogModel } from 'src/app/models/confirm-dialog.model';
import { Load } from 'src/app/models/load.model';
import { AuthService } from 'src/app/services/auth.service';
import { ClientService } from 'src/app/services/client.service';
import { LoadService } from 'src/app/services/load.service';
import { UiService } from 'src/app/services/ui.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DocumentUploadComponent } from '../document-upload/document-upload.component';
import { UpdateOwnerComponent } from '../update-owner/update-owner.component';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit, OnDestroy {

  @ViewChild('uploadPond') uploadPond: any;

  id: number;
  client: Client;

  destroy$ = new Subject<null>();

  pondOptions: FilePondOptions = {
    labelIdle: 'Drag and drop files here OR click to add file',
    allowMultiple: false,
    maxFiles: 1,
    credits: false
  };

  dtOptions: DataTables.Settings = {};

  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private clientService: ClientService,
    private uiService: UiService,
    private router: Router,
    public auth: AuthService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private location: Location,
    private loadService: LoadService
  ) {
    this.uiService.setHeaderTitle('Client Details');
  }

  ngOnInit(): void {
    this.dtOptions = {
      serverSide: false,
      pagingType: 'simple',
      lengthChange: false,
      paging: false,
      info: false,
      searching: false,
      ordering: false
    };

    this.route.paramMap.pipe(
      takeUntil(this.destroy$)
    ).subscribe((params: ParamMap) => {
      this.id = parseInt(params.get('id'));

      this.clientService.getClientDetails(this.id).pipe(
        takeUntil(this.destroy$)
      ).subscribe(client => {
        this.client = client;
        this.isLoading = false;
      });
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  goToEditClient() {
    this.clientService.clientToEdit = this.client;
    this.router.navigate(['/add-client']);
  }

  changeOwner() {
    const dialog = this.dialog.open(UpdateOwnerComponent, {
      data: {
        id: this.id,
        type: UpdateOwnerComponentType.Client
      },
      width: '300px'
    });
    dialog.afterClosed().pipe(
      takeUntil(this.destroy$)
    ).subscribe(res => {
      if (res.success) {
        this.client = null;
        this.isLoading = true;
        this.clientService.getClientDetails(this.id).pipe(
          takeUntil(this.destroy$)
        ).subscribe(client => {
          this.client = client;
          this.isLoading = false;
        });
      }
    });
  }

  deleteClient() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: new ConfirmDialogModel('Delete Client', `Are you sure you want to delete client #${ this.client.id }?`)
    });

    dialogRef.afterClosed().pipe(
      filter(res => res),
      tap(() => this.isLoading = true),
      switchMapTo(this.clientService.deleteClient(this.client.id)),
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      if (res) {
        this.snackbar.open('Client deleted', null, {
          duration: 3000
        });
        this.location.back();
      } else {
        this.snackbar.open('Failed to delete client. Please try again', null, {
          duration: 5000
        });
      }
    }, err => {
      let msg;
      if (err.error?.message) {
        msg = err.error.message;
      }
      this.snackbar.open(`Failed to delete client. ${ msg }`, null, {
        duration: 5000
      });
    });
  }

  addLoad() {
    let load = new Load();
    load.client = this.client;
    load.client_id = this.client.id;
    this.loadService.loadToEdit = load;
    this.router.navigate(['/add-load']);
  }

  fileAdded() {
    let pondFile = this.uploadPond.getFile();
    if (pondFile == null || pondFile.file == null) {
      return;
    }

    this.uploadPond.pond.setOptions({
      disabled: true
    });
    let dialog = this.dialog.open(DocumentUploadComponent, {
      data: {
        clientId: this.id,
        file: pondFile.file,
        filename: pondFile.filename
      },
      width: '400px'
    });
    dialog.afterClosed().pipe(
      takeUntil(this.destroy$)
    ).subscribe(({ success, res }) => {
      this.uploadPond.pond.setOptions({
        disabled: false
      });
      this.uploadPond.removeFiles();
      if (success) {
        this.snackbar.open('Document uploaded', null, {
          duration: 5000
        });
        this.client = null;
        this.isLoading = true;
        this.clientService.getClientDetails(this.id).pipe(
          takeUntil(this.destroy$)
        ).subscribe(client => {
          this.client = client;
          this.isLoading = false;
        });
      }
    });
  }

  deleteDocument(id: number) {
    let snackbar;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: new ConfirmDialogModel('Delete Document', `Are you sure you want to delete this document?`)
    });

    dialogRef.afterClosed().pipe(
      filter(res => res),
      tap(() => {
        snackbar = this.snackbar.open('Deleting file...', null);
      }),
      switchMapTo(this.clientService.deleteDocument(this.id, id)),
      finalize(() => snackbar.dismiss())
    ).subscribe(res => {
      if (res) {
        this.snackbar.open('Document deleted', null, {
          duration: 3000
        });
        let idx = this.client.documents.findIndex(doc => doc.id === id);
        this.client.documents.splice(idx, 1);
      } else {
        this.snackbar.open('Failed to delete document. Please try again', null, {
          duration: 5000
        });
      }
    }, err => {
      let msg;
      if (err.error?.message) {
        msg = err.error.message;
      }
      this.snackbar.open(`Failed to delete document. ${ msg }`, null, {
        duration: 5000
      });
    });
  }

  formatDate(date: Moment, displayTime = false) {
    return moment(date).format('YYYY-MM-DD' + (displayTime ? ' HH:mm' : ''));
  }

}
