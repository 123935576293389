import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ClientBusinessLocation } from '../models/client-business-location.model';
import { Client } from '../models/client.model';
import { DtResponse } from '../models/dt-response.model';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  clientToEdit: Client;

  constructor(
    private http: HttpClient
  ) { }

  getMyClients(dtParams: any, searchQuery: string = ''): Observable<DtResponse<Client>> {
    return this.http.post<DtResponse<Client>>(`${ environment.apiUrl }/client/my-clients`, {
      ...dtParams,
      search_query: searchQuery
    });
  }

  getAllClients(dtParams: any, searchQuery: string = ''): Observable<DtResponse<Client>> {
    return this.http.post<DtResponse<Client>>(`${ environment.apiUrl }/client/all-clients`, {
      ...dtParams,
      search_query: searchQuery
    });
  }

  getClientDetails(id: number): Observable<Client> {
    return this.http.get<Client>(`${ environment.apiUrl }/client/${ id }`);
  }

  addNewClient(client: Client | FormData): Observable<number> {
    return this.http.post<number>(`${ environment.apiUrl }/client/create-update`, client);
  }

  searchClients(term?: string): Observable<Client[]> {
    let params = {};
    if (term) {
      params = {
        term
      };
    }

    return this.http.get<Client[]>(`${ environment.apiUrl }/client/search`, {
      params
    });
  }

  getClientLocations(clientId: number, term?: string, skip: number = 0, take: number = -1): Observable<ClientBusinessLocation[]> {
    let params: any = {
      client_id: clientId.toString(),
      skip: skip.toString(),
      take: take.toString()
    };

    if (term != null) {
      params.term = term;
    }

    return this.http.get<ClientBusinessLocation[]>(`${ environment.apiUrl }/client/get-locations`, { params }).pipe(
      map(res => {
        res.forEach(loc => {
          loc.full_address = `${ loc.label ? loc.label + ' - ' : '' }${ loc.address }, ${ loc.city } ${ loc.state }`;
        });
        return res;
      })
    );
  }

  addNewBusinessLocation(clientId: number, location: ClientBusinessLocation): Observable<number> {
    return this.http.post<number>(`${ environment.apiUrl }/client/${ clientId }/create-location`, location);
  }

  changeClientOwner(clientId: number, ownerId: number): Observable<boolean> {
    return this.http.post<boolean>(`${ environment.apiUrl }/client/${ clientId }/change-owner`, {
      owner_id: ownerId.toString()
    });
  }

  alertManagementAboutClient(clientId: number, notes: string): Observable<boolean> {
    return this.http.post<boolean>(`${ environment.apiUrl }/client/alert-management`, {
      id: clientId,
      notes
    });
  }

  deleteClient(id: number): Observable<boolean> {
    return this.http.post<boolean>(`${ environment.apiUrl }/client/delete`, {
      id
    });
  }

  addDocumentToClient(clientId: number, file: any, description: string): Observable<number> {
    let formData = new FormData();
    formData.append('client_id', clientId.toString());
    formData.append('file', file);
    formData.append('description', description);

    return this.http.post<number>(`${ environment.apiUrl }/client/add-document-to-client`, formData);
  }

  deleteDocument(clientId: number, documentId: number): Observable<boolean> {
    return this.http.post<boolean>(`${ environment.apiUrl }/client/delete-document`, {
      client_id: clientId,
      document_id: documentId
    });
  }

}
