<div class="buttons-container">
  <a class="btn-secondary" (click)="goBack()">&lt; Back</a>
</div>
<div class="date-range-container">
  <label>Date Range</label>
  <input type="text" ngxDaterangepickerMd [(ngModel)]="selectedDates"
    [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [showRangeLabelOnInput]="true"
    [ranges]="ranges" [disabled]="report == null" placeholder="Select a date range"
    (datesUpdated)="datesUpdated()" [autoApply]="true" />
</div>
<div *ngIf="report == null" class="loading-text">Loading...</div>
<div class="content-container" *ngIf="report != null">
  <div class="row">
    <div class="disclaimer-text">* Only loads with a carrier assigned are taken into account in this report</div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Total carriers: </span>{{ report?.numOfCarriers }}</div>
    <div class="property-container"><span class="label">Average number of loads per carrier: </span>{{ report?.loadsPerCarrier.toFixed(2) }}</div>
    <div class="property-container"><span class="label">Average revenue per carrier: </span>${{ formatNumber(report?.revenuePerCarrier) }}</div>
    <div class="property-container"><span class="label">Average margin per carrier: </span>${{ formatNumber(report?.marginPerCarrier) }}</div>
  </div>
  <div class="row">
    <h2>By Carrier</h2>
  </div>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
    <thead>
      <tr>
        <th></th>
        <th>Carrier</th>
        <th>Number of loads</th>
        <th>Revenue</th>
        <th>Margin</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let carrier of report?.byCarrier; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ carrier.business_name }}</td>
        <td>{{ carrier.num_of_loads }}</td>
        <td>${{ formatNumber(carrier.revenue) }}</td>
        <td>${{ formatNumber(carrier.margin) }}</td>
      </tr>
      <tr *ngIf="report != null && (report.byCarrier == null || report.byCarrier.length === 0)">
        <td class="center-text" colspan="5">No data found</td>
      </tr>
    </tbody>
  </table>
</div>
