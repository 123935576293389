<div class="form-container">
  <div class="header-container">
    <h1>{{ headerTitle }}</h1>
    <div class="buttons-container">
      <button class="btn-primary" (click)="save()" [disabled]="isSaving">Save</button>
      <button class="btn-secondary" (click)="cancel()" [disabled]="isSaving">Cancel</button>
    </div>
  </div>
  <div class="errors-container" *ngIf="errors.length > 0">
    <div class="error-line" *ngFor="let error of errors">{{ error }}</div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="email">Email</label>
        <input id="email" type="text" [(ngModel)]="employee.email" />
      </div>
      <div class="form-field-container">
        <label for="phone">Phone</label>
        <input id="phone" type="text" [(ngModel)]="employee.phone" />
      </div>
    </div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="first_name">First Name</label>
        <input id="first_name" type="text" [(ngModel)]="employee.first_name" />
      </div>
      <div class="form-field-container">
        <label for="last_name">Last Name</label>
        <input id="last_name" type="text" [(ngModel)]="employee.last_name" />
      </div>
      <div class="form-field-container one-width">
        <label for="date_of_birth">Date of Birth</label>
        <input type="date" name="date_of_birth" id="date_of_birth" [(ngModel)]="employee.date_of_birth" />
      </div>
    </div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container two-widths">
        <label for="address">Address</label>
        <input #addressText placeholder="Start typing an address" [disabled]="!mapLoaded" id="address" type="text" [(ngModel)]="employee.address" />
      </div>
    </div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="city">City</label>
        <input id="city" type="text" [(ngModel)]="employee.city" />
      </div>
      <div class="form-field-container">
        <label for="state">State</label>
        <input id="state" type="text" [(ngModel)]="employee.state" />
      </div>
      <div class="form-field-container">
        <label for="zip">ZIP</label>
        <input id="zip" type="text" [(ngModel)]="employee.zip" />
      </div>
    </div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="salary">Salary</label>
        <input id="salary" type="number" step="0.01" [(ngModel)]="employee.salary" (wheel)="$event.target.blur()" (change)="roundSalary()" />
      </div>
      <div class="form-field-container one-width">
        <label for="role">Role</label>
        <select name="role" id="role" [(ngModel)]="employee.role">
          <option *ngFor="let role of (roles | async)" [value]="role">{{ roleToLabelMapping[role] }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container two-widths">
        <label for="notes">Notes</label>
        <textarea
          name="notes"
          id="notes"
          cols="30"
          rows="10"
          [(ngModel)]="employee.notes">
        </textarea>
      </div>
    </div>
  </div>
  <div class="form-group-container">
    <div class="form-row">
      <div class="form-field-container flex-grow">
        <label for="paperwork">Paperwork</label>
        <file-pond class="paperwork-pond" #pond [options]="pondOptions"
          (onaddfile)="onAddedPaperwork($event)">
        </file-pond>
        <div class="bold" *ngIf="currentPaperwork.length > 0">Added files:</div>
        <div class="paperwork-entry" *ngFor="let paperwork of currentPaperwork; let i = index">
          <div class="filename-label">{{ getFilename(paperwork) }}</div>
          <input class="auto-width" type="text" [disabled]="paperwork.file_url != null" [(ngModel)]="paperwork.description" placeholder="Enter description" />
          <button class="btn-remove" (click)="removePaperwork(i)">- Remove</button>
        </div>
      </div>
    </div>
  </div>
</div>
