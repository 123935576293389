import { MapsAPILoader } from '@agm/core';
import { Location } from '@angular/common';
import { Component, ElementRef, NgZone, OnDestroy, OnInit, Optional, QueryList, ViewChild, ViewChildren, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin, from, Observable, Subject } from 'rxjs';
import { finalize, map, switchMap, tap, takeUntil } from 'rxjs/operators';
import { Client } from 'src/app/models/client.model';
import { ClientService } from 'src/app/services/client.service';
import { GeocodingService } from 'src/app/services/geocoding.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit, OnDestroy {

  @ViewChild('addressText') addressText: ElementRef;
  @ViewChildren('businessAddressText') businessAddressTexts: QueryList<ElementRef>;

  client = new Client();

  headerTitle = 'Add Client';
  errors: string[] = [];
  isSaving = false;
  successText = 'Client created';

  mapLoaded = false;

  destroy$ = new Subject<null>();

  constructor(
    private uiService: UiService,
    private location: Location,
    private clientService: ClientService,
    private snackbar: MatSnackBar,
    private geocodingService: GeocodingService,
    private ngZone: NgZone,
    private mapLoader: MapsAPILoader,
    @Optional() private dialogRef: MatDialogRef<AddClientComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.uiService.setHeaderTitle('Add New Client');
    this.uiService.setGrayBg(true);
    if (this.clientService.clientToEdit != null) {
      this.client = this.clientService.clientToEdit;
      this.clientService.clientToEdit = null;
      if (this.client.id != null) {
        this.uiService.setHeaderTitle('Edit Client');
        this.headerTitle = 'Edit Client';
        this.successText = 'Client updated';
      }
    }
  }

  ngOnInit(): void {
    if (this.data?.name) {
      this.client.name = this.data.name;
    }

    from(this.mapLoader.load()).subscribe(() => {
      this.mapLoaded = true;
      this.setupAutocomplete();
    });
  }

  ngOnDestroy(): void {
    this.uiService.setGrayBg(false);
    this.destroy$.next();
  }

  private setupAutocomplete() {
    let addressAutocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement, {
      componentRestrictions: { country: 'US' }
    });
    google.maps.event.addListener(addressAutocomplete, 'place_changed', () => {
      let place = addressAutocomplete.getPlace();
      this.ngZone.run(() => {
        this.client.address = place.formatted_address;
      });
    });
  }

  addLocation() {
    this.client.business_locations.push({
      address: '',
      city: '',
      state: '',
      zip: ''
    });

    setTimeout(() => {
      let elem = this.businessAddressTexts.last.nativeElement;
      let addressAutocomplete = new google.maps.places.Autocomplete(elem, {
        componentRestrictions: { country: 'US' }
      });
      google.maps.event.addListener(addressAutocomplete, 'place_changed', () => {
        let place = addressAutocomplete.getPlace();
        let placeAddress = this.geocodingService.deconstructGooglePlaceResult(place);
        let i;
        this.businessAddressTexts.forEach((ref, idx) => {
          if (ref.nativeElement == elem) i = idx;
        });
        if (i != null) {
          this.ngZone.run(() => {
            this.client.business_locations[i].address = placeAddress.address;
            this.client.business_locations[i].city = placeAddress.city;
            this.client.business_locations[i].state = placeAddress.state;
            this.client.business_locations[i].zip = placeAddress.zip;
          });
        }
      });
    });
  }

  removeLocation(i: number) {
    this.client.business_locations.splice(i, 1);
  }

  addRep() {
    this.client.reps.push({
      name: '',
      email: '',
      phone: '',
      title: ''
    });
  }

  removeRep(i: number) {
    this.client.reps.splice(i, 1);
  }

  cancel() {
    if (this.dialogRef == null) {
      this.location.back();
    } else {
      this.dialogRef.close();
    }
  }

  save() {
    this.isSaving = true;
    this.errors = [];

    let latLngObs: Observable<google.maps.GeocoderResult[]>[] = [];

    for (let loc of this.client.business_locations) {
      let { address, city, state, zip } = loc;
      if (address.trim() === '' || city.trim() === '' || state.trim() === '' || zip.trim() === '') {
        this.errors.push('All business location fields must be filled in');
        this.isSaving = false;
        return;
      }
      const fullAddress = `${ address }, ${ city } ${ state } ${ zip }`;
      latLngObs.push(this.geocodingService.getLatLng(fullAddress));
    }

    if (latLngObs.length === 0) {
      latLngObs.push(new Observable(observer => {
        observer.next(null);
        observer.complete();
      }));
    }

    forkJoin(latLngObs).pipe(
      tap(([...latLngArr]) => {
        for (let i = 0; i < latLngArr.length; i++) {
          let res = latLngArr[i];
          if (res == null) {
            continue;
          }
          if (res.length) {
            const locResult = res[0].geometry.location;
            this.client.business_locations[i].lat = locResult.lat();
            this.client.business_locations[i].lng = locResult.lng();
            let foundLocality = false;
            for (const c of res[0].address_components) {
              const types = c.types;
              if (types.includes('administrative_area_level_1')) {
                this.client.business_locations[i].state = c.short_name;
              } else if (types.includes('locality')) {
                this.client.business_locations[i].city = c.long_name;
                foundLocality = true;
              } else if (types.includes('administrative_area_level_3') && !foundLocality) { // do not overwrite locality
                this.client.business_locations[i].city = c.long_name;
              }
            }
          } else {
            this.errors.push(`Address #${ i+1 } (${ this.client.business_locations[i].address }) could not be pinned to a set of coordinates. Please check to see that it is a valid address`);
            throw new Error();
          }
        }
      }),
      map(() => {
        let formData = new FormData();
        Object.keys(this.client).forEach(key => {
          if (typeof this.client[key] === 'object' && this.client[key] != null) {
            Object.keys(this.client[key]).forEach(key2 => {
              if (typeof this.client[key][key2] === 'object' && this.client[key][key2] != null) {
                Object.keys(this.client[key][key2]).forEach(key3 => {
                  formData.append(`${ key }[${ key2 }][${ key3 }]`, this.client[key][key2][key3] ?? '');
                });
              } else {
                formData.append(`${ key }[${ key2 }]`, this.client[key][key2] ?? '');
              }
            });
          } else {
            formData.append(key, this.client[key] ?? '');
          }
        });
        return formData;
      }),
      switchMap(data => this.clientService.addNewClient(data)),
      finalize(() => {
        this.ngZone.run(() => {
          this.isSaving = false;
        });
      }),
      takeUntil(this.destroy$)
    ).subscribe(res => {
      this.ngZone.run(() => {
        this.errors = [];
        if (this.dialogRef == null) {
          this.location.back();
          this.snackbar.open(this.successText, null, {
            duration: 5000
          });
        } else {
          this.client.id = res;
          this.dialogRef.close({ created: true, item: this.client });
        }
      });
    }, err => {
      this.ngZone.run(() => {
        if (err.error?.errors) {
          this.errors = [].concat.apply([], Object.values(err.error.errors));
        } else if (err.error?.message) {
          this.errors = [err.error.message];
        } else if (this.errors.length === 0) {
          this.errors = ['An unexpected error has occurred. Please try again or contact administrator.'];
        }
      });
    });
  }

}
