import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { FilePond, FilePondOptions } from 'filepond';
import { Moment } from 'moment';
import { filter, finalize, switchMapTo, takeUntil, tap } from 'rxjs/operators';
import { DocumentService } from 'src/app/services/document.service';
import { UiService } from 'src/app/services/ui.service';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from 'src/app/models/confirm-dialog.model';
import { Document } from 'src/app/models/document.model';

@Component({
  selector: 'app-documents-table',
  templateUrl: './documents-table.component.html',
  styleUrls: ['./documents-table.component.scss']
})
export class DocumentsTableComponent implements OnInit, OnDestroy {

  @ViewChild('uploadPond') uploadPond: any;
  @ViewChild(DataTableDirective, { static: false }) private dtElem: DataTableDirective;

  destroy$ = new Subject<null>();

  documents: Document[];
  errors: string[] = [];

  pondOptions: FilePondOptions = {
    labelIdle: 'Drag and drop files here OR click to add file',
    allowMultiple: false,
    maxFiles: 1,
    credits: false
  };

  dtOptions: DataTables.Settings = {};

  constructor(
    private documentService: DocumentService,
    private snackbar: MatSnackBar,
    private uiService: UiService,
    public authService: AuthService,
    private dialog: MatDialog
  ) {
    this.uiService.setHeaderTitle('Document Pool');
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      lengthChange: false,
      searching : false,
      ordering: false,
      ajax: (dtParams, callback) => {
        this.documentService.getAllDocuments(dtParams).pipe(
          takeUntil(this.destroy$)
        ).subscribe(res => {
          this.documents = res.data;
          callback({
            recordsTotal: res.recordsTotal,
            recordsFiltered: res.recordsFiltered,
            data: []
          });
        });
      },
      drawCallback: () => {
        setTimeout(() => {
          this.dtElem.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.columns.adjust();
          });
        });
      },
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  fileAdded() {
    const snackbar = this.snackbar.open('Uploading file...', null);
    this.uploadPond.pond.setOptions({
      disabled: true
    });
    let formData = new FormData();
    formData.append('file', this.uploadPond.getFile().file);

    this.documentService.addDocument(formData).pipe(
      finalize(() => {
        this.uploadPond.pond.setOptions({
          disabled: false
        });
        snackbar.dismiss();
      }),
      takeUntil(this.destroy$)
    ).subscribe(res => {
      this.errors = [];
      this.snackbar.open('Document uploaded', null, {
        duration: 5000
      });
      this.uploadPond.removeFiles();
      this.dtElem.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload();
      });
    }, err => {
      if (err.error?.errors) {
        this.errors = [].concat.apply([], Object.values(err.error.errors));
      } else if (err.error?.message) {
        this.errors = [err.error.message];
      } else {
        this.errors = ['An unexpected error has occurred. Please try again or contact administrator.'];
      }
    });
  }

  formatDate(date: Moment) {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }

  deleteDocument(id: number) {
    let snackbar;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: new ConfirmDialogModel('Delete Document', `Are you sure you want to delete this document?`)
    });

    dialogRef.afterClosed().pipe(
      filter(res => res),
      tap(() => {
        snackbar = this.snackbar.open('Deleting file...', null);
      }),
      switchMapTo(this.documentService.deleteDocument(id)),
      finalize(() => snackbar.dismiss())
    ).subscribe(res => {
      if (res) {
        this.snackbar.open('Document deleted', null, {
          duration: 3000
        });
        this.dtElem.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.ajax.reload();
        });
      } else {
        this.snackbar.open('Failed to delete document. Please try again', null, {
          duration: 5000
        });
      }
    }, err => {
      let msg;
      if (err.error?.message) {
        msg = err.error.message;
      }
      this.snackbar.open(`Failed to delete document. ${ msg }`, null, {
        duration: 5000
      });
    });
  }

}
