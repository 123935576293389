import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { forkJoin, from, Observable, Subject } from 'rxjs';
import { finalize, switchMap, tap, takeUntil } from 'rxjs/operators';
import { CarrierAddress } from 'src/app/models/carrier-address.model';
import { CarrierSearch } from 'src/app/models/carrier-search.model';
import { Carrier } from 'src/app/models/carrier.model';
import { CarrierService } from 'src/app/services/carrier.service';
import { GeocodingService } from 'src/app/services/geocoding.service';

@Component({
  selector: 'app-carrier-search',
  templateUrl: './carrier-search.component.html',
  styleUrls: ['./carrier-search.component.scss']
})
export class CarrierSearchComponent implements OnInit, OnDestroy {

  @ViewChild('fromAddressText') fromAddressText: ElementRef;
  @ViewChild('toAddressText') toAddressText: ElementRef;

  destroy$ = new Subject<null>();

  fromAddress: string = '';
  toAddress: string = '';
  searchModel: CarrierSearch;

  results: Carrier[] = null;
  errors: string[] = [];
  isSearching = false;

  mapLoaded = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<CarrierSearchComponent>,
    private geocodingService: GeocodingService,
    private carrierService: CarrierService,
    private zone: NgZone,
    private mapLoader: MapsAPILoader
  ) {
    this.searchModel = new CarrierSearch();
  }

  ngOnInit(): void {
    this.fromAddress = this.data.fromAddress ?? '';
    this.toAddress = this.data.toAddress ?? '';

    from(this.mapLoader.load()).subscribe(() => {
      this.mapLoaded = true;
      this.setupAutocomplete();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private setupAutocomplete() {
    let fromAutocomplete = new google.maps.places.Autocomplete(this.fromAddressText.nativeElement, {
      componentRestrictions: { country: 'US' }
    });
    google.maps.event.addListener(fromAutocomplete, 'place_changed', () => {
      let place = fromAutocomplete.getPlace();
      this.zone.run(() => {
        this.fromAddress = place.formatted_address;
      });
    });

    let toAutocomplete = new google.maps.places.Autocomplete(this.toAddressText.nativeElement, {
      componentRestrictions: { country: 'US' }
    });
    google.maps.event.addListener(toAutocomplete, 'place_changed', () => {
      let place = toAutocomplete.getPlace();
      this.zone.run(() => {
        this.toAddress = place.formatted_address;
      });
    });
  }

  search() {
    this.isSearching = true;
    this.results = null;

    let fromObs: Observable<google.maps.GeocoderResult[]>, toObs: Observable<google.maps.GeocoderResult[]>;
    if (this.fromAddress.trim() != '') {
      fromObs = this.geocodingService.getLatLng(this.fromAddress);
    } else {
      fromObs = new Observable(observer => {
        observer.next(null);
        observer.complete();
      });
    }
    if (this.toAddress.trim() != '') {
      toObs = this.geocodingService.getLatLng(this.toAddress);
    } else {
      toObs = new Observable(observer => {
        observer.next(null);
        observer.complete();
      });
    }

    forkJoin([ fromObs, toObs ]).pipe(
      tap(([ fromGeocoderResults, toGeocoderResults ]) => {
        let fromLat: number, fromLng: number, toLat: number, toLng: number;
        if (fromGeocoderResults != null) {
          if (fromGeocoderResults.length) {
            const locResult = fromGeocoderResults[0].geometry.location;
            fromLat = locResult.lat();
            fromLng = locResult.lng();
          } else {
            this.errors = [];
            this.errors.push(`"From" address could not be pinned to a set of coordinates. Please check to see that it is a valid address`);
            throw new Error();
          }
        }
        if (toGeocoderResults != null) {
          if (toGeocoderResults.length) {
            const locResult = toGeocoderResults[0].geometry.location;
            toLat = locResult.lat();
            toLng = locResult.lng();
          } else {
            this.errors = [];
            this.errors.push(`"To" address could not be pinned to a set of coordinates. Please check to see that it is a valid address`);
            throw new Error();
          }
        }

        this.searchModel.from_lat = fromLat;
        this.searchModel.from_lng = fromLng;
        this.searchModel.to_lat = toLat;
        this.searchModel.to_lng = toLng;
      }),
      switchMap(() => this.carrierService.searchCarriers(this.searchModel)),
      finalize(() => {
        this.zone.run(() => this.isSearching = false);
      }),
      takeUntil(this.destroy$)
    ).subscribe(res => {
      this.zone.run(() => this.results = res);
    });
  }

  selectCarrier(carrier: Carrier) {
    this.dialogRef.close({ carrier });
  }

  getAddressString(address: CarrierAddress): string {
    return address ? address.address + ', ' + address.city + ' ' + address.state + ' ' + address.zip : '-';
  }

  getInactiveReason(carrier: Carrier): string | null {
    if (carrier.is_blacklisted) {
      return "Carrier blacklisted";
    } else if (carrier.changes.filter(c => c.approved_at == null).length > 0) {
      return "Pending admin approval";
    } else if (carrier.carrier_packet_expiry == null) {
      return "Inactive due to missing carrier packet";
    } else if (moment.utc(carrier.carrier_packet_expiry).isBefore(moment())) {
      return "Inactive due to expired carrier packet";
    } else if (carrier.insurance_expiry == null) {
      return "Inactive due to missing insurance";
    } else if (moment.utc(carrier.insurance_expiry).isBefore(moment())) {
      return "Inactive due to expired insurance";
    }
    return null;
  }

}
