import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, finalize, switchMapTo, takeUntil, tap } from 'rxjs/operators';
import { ConfirmDialogModel } from 'src/app/models/confirm-dialog.model';
import { Load } from 'src/app/models/load.model';
import { Quote } from 'src/app/models/quote.model';
import { QuoteService } from 'src/app/services/quote.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadService } from 'src/app/services/load.service';
import { UiService } from 'src/app/services/ui.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { RouteHistoryService } from 'src/app/services/route-history.service';
import { LoadStopType } from 'src/app/enums/load-stop-type.enum';

@Component({
  selector: 'app-quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.scss']
})
export class QuoteDetailsComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<null>();

  id: number;
  quote: Quote;

  dtOptions: DataTables.Settings = {};

  isLoading = true;

  cameFromAddQuote = false;

  constructor(
    private uiService: UiService,
    private route: ActivatedRoute,
    public quoteService: QuoteService,
    private router: Router,
    private loadSerivce: LoadService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private location: Location,
    public auth: AuthService,
    private routeHistoryService: RouteHistoryService
  ) {
    this.uiService.setHeaderTitle('Quote Details');
  }

  ngOnInit(): void {
    this.dtOptions = {
      serverSide: false,
      pagingType: 'simple',
      lengthChange: false,
      paging: false,
      info: false,
      searching: false,
      ordering: false
    };

    this.route.paramMap.pipe(
      takeUntil(this.destroy$)
    ).subscribe((params: ParamMap) => {
      this.id = parseInt(params.get('id'));
      this.fetchQuote();
    });

    this.cameFromAddQuote = this.routeHistoryService.previousUrl === '/add-quote';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  goToEditQuote() {
    this.quoteService.quoteToEdit = this.quote;
    this.router.navigate(['/add-quote']);
  }

  convertToLoad() {
    const load = new Load();

    load.quote_id = this.quote.id;
    load.pickup_stops.push({
      type: LoadStopType.Pickup,
      location: this.quote.pickup_location,
      details: this.quote.pickup_shipment_description
    });
    load.dropoff_stops.push({
      type: LoadStopType.Dropoff,
      location: this.quote.dropoff_location,
      details: this.quote.dropoff_shipment_description
    });
    load.charge = this.quote.rate;
    load.client = this.quote.client;
    load.equipments = this.quote.equipments;

    this.loadSerivce.loadToEdit = load;

    this.router.navigate(['/add-load']);
  }

  deleteQuote() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: new ConfirmDialogModel('Delete Quote', `Are you sure you want to delete quote #${ this.quote.id }?`)
    });

    dialogRef.afterClosed().pipe(
      filter(res => res),
      tap(() => this.isLoading = true),
      switchMapTo(this.quoteService.deleteQuote(this.quote.id)),
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      if (res) {
        this.snackbar.open('Quote deleted', null, {
          duration: 3000
        });
        this.location.back();
      } else {
        this.snackbar.open('Failed to delete quote. Please try again', null, {
          duration: 5000
        });
      }
    }, err => {
      let msg;
      if (err.error?.message) {
        msg = err.error.message;
      }
      this.snackbar.open(`Failed to delete quote. ${ msg }`, null, {
        duration: 5000
      });
    });
  }

  goBack() {
    if (!this.cameFromAddQuote) {
      this.location.back();
    } else {
      this.router.navigate(['my-quotes']);
    }
  }

  private fetchQuote() {
    this.isLoading = true;
    this.quoteService.getQuoteDetails(this.id).pipe(
      takeUntil(this.destroy$)
    ).subscribe(quote => {
      this.quote = quote;
      this.isLoading = false;
    });
  }

}
