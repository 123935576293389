<div class="buttons-container">
  <a class="btn-secondary" (click)="goBack()">&lt; Back</a>
</div>
<div class="date-range-container">
  <label>Date Range</label>
  <input type="text" ngxDaterangepickerMd [(ngModel)]="selectedDates"
    [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [showRangeLabelOnInput]="true"
    [ranges]="ranges" [disabled]="report == null" placeholder="Select a date range"
    (datesUpdated)="datesUpdated()" [autoApply]="true" />
</div>
<div *ngIf="report == null" class="loading-text">Loading...</div>
<div class="content-container" *ngIf="report != null">
  <div class="row">
    <div class="property-container"><span class="label">Total clients: </span>{{ report?.numOfClients }}</div>
    <div class="property-container"><span class="label">Average number of loads per client: </span>{{ +report?.loadsPerClient.toFixed(2) }}</div>
    <div class="property-container"><span class="label">Average revenue per client: </span>${{ formatNumber(report?.revenuePerClient) }}</div>
    <div class="property-container"><span class="label">Average margin per client: </span>${{ formatNumber(report?.marginPerClient) }}</div>
  </div>
  <div class="row">
    <h2>By Client</h2>
  </div>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
    <thead>
      <tr>
        <th></th>
        <th>Client</th>
        <th>Number of loads</th>
        <th>Revenue</th>
        <th>Margin</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let client of report?.byClient; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ client.name }}</td>
        <td>{{ client.num_of_loads }}</td>
        <td>${{ formatNumber(client.revenue) }}</td>
        <td>${{ formatNumber(client.margin) }}</td>
      </tr>
      <tr *ngIf="report != null && (report.byClient == null || report.byClient.length === 0)">
        <td class="center-text" colspan="5">No data found</td>
      </tr>
    </tbody>
  </table>
</div>
