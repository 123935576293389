<div class="buttons-container">
  <a class="btn-secondary" (click)="goBack()">&lt; Back</a>
</div>
<div class="date-range-container">
  <label>Date Range</label>
  <input type="text" ngxDaterangepickerMd [(ngModel)]="selectedDates"
    [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [showRangeLabelOnInput]="true"
    [ranges]="ranges" [disabled]="report == null" placeholder="Select a date range"
    (datesUpdated)="datesUpdated()" [autoApply]="true" />
</div>
<div *ngIf="report == null" class="loading-text">Loading...</div>
<div class="content-container" *ngIf="report != null">
  <div class="row">
    <div class="property-container"><span class="label">Number of loads: </span>{{ report?.numOfLoads }}</div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Revenue: </span>${{ formatNumber(report?.totalRevenue) }}</div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Margin: </span>${{ formatNumber(report?.totalMargin) }}</div>
  </div>
</div>
