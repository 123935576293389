import { Location } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FilePond, FilePondOptions } from 'filepond';
import { BehaviorSubject, concat, merge, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, switchMapTo, tap, takeUntil } from 'rxjs/operators';
import { ChargeLineItemType, ChargeLineItemTypeToLabelMapping } from 'src/app/enums/charge-line-item-type.enum';
import { LoadStatus, LoadStatusToLabelMapping } from 'src/app/enums/load-status.enum';
import { LoadStopType } from 'src/app/enums/load-stop-type.enum';
import { CarrierBookingAgent } from 'src/app/models/carrier-booking-agent.model';
import { CarrierDriver } from 'src/app/models/carrier-driver.model';
import { CarrierEquipment } from 'src/app/models/carrier-equipment.model';
import { ClientBusinessLocation } from 'src/app/models/client-business-location.model';
import { Client } from 'src/app/models/client.model';
import { Load } from 'src/app/models/load.model';
import { AuthService } from 'src/app/services/auth.service';
import { CarrierService } from 'src/app/services/carrier.service';
import { ClientService } from 'src/app/services/client.service';
import { LoadService } from 'src/app/services/load.service';
import { UiService } from 'src/app/services/ui.service';
import { AddCarrierBookingAgentComponent } from '../add-carrier-booking-agent/add-carrier-booking-agent.component';
import { AddCarrierDriverComponent } from '../add-carrier-driver/add-carrier-driver.component';
import { AddCarrierComponent } from '../add-carrier/add-carrier.component';
import { AddClientBusinessLocationComponent } from '../add-client-business-location/add-client-business-location.component';
import { AddClientComponent } from '../add-client/add-client.component';
import { CarrierSearchComponent } from '../carrier-search/carrier-search.component';
import { SendRateConEmailComponent } from '../send-rate-con-email/send-rate-con-email.component';

@Component({
  selector: 'app-add-load',
  templateUrl: './add-load.component.html',
  styleUrls: ['./add-load.component.scss']
})
export class AddLoadComponent implements OnInit, OnDestroy {

  @ViewChildren('equipmentSelects') equipmentSelects: QueryList<NgSelectComponent>;
  @ViewChildren('locationSelects') locationSelects: QueryList<NgSelectComponent>;

  @ViewChild('clientSelect') clientSelect: NgSelectComponent;
  @ViewChild('driverSelect') driverSelect: NgSelectComponent;
  @ViewChild('bookingAgentSelect') bookingAgentSelect: NgSelectComponent;
  @ViewChild('loadTenderPond') loadTenderPond: FilePond;

  destroy$ = new Subject<null>();

  dateTimePicker = new FormControl('');

  loadStatuses = Object.values(LoadStatus).filter(v => typeof v === 'number');
  loadStatusToLabelMapping = LoadStatusToLabelMapping;

  chargeLineItemTypes = Object.values(ChargeLineItemType).filter(v => typeof v === 'number');
  chargeLineItemTypeToLabelMapping = ChargeLineItemTypeToLabelMapping;

  LoadStopPickup = LoadStopType.Pickup;
  LoadStopDropoff = LoadStopType.Dropoff;

  headerTitle = 'Add Load';
  errors: string[] = [];
  isSaving = false;
  successText = 'Load created';

  clients$: Observable<Client[]>;
  clientInput$ = new Subject<string>();
  clientLoading = false;
  clientSelected$ = new Subject<null>();

  fullLocationList: ClientBusinessLocation[] = [];

  pickupLocations$: Observable<ClientBusinessLocation[]>[] = [];
  pickupLocationInput$: Subject<string>[] = [];
  dropoffLocations$: Observable<ClientBusinessLocation[]>[] = [];
  dropoffLocationInput$: Subject<string>[] = [];

  locationLoading = false;

  fullEquipmentList$: BehaviorSubject<CarrierEquipment[]> = new BehaviorSubject([]);
  equipmentLoading = false;

  equipment$: Observable<CarrierEquipment[]>[] = [];
  equipmentInput$: Subject<string>[] = [];

  carrierSelected$ = new Subject<null>();

  fullDriverList: CarrierDriver[] = [];

  drivers$: Observable<CarrierDriver[]>;
  driverInput$ = new Subject<string>();
  driverLoading = false;

  fullBookingAgentList: CarrierBookingAgent[] = [];

  bookingAgents$: Observable<CarrierBookingAgent[]>;
  bookingAgentInput$ = new Subject<string>();
  bookingAgentLoading = false;

  margin: number = this.getInitialMargin();

  pondOptions: FilePondOptions = {
    labelIdle: 'Drop file here',
    allowMultiple: false,
    maxFiles: 1,
    credits: false,
    server: {
      load: (source, load, error, progress, abort, headers) => {
        this.httpClient.get(source, {
          responseType: 'blob',
          observe: 'response'
        }).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: HttpResponse<Blob>) => {
          load(res.body);
        });
      }
    }
  };

  constructor(
    private uiService: UiService,
    private clientService: ClientService,
    private router: Router,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private carrierService: CarrierService,
    public loadService: LoadService,
    private location: Location,
    private httpClient: HttpClient,
    public auth: AuthService
  ) {
    this.uiService.setHeaderTitle('Add New Load');
    this.uiService.setGrayBg(true);
  }

  ngOnInit(): void {
    if (this.loadService.loadToEdit == null) {
      this.loadService.loadToEdit = new Load();
    } else {
      this.successText = 'Load updated';
      this.headerTitle = 'Update Load';
    }

    this.loadClients();
    this.loadLocations();
    this.loadDrivers();
    this.loadBookingAgents();
    this.loadEquipment();

    if (this.loadService.loadToEdit.equipments.length === 0) {
      this.addEquipment();
    }
    if (this.loadService.loadToEdit.pickup_stops.length === 0) {
      this.addPickupStop();
    }
    if (this.loadService.loadToEdit.dropoff_stops.length === 0) {
      this.addDropoffStop();
    }

    if (this.loadService.loadToEdit.client != null) {
      setTimeout(() => this.newClientSelected());
    }
    if (this.loadService.loadToEdit.carrier != null) {
      setTimeout(() => this.carrierSelected$.next());
    }
  }

  ngOnDestroy(): void {
    this.uiService.setGrayBg(false);
    this.destroy$.next();
  }

  trackClientByFn(item: Client) {
    return item.id;
  }

  addClient = (name: string) => {
    this.clientSelect.close();
    const dialog = this.dialog.open(AddClientComponent, {
      maxHeight: '90vh',
      data: {
        name
      }
    });
    return new Promise((resolve, reject) => {
      dialog.afterClosed().pipe(
        takeUntil(this.destroy$)
      ).subscribe(({ created, item }) => {
        if (created) {
          this.snackbar.open('Client created', null, {
            duration: 3000
          });
          this.clientInput$.next('');
          resolve(item);
        } else {
          this.snackbar.open('Failed to create client. Please try again or contact administrator.', null, {
            duration: 5000
          });
          reject('No client created');
        }
      })
    });
  }

  newClientSelected() {
    this.clientSelected$.next();
  }

  private loadClients() {
    this.clientLoading = true;
    this.clients$ = concat(of([]), this.clientService.searchClients().pipe(
      finalize(() => this.clientLoading = false)
    ), this.clientInput$.pipe(
      filter(v => v != null && v.trim() != ''),
      tap(() => this.clientLoading = true),
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => this.clientService.searchClients(term).pipe(
        catchError(() => of([])),
        tap(() => this.clientLoading = false)
      ))
    ));
  }

  trackLocationByFn(item: ClientBusinessLocation) {
    return item.id;
  }

  addBusinessLocation = (name: string) => {
    this.locationSelects.forEach(s => s.close());
    const dialog = this.dialog.open(AddClientBusinessLocationComponent, {
      data: {
        clientId: this.loadService.loadToEdit.client.id,
        address: name
      }
    });
    return new Promise((resolve, reject) => {
      dialog.afterClosed().pipe(
        takeUntil(this.destroy$)
      ).subscribe(({ created, item }) => {
        if (created) {
          this.snackbar.open('Location created', null, {
            duration: 3000
          });
          item.full_address = `${ item.label ? item.label + ' - ' : '' }${ item.address }, ${ item.city } ${ item.state }`;
          this.fullLocationList.unshift(item);
          this.pickupLocationInput$.forEach(i => i.next(''));
          this.dropoffLocationInput$.forEach(i => i.next(''));
          resolve(item);
        } else {
          this.snackbar.open('Failed to create location. Please try again or contact administrator.', null, {
            duration: 5000
          });
          reject('No location created');
        }
      })
    });
  }

  addPickupStop() {
    this.loadService.loadToEdit.pickup_stops.push({
      type: LoadStopType.Pickup
    });
    this.setupPickupLocationFields();
  }

  addDropoffStop() {
    this.loadService.loadToEdit.dropoff_stops.push({
      type: LoadStopType.Dropoff
    });
    this.setupDropoffLocationFields();
  }

  removePickupStop(i: number) {
    this.loadService.loadToEdit.pickup_stops.splice(i, 1);
    this.pickupLocationInput$.splice(i, 1);
    this.pickupLocations$.splice(i, 1);
  }

  removeDropoffStop(i: number) {
    this.loadService.loadToEdit.dropoff_stops.splice(i, 1);
    this.dropoffLocationInput$.splice(i, 1);
    this.dropoffLocations$.splice(i, 1);
  }

  private setupPickupLocationFields() {
    const typeahead = new Subject<string>();
    this.pickupLocationInput$.push(typeahead);
    this.pickupLocations$.push(merge(of(this.fullLocationList), typeahead.pipe(
      filter(term => term != null),
      map(term => this.fullLocationList.filter(loc => loc.address.toLowerCase().includes(term.toLowerCase()) || loc.label?.toLowerCase()?.includes(term.toLowerCase())))
    ), this.clientSelected$.pipe(
      switchMapTo(of([]))
    ), this.clientSelected$.pipe(
      filter(() => this.loadService.loadToEdit.client != null),
      tap(() => this.locationLoading = true),
      finalize(() => this.locationLoading = false),
      switchMap(() => this.clientService.getClientLocations(this.loadService.loadToEdit.client.id).pipe(
        finalize(() => this.locationLoading = false),
        catchError((): Observable<ClientBusinessLocation[]> => of([])),
        tap(res => {
          this.fullLocationList = res;
          typeahead.next('');
        })
      ))
    )));
  }

  private setupDropoffLocationFields() {
    const typeahead = new Subject<string>();
    this.dropoffLocationInput$.push(typeahead);
    this.dropoffLocations$.push(merge(of(this.fullLocationList), typeahead.pipe(
      filter(term => term != null),
      map(term => this.fullLocationList.filter(loc => loc.address.toLowerCase().includes(term.toLowerCase()) || loc.label?.toLowerCase()?.includes(term.toLowerCase())))
    ), this.clientSelected$.pipe(
      switchMapTo(of([]))
    ), this.clientSelected$.pipe(
      filter(() => this.loadService.loadToEdit.client != null),
      tap(() => this.locationLoading = true),
      finalize(() => this.locationLoading = false),
      switchMap(() => this.clientService.getClientLocations(this.loadService.loadToEdit.client.id).pipe(
        finalize(() => this.locationLoading = false),
        catchError((): Observable<ClientBusinessLocation[]> => of([])),
        tap(res => {
          this.fullLocationList = res;
          typeahead.next('');
        })
      ))
    )));
  }

  private loadLocations() {
    if (this.loadService.loadToEdit.client != null) {
      this.locationLoading = true;
      this.clientService.getClientLocations(this.loadService.loadToEdit.client.id).pipe(
        finalize(() => this.locationLoading = false),
        takeUntil(this.destroy$)
      ).subscribe(res => this.fullLocationList = res);
    }

    for (let stop of this.loadService.loadToEdit.pickup_stops) {
      stop.date = stop.date.toString().substring(0, 10);
      this.setupPickupLocationFields();
    }
    for (let stop of this.loadService.loadToEdit.dropoff_stops) {
      stop.date = stop.date.toString().substring(0, 10);
      this.setupDropoffLocationFields();
    }
  }

  addEquipment() {
    this.loadService.loadToEdit.equipments.push({
      name: ''
    });
    this.setupEquipmentFields();
  }

  private setupEquipmentFields() {
    const typeahead = new Subject<string>();
    this.equipmentInput$.push(typeahead);
    this.equipment$.push(concat(this.fullEquipmentList$, typeahead.pipe(
      filter(term => term != null),
      map(term => this.fullEquipmentList$.getValue().filter(e => e.name.toLowerCase().includes(term.toLowerCase())))
    )));
  }

  removeEquipment(i: number) {
    this.loadService.loadToEdit.equipments.splice(i, 1);
    this.equipmentInput$.splice(i, 1);
    this.equipment$.splice(i, 1);
  }

  trackEquipmentByFn(item: CarrierEquipment) {
    return item.id;
  }

  createEquipment = (name: string) => {
    this.equipmentSelects.forEach(ref => ref.close());
    this.equipmentLoading = true;
    return new Promise((resolve, reject) => {
      this.carrierService.createCarrierEquipment(name).pipe(
        finalize(() => this.equipmentLoading = false),
        takeUntil(this.destroy$)
      ).subscribe(equipmentId => {
        const equipment = {
          id: equipmentId,
          name
        };
        let equipmentList = this.fullEquipmentList$.getValue();
        equipmentList.push(equipment);
        equipmentList = equipmentList.sort((a, b) => a.name < b.name ? -1 : 1);
        this.fullEquipmentList$.next(equipmentList);
        this.equipmentInput$.forEach(input => input.next(''));
        resolve(equipment);
      }, err => {
        this.snackbar.open('Failed to create equipment. Please try again or contact administrator.', null, {
          duration: 5000
        });
        reject('No equipment created');
      });
    });
  }

  private loadEquipment() {
    this.equipmentLoading = true;
    this.carrierService.getAllEquipment().pipe(
      finalize(() => this.equipmentLoading = false),
      takeUntil(this.destroy$)
    ).subscribe(res => this.fullEquipmentList$.next(res));

    for (let _ of this.loadService.loadToEdit.equipments) {
      this.setupEquipmentFields();
    }
  }

  searchCarriers() {
    let from, to = '';
    // const pickupLocation = this.loadService.loadToEdit.pickup_location;
    // const dropoffLocation = this.loadService.loadToEdit.dropoff_location;
    // if (pickupLocation != null) {
    //   from = pickupLocation.address + ', ' + pickupLocation.city + ' ' + pickupLocation.state + ' ' + pickupLocation.zip;
    // }
    // if (dropoffLocation != null) {
    //   to = dropoffLocation.address + ', ' + dropoffLocation.city + ' ' + dropoffLocation.state + ' ' + dropoffLocation.zip;
    // }
    const dialog = this.dialog.open(CarrierSearchComponent, {
      data: {
        fromAddress: from,
        toAddress: to
      },
      width: '900px',
      maxHeight: '90vh'
    });

    dialog.afterClosed().pipe(
      takeUntil(this.destroy$)
    ).subscribe(({ carrier }) => {
      if (carrier != null) {
        this.loadService.loadToEdit.carrier_id = carrier.id;
        this.loadService.loadToEdit.carrier = carrier;
        this.carrierSelected$.next();
      }
    });
  }

  clearCarrier() {
    this.loadService.loadToEdit.carrier_id = null;
    this.loadService.loadToEdit.carrier = null;
    this.loadService.loadToEdit.carrier_driver_id = null;
    this.loadService.loadToEdit.carrier_driver = null;
    this.loadService.loadToEdit.carrier_booking_agent_id = null;
    this.loadService.loadToEdit.carrier_booking_agent = null;
  }

  trackDriverByFn(item: CarrierDriver) {
    return item.id;
  }

  addDriver = (name: string) => {
    this.driverSelect.close();
    const dialog = this.dialog.open(AddCarrierDriverComponent, {
      data: {
        carrierId: this.loadService.loadToEdit.carrier.id,
        name
      }
    });
    return new Promise((resolve, reject) => {
      dialog.afterClosed().pipe(
        takeUntil(this.destroy$)
      ).subscribe(({ created, item }) => {
        if (created) {
          this.snackbar.open('Driver created', null, {
            duration: 3000
          });
          this.fullDriverList.unshift(item);
          this.driverInput$.next('');
          resolve(item);
        } else {
          this.snackbar.open('Failed to create driver. Please try again or contact administrator.', null, {
            duration: 5000
          });
          reject('No driver created');
        }
      })
    });
  }

  private loadDrivers() {
    this.drivers$ = merge(of([]), this.driverInput$.pipe(
      filter(term => term != null),
      map(term => this.fullDriverList.filter(driver => driver.name.toLowerCase().includes(term.toLowerCase())))
    ), this.carrierSelected$.pipe(
      switchMapTo(of([]))
    ), this.carrierSelected$.pipe(
      filter(() => this.loadService.loadToEdit.carrier != null),
      tap(() => this.driverLoading = true),
      switchMap(() => this.carrierService.getDrivers(this.loadService.loadToEdit.carrier.id).pipe(
        catchError((): Observable<CarrierDriver[]> => of([])),
        tap(res => {
          this.driverLoading = false;
          this.fullDriverList = res;
        })
      ))
    ));
  }

  trackBookingAgentByFn(item: CarrierBookingAgent) {
    return item.id;
  }

  addBookingAgent = (name: string) => {
    this.bookingAgentSelect.close();
    const dialog = this.dialog.open(AddCarrierBookingAgentComponent, {
      data: {
        carrierId: this.loadService.loadToEdit.carrier.id,
        name
      }
    });
    return new Promise((resolve, reject) => {
      dialog.afterClosed().pipe(
        takeUntil(this.destroy$)
      ).subscribe(({ created, item }) => {
        if (created) {
          this.snackbar.open('Booking agent created', null, {
            duration: 3000
          });
          this.fullBookingAgentList.unshift(item);
          this.bookingAgentInput$.next('');
          resolve(item);
        } else {
          this.snackbar.open('Failed to create booking agent. Please try again or contact administrator.', null, {
            duration: 5000
          });
          reject('No booking agent created');
        }
      })
    });
  }

  private loadBookingAgents() {
    this.bookingAgents$ = merge(of([]), this.bookingAgentInput$.pipe(
      filter(term => term != null),
      map(term => this.fullBookingAgentList.filter(bookingAgent => bookingAgent.name.toLowerCase().includes(term.toLowerCase())))
    ), this.carrierSelected$.pipe(
      switchMapTo(of([]))
    ), this.carrierSelected$.pipe(
      filter(() => this.loadService.loadToEdit.carrier != null),
      tap(() => this.bookingAgentLoading = true),
      switchMap(() => this.carrierService.getBookingAgents(this.loadService.loadToEdit.carrier.id).pipe(
        catchError((): Observable<CarrierBookingAgent[]> => of([])),
        tap(res => {
          this.bookingAgentLoading = false;
          this.fullBookingAgentList = res;
        })
      ))
    ));
  }

  addCarrier() {
    const dialog = this.dialog.open(AddCarrierComponent, {
      maxHeight: '90vh',
    });
    dialog.afterClosed().pipe(
      takeUntil(this.destroy$)
    ).subscribe(({ created, item }) => {
      if (created) {
        this.auth.isAdmin$.subscribe(isAdmin => {
          if (isAdmin) {
            this.snackbar.open('Carrier created', null, {
              duration: 3000
            });
            this.loadService.loadToEdit.carrier_id = item.id;
            this.loadService.loadToEdit.carrier = item;
            this.carrierSelected$.next();
          } else {
            this.snackbar.open('Carrier created. Pending admin approval', null, {
              duration: 5000
            });
          }
        })
      } else {
        this.snackbar.open('Failed to create carrier. Please try again or contact administrator.', null, {
          duration: 5000
        });
      }
    });
  }

  addChargeLineItem() {
    this.loadService.loadToEdit.charge_line_items.push({
      name: '',
      charge: 0,
      type: ChargeLineItemType.Carrier
    });
  }

  removeChargeLineItem(i: number) {
    this.loadService.loadToEdit.charge_line_items.splice(i, 1);
    this.calculatePayment();
  }

  calculateMargin() {
    const currentLoad = this.loadService.loadToEdit;
    currentLoad.charge = Math.round(currentLoad.charge * 100) / 100;
    currentLoad.compensation = Math.round(currentLoad.compensation * 100) / 100;
    this.margin = Math.round((currentLoad.charge + currentLoad.client_accessorial - currentLoad.carrier_accessorial - currentLoad.compensation) * 100) / 100;
  }

  calculatePayment() {
    let carrier_total = 0;
    let client_total = 0;
    for (let lineItem of this.loadService.loadToEdit.charge_line_items) {
      lineItem.charge = Math.round(lineItem.charge * 100) / 100;
      if (lineItem.type === ChargeLineItemType.Carrier) {
        carrier_total += lineItem.charge;
      } else if (lineItem.type === ChargeLineItemType.Client) {
        client_total += lineItem.charge;
      }
    }
    this.loadService.loadToEdit.carrier_accessorial = Math.round(carrier_total * 100) / 100;
    this.loadService.loadToEdit.client_accessorial = Math.round(client_total * 100) / 100;
    this.calculateMargin();
  }

  getHours(): number {
    const d = new Date();
    return d.getHours();
  }

  save() {
    this.isSaving = true;

    let loadTender = null;
    if (this.loadTenderPond.getFile() != null) {
      loadTender = this.loadTenderPond.getFile().file;
    }

    this.loadService.addNewLoad(this.loadService.loadToEdit, loadTender).pipe(
      finalize(() => this.isSaving = false),
      takeUntil(this.destroy$)
    ).subscribe(loadId => {
      this.errors = [];
      this.snackbar.open(this.successText, null, {
        duration: 5000
      });
      if (this.loadService.loadToEdit.carrier_booking_agent != null) {
        this.auth.userEmail$.pipe(
          takeUntil(this.destroy$),
          switchMap(email => {
            const dialogRef = this.dialog.open(SendRateConEmailComponent, {
              width: '50%',
              maxHeight: '90vh',
              data: {
                loadId,
                emailsTo: this.loadService.loadToEdit.carrier_booking_agent ? [ this.loadService.loadToEdit.carrier_booking_agent.email ] : [],
                emailsCc: [ email ],
                displaySkip: true
              }
            });
            return dialogRef.afterClosed();
          })
        ).subscribe(({ skipped, success }: { skipped?: boolean, success?: boolean }) => {
          this.performCleanup(loadId);
        });
      } else {
        this.performCleanup(loadId);
      }
    }, err => {
      if (err.error?.errors) {
        this.errors = [].concat.apply([], Object.values(err.error.errors));
      } else if (err.error?.message) {
        this.errors = [err.error.message];
      } else {
        this.errors = ['An unexpected error has occurred. Please try again or contact administrator.'];
      }
    });
  }

  cancel() {
    this.loadService.loadToEdit = null;
    this.location.back();
  }

  loadTenderRemoved() {
    this.loadService.loadToEdit.load_tender_url = null;
  }

  private getInitialMargin() {
    return (this.loadService.loadToEdit?.charge ?? 0) + (this.loadService.loadToEdit?.carrier_accessorial ?? 0) - (this.loadService.loadToEdit?.client_accessorial ?? 0) - (this.loadService.loadToEdit?.compensation ?? 0);
  }

  private performCleanup(loadId?: number) {
    if (this.loadService.loadToEdit.quote_id != null) {
      this.router.navigate(['/load', loadId]);
    } else {
      this.location.back();
    }
    this.loadService.loadToEdit = null;
  }

}
