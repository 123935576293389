<div class="form-container">
  <div class="header-container">
    <h1>Add Location</h1>
    <div class="buttons-container">
      <button class="btn-primary" (click)="save()" [disabled]="isSaving">Save</button>
      <button class="btn-secondary" (click)="cancel()" [disabled]="isSaving">Cancel</button>
    </div>
  </div>
  <div class="errors-container" *ngIf="errors.length > 0">
    <div class="error-line" *ngFor="let error of errors">{{ error }}</div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="label">Location Label</label>
        <input class="full-width" id="label" type="text" [(ngModel)]="location.label" [disabled]="isSaving" />
      </div>
      <div class="form-field-container">
        <label for="address">Address</label>
        <input #addressText placeholder="Start typing an address" [disabled]="!mapLoaded" class="full-width" id="address" type="text" [(ngModel)]="location.address" [disabled]="isSaving" />
      </div>
    </div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="city">City</label>
        <input id="city" type="text" [(ngModel)]="location.city" [disabled]="isSaving" />
      </div>
      <div class="form-field-container">
        <label for="state">State</label>
        <input id="state" type="text" [(ngModel)]="location.state" [disabled]="isSaving" />
      </div>
      <div class="form-field-container">
        <label for="zip">ZIP</label>
        <input id="zip" type="text" [(ngModel)]="location.zip" [disabled]="isSaving" />
      </div>
    </div>
  </div>
</div>
