<div class="buttons-container">
  <input type="text" placeholder="Type to filter results" (input)="filterQueryChanged($event.target.value)" />
  <a class="btn-primary" [routerLink]="['/add-carrier']">+ New carrier</a>
</div>
<div class="table-container">
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover display nowrap">
    <thead>
      <tr>
        <th>Carrier #</th>
        <th>MC #</th>
        <th>DOT #</th>
        <th>Business Name</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody *ngIf="carriers?.length !== 0">
      <tr class="clickable" *ngFor="let carrier of carriers" (click)="goToCarrier(carrier.id)">
        <td>{{ carrier.id }}</td>
        <td>{{ carrier.mc_num ? carrier.mc_num : '-' }}</td>
        <td>{{ carrier.dot_num ? carrier.dot_num : '-' }}</td>
        <td>{{ carrier.business_name }}</td>
        <td [class.inactive]="carrierService.getCarrierStatus(carrier) !== 'Active'" [class.active]="carrierService.getCarrierStatus(carrier) === 'Active'">{{ carrierService.getCarrierStatus(carrier) }}</td>
      </tr>
    </tbody>
    <tbody *ngIf="carriers?.length === 0">
      <tr>
        <td colspan="4">No data found</td>
      </tr>
    </tbody>
  </table>
</div>
