import { Moment } from "moment";
import { Load } from "./load.model";

export class EmployeeIssue {
  id: number;
  load_id?: number;
  employee_id: number;
  notes: string;
  created_at: Moment;
  for_load?: Load;
}
