<div class="buttons-container">
  <input type="text" placeholder="Type to filter results" (input)="filterQueryChanged($event.target.value)" />
  <a class="btn-primary" [routerLink]="['/add-employee']" *ngIf="auth.isAdmin$ | async">+ New employee</a>
</div>
<div class="table-container">
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover display nowrap">
    <thead>
      <tr>
        <th>Employee #</th>
        <th>Name</th>
        <th>Email</th>
        <th>Role</th>
      </tr>
    </thead>
    <tbody *ngIf="employees?.length !== 0">
      <tr class="clickable" *ngFor="let employee of employees" (click)="goToEmployee(employee.id)">
        <td>{{ employee.id }}</td>
        <td>{{ employee.user_info.first_name + ' ' + employee.user_info.last_name }}</td>
        <td>{{ employee.email }}</td>
        <td>{{ roleToLabelMapping[employee.role] }}</td>
      </tr>
    </tbody>
    <tbody *ngIf="employees?.length === 0">
      <tr>
        <td colspan="4">No data found</td>
      </tr>
    </tbody>
  </table>
</div>
