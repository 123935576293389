<div class="form-container">
  <div class="header-container">
    <h1>{{ headerTitle }}</h1>
    <div class="buttons-container">
      <button class="btn-primary" (click)="save()" [disabled]="isSaving">Save</button>
      <button class="btn-secondary" (click)="cancel()" [disabled]="isSaving">Cancel</button>
    </div>
  </div>
  <div class="errors-container" *ngIf="errors.length > 0">
    <div class="error-line" *ngFor="let error of errors">{{ error }}</div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container two-widths">
        <label>Client</label>
        <ng-select
          [items]="clients$ | async"
          bindLabel="name"
          [addTag]="addClient"
          [trackByFn]="trackClientByFn"
          [minTermLength]="2"
          [loading]="clientLoading"
          typeToSearchText="Start typing to search..."
          [typeahead]="clientInput$"
          [(ngModel)]="quoteService.quoteToEdit.client"
          (change)="newClientSelected()">
        </ng-select>
      </div>
      <div class="form-field-container">
        <label for="charge">Rate</label>
        <input id="charge" type="number" step="0.01" [(ngModel)]="quoteService.quoteToEdit.rate" (wheel)="$event.target.blur()" (change)="roundRate()" />
      </div>
    </div>
  </div>
  <h2>Pickup Details</h2>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="pickup_city">City</label>
        <input #pickupCityText id="pickup_city" type="text" placeholder="Start typing a city" [(ngModel)]="quoteService.quoteToEdit.pickup_city" [disabled]="!mapLoaded" />
      </div>
      <div class="form-field-container">
        <label for="pickup_state">State</label>
        <input id="pickup_state" type="text" [(ngModel)]="quoteService.quoteToEdit.pickup_state" [disabled]="!mapLoaded" />
      </div>
      <div class="form-field-container two-widths">
        <label>Client Business Location</label>
        <ng-select
          #pickupLocationSelect
          [items]="pickupLocations$ | async"
          bindLabel="full_address"
          [addTag]="addBusinessLocation"
          [trackByFn]="trackLocationByFn"
          [loading]="pickupLocationLoading"
          typeToSearchText="None found. Start typing to add"
          [typeahead]="pickupLocationInput$"
          [(ngModel)]="quoteService.quoteToEdit.pickup_location"
          [disabled]="!quoteService.quoteToEdit.client || pickupLocationLoading"
          [placeholder]="!quoteService.quoteToEdit.client ? 'Select a client first' : ''">
        </ng-select>
      </div>
    </div>
    <div class="form-row flex">
      <div class="form-field-container two-widths">
        <label for="pickup_details">Pickup Notes</label>
        <input id="pickup_details" type="text" [(ngModel)]="quoteService.quoteToEdit.pickup_shipment_description" />
      </div>
    </div>
  </div>
  <h2>Dropoff Details</h2>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="dropoff_city">City</label>
        <input #dropoffCityText id="dropoff_city" type="text" placeholder="Start typing a city" [(ngModel)]="quoteService.quoteToEdit.dropoff_city" [disabled]="!mapLoaded" />
      </div>
      <div class="form-field-container">
        <label for="dropoff_state">State</label>
        <input id="dropoff_state" type="text" [(ngModel)]="quoteService.quoteToEdit.dropoff_state" [disabled]="!mapLoaded" />
      </div>
      <div class="form-field-container two-widths">
        <label>Client Business Location</label>
        <ng-select
          #dropoffLocationSelect
          [items]="dropoffLocations$ | async"
          bindLabel="full_address"
          [addTag]="addBusinessLocation"
          [trackByFn]="trackLocationByFn"
          [loading]="dropoffLocationLoading"
          typeToSearchText="None found. Start typing to add"
          [typeahead]="dropoffLocationInput$"
          [(ngModel)]="quoteService.quoteToEdit.dropoff_location"
          [disabled]="!quoteService.quoteToEdit.client || dropoffLocationLoading"
          [placeholder]="!quoteService.quoteToEdit.client ? 'Select a client first' : ''">
        </ng-select>
      </div>
    </div>
    <div class="form-row flex">
      <div class="form-field-container two-widths">
        <label for="dropoff_details">Dropoff Notes</label>
        <input id="dropoff_details" type="text" [(ngModel)]="quoteService.quoteToEdit.dropoff_shipment_description" />
      </div>
    </div>
  </div>
  <h2>Equipment Required</h2>
  <div class="form-group-container">
    <div class="form-row flex" *ngFor="let equipment of quoteService.quoteToEdit.equipments; let i = index">
      <div class="form-field-container two-widths">
        <ng-select
          #equipmentSelects
          [items]="equipment$[i] | async"
          bindLabel="name"
          [addTag]="(auth.isOps$ | async) ? createEquipment : null"
          [trackByFn]="trackEquipmentByFn"
          [loading]="equipmentLoading"
          [typeahead]="equipmentInput$[i]"
          [(ngModel)]="quoteService.quoteToEdit.equipments[i]"
          [disabled]="equipmentLoading">
        </ng-select>
      </div>
    </div>
  </div>
</div>
