import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  private headerTitleSubject: BehaviorSubject<string> = new BehaviorSubject('');
  headerTitle = this.headerTitleSubject.asObservable();

  private hasGrayBgSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hasGrayBg = this.hasGrayBgSubject.asObservable();

  constructor() { }

  setHeaderTitle(title: string) {
    this.headerTitleSubject.next(title);
  }

  setGrayBg(hasGrayBg: boolean) {
    this.hasGrayBgSubject.next(hasGrayBg);
  }
}
