import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UpdateOwnerComponentType } from 'src/app/enums/update-owner-component.type.enum';
import { User } from 'src/app/models/user.model';
import { ClientService } from 'src/app/services/client.service';
import { LoadService } from 'src/app/services/load.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-update-owner',
  templateUrl: './update-owner.component.html',
  styleUrls: ['./update-owner.component.scss']
})
export class UpdateOwnerComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<null>();

  isLoading = true;

  users: User[];
  id: number;

  selectedUserId: number;

  type: UpdateOwnerComponentType;

  constructor(
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private clientService: ClientService,
    private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<UpdateOwnerComponent>,
    private loadService: LoadService
  ) { }

  ngOnInit(): void {
    this.id = this.data.id;
    this.type = this.data.type;
    this.userService.getAllUsers().pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.destroy$)
    ).subscribe(res => this.users = res.data);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  getFullName(user: User) {
    return (user.user_info.first_name + ' ' + user.user_info.last_name).trim();
  }

  changeOwner() {
    this.isLoading = true;
    let obs = null;
    if (this.type === UpdateOwnerComponentType.Client) {
      obs = this.clientService.changeClientOwner(this.id, this.selectedUserId);
    } else if (this.type === UpdateOwnerComponentType.Load) {
      obs = this.loadService.changeLoadOwner(this.id, this.selectedUserId);
    }
    obs.pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.destroy$)
    ).subscribe(success => {
      this.snackbar.open('Owner changed', null, {
        duration: 3000
      });
      this.dialogRef.close({ success: true });
    });
  }

}
