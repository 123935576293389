import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<null>();

  email: string;
  submissionResults: string;
  isSubmitting = false;
  isError = false;

  constructor(
    private auth: AuthService,
    private uiService: UiService
  ) {
    this.uiService.setHeaderTitle('Forgot password / First-time login.')
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  resetPassword() {
    this.isSubmitting = true;
    this.submissionResults = '';
    this.auth.forgotPassword(this.email).pipe(
      finalize(() => this.isSubmitting = false),
      takeUntil(this.destroy$)
    ).subscribe(res => {
      if (res) {
        this.isError = false;
        this.submissionResults = `Password reset link sent to ${ this.email }`;
      } else {
        this.isError = true;
        this.submissionResults = 'Failed to send password reset link. Please make sure the provided email has already been approved by admin.';
      }
    }, err => {
      this.isError = true;
      if (err.error?.message) {
        this.submissionResults = `Error: ${ err.error?.message }`;
      } else {
        this.submissionResults = 'An unknown error has occurred. Please try again or contact administrator.';
      }
    });
  }

}
