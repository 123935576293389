import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ExpiredTokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private newTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private auth: AuthService,
    private tokenService: TokenService,
    private router: Router,
    private snackbar: MatSnackBar
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(err => {
      // if (err instanceof HttpErrorResponse && err.status === 401 && this.tokenService.isLoggedIn()) {
      //   return this.handle401Error(request, next);
      // }
      if (err instanceof HttpErrorResponse && err.status === 401) {
        this.tokenService.removeToken();
        this.auth.userAuthStateSubject.next(false);
        this.router.navigate(['/login']);
        // if (err.error?.message) {
        //   this.snackbar.open(err.error.message, null, {
        //     duration: 5000
        //   });
        // }
      }
      return throwError(err);
    }));
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.newTokenSubject.next(null);
      return this.auth.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.newTokenSubject.next(token.access_token);
          return next.handle(req);
        }),
        catchError(err => {
          this.isRefreshing = false;
          this.router.navigate(['/login']);
          return throwError(err);
        })
      )
    }
    return this.newTokenSubject.pipe(
      filter(token => token != null),
      take(1),
      switchMap(token => next.handle(req))
    );
  }
}
