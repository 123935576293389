import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Quote } from 'src/app/models/quote.model';
import { QuoteService } from 'src/app/services/quote.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-quotes-table',
  templateUrl: './quotes-table.component.html',
  styleUrls: ['./quotes-table.component.scss']
})
export class QuotesTableComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(DataTableDirective, { static: false }) private dtElem: DataTableDirective;

  currentQuery = '';
  filterQuery$ = new Subject<string>();
  filterQueryDebounced$ = new Observable<string>();

  destroy$ = new Subject<null>();

  dtOptions: DataTables.Settings = {};
  quotes: Quote[];

  constructor(
    public quoteService: QuoteService,
    private router: Router,
    private uiService: UiService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      lengthChange: false,
      searching : false,
      columns: [
        { name: 'id', orderable: true },
        { name: 'client', orderable: false },
        { name: 'pickup_location', orderable: false },
        { name: 'dropoff_location', orderable: false },
        { name: 'rate', orderable: true },
      ],
      order: [[0, 'desc']],
      ajax: (dtParams, callback) => {
        this.route.data.pipe(switchMap(data => {
          if (data.allQuotes) {
            this.uiService.setHeaderTitle('Quotes List (all)');
            return this.quoteService.getAllQuotes(dtParams, this.currentQuery);
          } else {
            this.uiService.setHeaderTitle('Quotes List');
            return this.quoteService.getMyQuotes(dtParams, this.currentQuery);
          }
        }), takeUntil(this.destroy$), takeUntil(this.filterQueryDebounced$)).subscribe(res => {
          this.quotes = res.data;
          callback({
            recordsTotal: res.recordsTotal,
            recordsFiltered: res.recordsFiltered,
            data: []
          });
        });
      }
    };

    this.filterQueryDebounced$ = this.filterQuery$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(query => this.currentQuery = query),
      takeUntil(this.destroy$)
    );
  }

  ngAfterViewInit(): void {
    this.filterQueryDebounced$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(_ => {
      this.dtElem.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload();
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  goToQuote(id: number) {
    this.router.navigate(['/quote', id]);
  }

  filterQueryChanged(query: string) {
    this.filterQuery$.next(query);
  }

}
