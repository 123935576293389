import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { LoadService } from 'src/app/services/load.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<null>();

  currentRoute: string;
  userFullName = this.auth.userFullName$;

  constructor(
    private router: Router,
    public auth: AuthService,
    public loadService: LoadService
  ) {
    this.router.events.pipe(
      takeUntil(this.destroy$)
    ).subscribe(route => {
      if (route instanceof NavigationEnd) {
        this.currentRoute = route.urlAfterRedirects.slice(1);
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {
  }

}
