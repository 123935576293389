<div class="container">
  <input placeholder="Email" [(ngModel)]="email" (keyup.enter)="login()" />
  <div class="password-input-container">
    <input placeholder="Password" [type]="isPasswordVisible ? 'text' : 'password'" [(ngModel)]="password" (keyup.enter)="login()" />
    <div class="icon-container" (click)="togglePasswordVisibility()">
      <mat-icon>{{ isPasswordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
    </div>
  </div>
  <button class="btn-primary" (click)="login()" [disabled]="isLoading">Login</button>
  <a class="btn-secondary" [routerLink]="['/forgot-password']">First-time login / Forgot password</a>
  <p class="alert">{{ errorMessage }}</p>
</div>
