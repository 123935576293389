export class CarrierSearch {
  term: string;
  from_lat: number;
  from_lng: number;
  from_radius: number;
  to_lat: number;
  to_lng: number;
  to_radius: number;

  constructor() {
    this.from_radius = 25;
    this.to_radius = 25;
  }
}
