import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DtResponse } from '../models/dt-response.model';
import { Quote } from '../models/quote.model';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  quoteToEdit: Quote;

  constructor(
    private http: HttpClient
  ) { }

  getMyQuotes(dtParams: any, searchQuery: string = ''): Observable<DtResponse<Quote>> {
    return this.http.post<DtResponse<Quote>>(`${ environment.apiUrl }/quote/my-quotes`, {
      ...dtParams,
      search_query: searchQuery
    });
  }

  getAllQuotes(dtParams: any, searchQuery: string = ''): Observable<DtResponse<Quote>> {
    return this.http.post<DtResponse<Quote>>(`${ environment.apiUrl }/quote/all-quotes`, {
      ...dtParams,
      search_query: searchQuery
    });
  }

  getQuoteDetails(id: number): Observable<Quote> {
    return this.http.get<Quote>(`${ environment.apiUrl }/quote/${ id }`).pipe(
      map(quote => {
        if (quote.pickup_location) {
          let loc = quote.pickup_location;
          quote.pickup_location.full_address = `${ loc.label ? loc.label + ' - ' : '' }${ loc.address }, ${ loc.city } ${ loc.state }`;
        }
        if (quote.dropoff_location) {
          let loc = quote.dropoff_location;
          quote.dropoff_location.full_address = `${ loc.label ? loc.label + ' - ' : '' }${ loc.address }, ${ loc.city } ${ loc.state }`;
        }
        return quote;
      })
    );
  }

  addNewQuote(quote: Quote): Observable<number> {
    quote.client_id = quote.client?.id;
    quote.pickup_location_id = quote.pickup_location?.id;
    quote.dropoff_location_id = quote.dropoff_location?.id;

    return this.http.post<number>(`${ environment.apiUrl }/quote/create-update`, quote);
  }

  getPickupLocation(quote: Quote): string {
    if (quote.pickup_location) {
      return (quote.pickup_location.label ? quote.pickup_location.label + ' - ' : '') + quote.pickup_location.address + ', ' + quote.pickup_location.city + ', ' + quote.pickup_location.state + ' ' + quote.pickup_location.zip;
    } else {
      return quote.pickup_city + ', ' + quote.pickup_state;
    }
  }

  getDropoffLocation(quote: Quote): string {
    if (quote.dropoff_location) {
      return (quote.dropoff_location.label ? quote.dropoff_location.label + ' - ' : '') + quote.dropoff_location.address + ', ' + quote.dropoff_location.city + ', ' + quote.dropoff_location.state + ' ' + quote.dropoff_location.zip;
    } else {
      return quote.dropoff_city + ', ' + quote.dropoff_state;
    }
  }

  deleteQuote(id: number): Observable<boolean> {
    return this.http.post<boolean>(`${ environment.apiUrl }/quote/delete`, {
      id
    });
  }
}
