import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { PlaceAddress } from '../models/place-address.model';

@Injectable({
  providedIn: 'root',
})
export class GeocodingService {

  private geocoder: google.maps.Geocoder;

  constructor(private mapLoader: MapsAPILoader) {}

  private initGeocoder() {
    this.geocoder = new google.maps.Geocoder();
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.geocoder) {
      return from(this.mapLoader.load()).pipe(
        tap(() => this.initGeocoder()),
        map(() => true)
      );
    }
    return of(true);
  }

  getLatLng(address: string): Observable<google.maps.GeocoderResult[]> {
    return this.waitForMapsToLoad().pipe(
      switchMap(() => {
        return new Observable<google.maps.GeocoderResult[]>(observer => {
          this.geocoder.geocode({ address }, (
            (res: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
              if (status === google.maps.GeocoderStatus.OK) {
                observer.next(res);
                observer.complete();
              } else {
                observer.next([]);
                observer.complete();
              }
            }
          ))
        })
      })
    );
  }

  deconstructGooglePlaceResult(place: google.maps.places.PlaceResult): PlaceAddress {
    let street_number: string, route: string, city: string, state: string, zip: string, country: string;

    for (const component of place.address_components) {
      const addressTypes = component.types;
      if (addressTypes.includes('postal_code')) {
        zip = component.short_name;
      } else if (addressTypes.includes('country')) {
        country = component.long_name;
      } else if (addressTypes.includes('administrative_area_level_1')) {
        state = component.short_name;
      } else if (addressTypes.includes('locality')) {
        city = component.long_name;
      } else if (addressTypes.includes('administrative_area_level_3') && city == null) { // do not overwrite locality
        city = component.long_name;
      } else if (addressTypes.includes('street_number')) {
        street_number = component.long_name;
      } else if (addressTypes.includes('route')) {
        route = component.short_name;
      }
    }

    let res = new PlaceAddress();

    res.formatted_address = place.formatted_address;
    if (street_number != null && route != null) {
      res.address = street_number + ' ' + route;
    } else if (place.vicinity != null) {
      res.address = place.vicinity?.split(', ')[0];
    }
    res.city = city;
    res.state = state;
    res.zip = zip;
    res.country = country;
    res.lat = place.geometry['location'].lat();
    res.lng = place.geometry['location'].lng();

    return res;
  }
}
