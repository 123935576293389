import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from "angular-datatables";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthTokenInterceptor } from './interceptors/auth-token.interceptor';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MainComponent } from './components/main/main.component';
import { LoadsTableComponent } from './components/loads-table/loads-table.component';
import { ReportsListComponent } from './components/reports-list/reports-list.component';
import { ExpiredTokenInterceptor } from './interceptors/expired-token.interceptor';
import { AuthService } from './services/auth.service';
import { Observable } from 'rxjs';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { CarriersTableComponent } from './components/carriers-table/carriers-table.component';
import { CarrierDetailsComponent } from './components/carrier-details/carrier-details.component';
import { AddCarrierComponent } from './components/add-carrier/add-carrier.component';
import { FilePondModule } from 'ngx-filepond';
import { ClientsTableComponent } from './components/clients-table/clients-table.component';
import { ClientDetailsComponent } from './components/client-details/client-details.component';
import { AddClientComponent } from './components/add-client/add-client.component';
import { LoadDetailsComponent } from './components/load-details/load-details.component';
import { AddLoadComponent } from './components/add-load/add-load.component';
import { AddClientBusinessLocationComponent } from './components/add-client-business-location/add-client-business-location.component';
import { AddCarrierDriverComponent } from './components/add-carrier-driver/add-carrier-driver.component';
import { AddCarrierBookingAgentComponent } from './components/add-carrier-booking-agent/add-carrier-booking-agent.component';
import { DocumentsTableComponent } from './components/documents-table/documents-table.component';
import { UpdateOwnerComponent } from './components/update-owner/update-owner.component';
import { PodUploadComponent } from './components/pod-upload/pod-upload.component';
import { FileSaverModule } from 'ngx-filesaver';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { GeocodingService } from './services/geocoding.service';
import { CarrierSearchComponent } from './components/carrier-search/carrier-search.component';
import { AddCarrierNoteComponent } from './components/add-carrier-note/add-carrier-note.component';
import { ReportRevenueComponent } from './components/report-revenue/report-revenue.component';
import { ReportClientComponent } from './components/report-client/report-client.component';
import { ReportLoadComponent } from './components/report-load/report-load.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { EmployeesTableComponent } from './components/employees-table/employees-table.component';
import { EmployeeDetailsComponent } from './components/employee-details/employee-details.component';
import { AddEmployeeComponent } from './components/add-employee/add-employee.component';
import { SendManagementAlertComponent } from './components/send-management-alert/send-management-alert.component';
import { LogEmployeeIssueComponent } from './components/log-employee-issue/log-employee-issue.component';
import { QuotesTableComponent } from './components/quotes-table/quotes-table.component';
import { AddQuoteComponent } from './components/add-quote/add-quote.component';
import { QuoteDetailsComponent } from './components/quote-details/quote-details.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { LoginMainComponent } from './components/login-main/login-main.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ReportEmployeeComponent } from './components/report-employee/report-employee.component';
import { ReportCarrierComponent } from './components/report-carrier/report-carrier.component';
import { ReportEmployeeSelfComponent } from './components/report-employee-self/report-employee-self.component';
import { ReportLeaderboardComponent } from './components/report-leaderboard/report-leaderboard.component';
import { DocumentUploadComponent } from './components/document-upload/document-upload.component';
import { SendRateConEmailComponent } from './components/send-rate-con-email/send-rate-con-email.component';
import { MatChipsModule } from '@angular/material/chips';

function initializeAppFactory(authService: AuthService): () => Observable<any> {
  return () => authService.refreshToken();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    DashboardComponent,
    MainComponent,
    LoadsTableComponent,
    ReportsListComponent,
    DateTimePickerComponent,
    CarriersTableComponent,
    CarrierDetailsComponent,
    AddCarrierComponent,
    ClientsTableComponent,
    ClientDetailsComponent,
    AddClientComponent,
    LoadDetailsComponent,
    AddLoadComponent,
    AddClientBusinessLocationComponent,
    AddCarrierDriverComponent,
    AddCarrierBookingAgentComponent,
    DocumentsTableComponent,
    UpdateOwnerComponent,
    PodUploadComponent,
    CarrierSearchComponent,
    AddCarrierNoteComponent,
    ReportRevenueComponent,
    ReportClientComponent,
    ReportLoadComponent,
    EmployeesTableComponent,
    EmployeeDetailsComponent,
    AddEmployeeComponent,
    SendManagementAlertComponent,
    LogEmployeeIssueComponent,
    QuotesTableComponent,
    AddQuoteComponent,
    QuoteDetailsComponent,
    AdminDashboardComponent,
    LoginMainComponent,
    ConfirmDialogComponent,
    ReportEmployeeComponent,
    ReportCarrierComponent,
    ReportEmployeeSelfComponent,
    ReportLeaderboardComponent,
    DocumentUploadComponent,
    SendRateConEmailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    DataTablesModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    MatDialogModule,
    NgxMatMomentModule,
    FilePondModule,
    MatSnackBarModule,
    MatIconModule,
    NgSelectModule,
    FileSaverModule,
    HttpClientJsonpModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ['places']
    }),
    NgxDaterangepickerMd.forRoot(),
    MatTooltipModule,
    MatChipsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExpiredTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    },
    GeocodingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
