<div class="container">
  <div class="sidebar-container">
    <div class="user-info-container">
      <div class="user-full-name">{{ userFullName | async }}</div>
    </div>
    <div class="nav-container">
      <div class="main-nav-links">
        <a [routerLink]="['/dashboard']" *ngIf="!(auth.isOps$ | async)" (click)="clearData()">Home</a>
        <a [routerLink]="['/admin-dashboard']" *ngIf="auth.isOps$ | async" (click)="clearData()">Home</a>
        <a [routerLink]="['/my-quotes']" (click)="clearData()">My Quotes</a>
        <a [routerLink]="['/all-quotes']" *ngIf="auth.isOps$ | async" (click)="clearData()">All Quotes</a>
        <a [routerLink]="['/my-loads']" (click)="clearData()">My Loads</a>
        <a [routerLink]="['/all-loads']" *ngIf="auth.isOps$ | async" (click)="clearData()">All Loads</a>
        <a [routerLink]="['/reports']" *ngIf="auth.isOps$ | async" (click)="clearData()">Reports</a>
        <a [routerLink]="['/carriers']" (click)="clearData()">Carriers</a>
        <a [routerLink]="['/clients']" (click)="clearData()">Clients</a>
        <a [routerLink]="['/employees']" *ngIf="auth.isOps$ | async" (click)="clearData()">Employees</a>
        <a [routerLink]="['/documents']" (click)="clearData()">Documents</a>
      </div>
      <button class="btn-secondary" (click)="logout()" [disabled]="isLoggingOut">Log out</button>
    </div>
  </div>
  <div class="content-top-container">
    <div class="content">
      <div class="header-container">
        <div class="title">{{ headerTitle | async }}</div>
      </div>
      <div class="content-container" [class.gray-bg]="hasGrayBg | async">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
