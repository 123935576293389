import { Component, OnInit } from '@angular/core';
import { Role } from 'src/app/enums/role.enum';
import { AuthService } from 'src/app/services/auth.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss']
})
export class ReportsListComponent implements OnInit {

  dtOptions: DataTables.Settings = {};

  reports = [
    {
      name: 'Revenue Report',
      link: '/report/revenue'
    },
    {
      name: 'Client Report',
      link: '/report/client'
    },
    {
      name: 'Load Report',
      link: '/report/load'
    },
    {
      name: 'Employee Report',
      link: '/report/employee'
    },
    {
      name: 'Carrier Report',
      link: '/report/carrier'
    },
    {
      name: 'Leaderboard Report',
      link: '/report/leaderboard'
    }
  ];

  constructor(
    private uiService: UiService,
    private auth: AuthService
  ) {
    this.uiService.setHeaderTitle('Reports List');

    this.auth.userRole$.subscribe(role => {
      if (role === Role.Employee) {
        this.reports = [
          {
            name: 'My Report',
            link: '/report/employee-self'
          },
          {
            name: 'Leaderboard Report',
            link: '/report/leaderboard'
          }
        ];
      }
    });
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      serverSide: false,
      lengthChange: false,
      searching : false,
      ordering: false
    };
  }

}
