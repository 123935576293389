import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Document } from '../models/document.model';
import { DtResponse } from '../models/dt-response.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private http: HttpClient
  ) { }

  getAllDocuments(dtParams: any): Observable<DtResponse<Document>> {
    return this.http.post<DtResponse<Document>>(`${ environment.apiUrl }/document/all-documents`, dtParams);
  }

  addDocument(document: Document | FormData): Observable<number> {
    return this.http.post<number>(`${ environment.apiUrl }/document/add`, document);
  }

  deleteDocument(id: number): Observable<boolean> {
    return this.http.post<boolean>(`${ environment.apiUrl }/document/delete`, {
      id
    });
  }

}
