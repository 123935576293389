export class ClientBusinessLocation {
  id?: number;
  client_id?: number;
  label?: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  lat?: number;
  lng?: number;
  full_address?: string;
}
