import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FmcsaCarrierResponse } from '../models/fmcsa-carrier-response.model';

@Injectable({
  providedIn: 'root'
})
export class FmcsaService {

  private apiUrl = `https://mobile.fmcsa.dot.gov/qc/services`;
  private webKey = '4f2226e571d8bb4dde8c842ff4d63ae7e3899033';

  constructor(
    private http: HttpClient
  ) { }

  getCarriersByMcNum(mcNum: string): Observable<FmcsaCarrierResponse> {
    mcNum = mcNum.replace( /^\D+/g, '');
    return this.http.get<FmcsaCarrierResponse>(`${ this.apiUrl }/carriers/docket-number/${ mcNum }?webKey=${ this.webKey }`);
  }

  getCarriersByDotNum(dotNum: string): Observable<FmcsaCarrierResponse> {
    dotNum = dotNum.replace( /^\D+/g, '');
    return this.http.get<FmcsaCarrierResponse>(`${ this.apiUrl }/carriers/${ dotNum }?webKey=${ this.webKey }`);
  }

}
