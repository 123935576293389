<div class="buttons-container">
  <a class="btn-secondary" (click)="goBack()">&lt; Back</a>
  <div class="right-buttons-container">
    <button class="btn-secondary" (click)="addNote()" [disabled]="isLoading">Add Note</button>
    <div [matTooltip]="load != null && load.carrier == null ? 'Select a carrier first' : ''">
      <button class="btn-secondary" (click)="downloadRateCon()" [disabled]="isLoading || load?.carrier == null">Download Rate Con</button>
    </div>
    <div [matTooltip]="load != null && load.carrier == null ? 'Select a carrier first' : ''">
      <button class="btn-secondary" (click)="sendRateConEmail()" [disabled]="isLoading || load?.carrier == null">Send Rate Con Email</button>
    </div>
    <button class="btn-secondary" (click)="changeOwner()" *ngIf="auth.isAdmin$ | async" [disabled]="isLoading">Change Owner</button>
    <button class="btn-primary" (click)="goToEditLoad()" [disabled]="isLoading">Edit Load</button>
    <button class="btn-round btn-delete" (click)="deleteLoad()" [disabled]="isLoading" *ngIf="(auth.isAdmin$ | async) || (auth.userId$ | async) === load?.owner_id"><mat-icon>delete_outlined</mat-icon></button>
  </div>
</div>
<div *ngIf="load == null || isLoading" class="loading-text">Loading...</div>
<div class="content-container" *ngIf="load != null && !isLoading">
  <div class="row">
    <h1><span class="label">Load </span>#{{ load?.visual_id }} / {{ load?.client.name }}</h1>
  </div>
  <div class="row">
    <div class="property-container">
      <span class="label">Status: </span>
      <select [class.yellow]="load?.status === 0" [class.red]="hasPassedDeadline(load?.deadline)" [class.green]="load?.status === 7" [disabled]="isStatusSelectLoading" #statusSelect (change)="optionOnChange(load.id, $event.target.value, statusSelect)">
        <option *ngFor="let status of loadStatuses" [value]="status" [selected]="load?.status === status">{{ loadStatusToLabelMapping[status] }}</option>
      </select>
    </div>
    <div class="property-container"><span class="label">Deadline: </span><span [class.expired]="isPastDate(load?.deadline)">{{ load?.deadline ? formatDateTime(load.deadline) : '-' }}</span></div>
  </div>
  <div class="row" *ngIf="auth.isOps$ | async">
    <div class="property-container">
      <span class="label">Invoicing Status: </span>
      <select *ngIf="load.status === 7" [class.green]="load?.invoicing_status === 4" [disabled]="isInvoicingStatusSelectLoading" #invoicingStatusSelect (change)="invoicingOptionOnChange(load.id, $event.target.value, invoicingStatusSelect)">
        <option *ngFor="let status of loadInvoicingStatuses" [value]="status" [selected]="load?.invoicing_status === status">{{ loadInvoicingStatusToLabelMapping[status] }}</option>
      </select>
      <div *ngIf="load.status !== 7">-</div>
    </div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Requires Assistance: </span><input type="checkbox" [checked]="load?.requires_assistance" (change)="checkboxOnChange(load?.id, $event.target.checked)" /></div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Booked by: </span>{{ load?.owner.user_info.first_name + ' ' + load?.owner.user_info.last_name }}</div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Load Tender: </span><a *ngIf="load != null && load.load_tender_url != null" [href]="load?.load_tender_url" target="_blank">File <mat-icon>attachment</mat-icon></a><span *ngIf="load?.load_tender_url == null">-</span></div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Client Invoice: </span><a *ngIf="load != null && load.client_invoice_url != null" [href]="load?.client_invoice_url" target="_blank">File <mat-icon>attachment</mat-icon></a><span *ngIf="load?.client_invoice_url == null">-</span></div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Client Payment Due Date: </span><span>{{ load?.client_payment_due_date ? formatDate(load.client_payment_due_date) : '-' }}</span></div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Carrier Invoice: </span><a *ngIf="load != null && load.carrier_invoice_url != null" [href]="load?.carrier_invoice_url" target="_blank">File <mat-icon>attachment</mat-icon></a><span *ngIf="load?.carrier_invoice_url == null">-</span></div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Carrier Payment Due Date: </span><span>{{ load?.carrier_payment_due_date ? formatDate(load.carrier_payment_due_date) : '-' }}</span></div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Proof of Delivery: </span><a *ngIf="load?.proof_of_delivery_url != null" [href]="load?.proof_of_delivery_url" target="_blank">File <mat-icon>attachment</mat-icon></a><span *ngIf="load?.proof_of_delivery_url == null">-</span></div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Charge: </span>${{ formatNumber(load?.charge) }}</div>
    <div class="property-container"><span class="label">Client Accessorial: </span>${{ formatNumber(load?.client_accessorial) }}</div>
    <div class="property-container"><span class="label">Carrier Accessorial: </span>${{ formatNumber(load?.carrier_accessorial) }}</div>
    <div class="property-container"><span class="label">Line Haul: </span>${{ formatNumber(load?.compensation) }}</div>
    <div class="property-container"><span class="label">Margin: </span>${{ formatNumber(load?.margin) }}</div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Payment Details:</span> {{ load?.charge_line_items?.length ? '' : '-' }}</div>
  </div>
  <div class="row" *ngIf="load?.charge_line_items?.length > 0">
    <table datatable [dtOptions]="dtOptions">
      <thead>
        <tr>
          <th>#</th>
          <th>Description</th>
          <th>Amount</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let lineItem of load.charge_line_items; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ lineItem.name }}</td>
          <td>${{ formatNumber(lineItem.charge) }}</td>
          <td>{{ chargeLineItemTypeToLabelMapping[lineItem.type] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <hr />
  <div class="row">
    <h2>Carrier Details</h2>
  </div>
  <div class="row" *ngIf="load?.carrier == null">
    No carrier assigned yet
  </div>
  <ng-container *ngIf="load?.carrier != null">
    <div class="row">
      <div class="property-container"><span class="label">MC #: </span>{{ load?.carrier?.mc_num ? load.carrier.mc_num : '-' }}</div>
      <div class="property-container"><span class="label">DOT #: </span>{{ load?.carrier?.dot_num ? load.carrier.dot_num : '-' }}</div>
      <div class="property-container"><span class="label">Name: </span>{{ load?.carrier?.business_name }}</div>
    </div>
    <div class="row">
      <div class="property-container"><span class="label">Driver: </span>{{ load?.carrier_driver?.name }}</div>
      <div class="property-container"><span class="label">Driver #: </span>{{ load?.carrier_driver?.driver_num }}</div>
      <div class="property-container"><span class="label">Tractor #: </span>{{ load?.carrier_driver?.tractor_num }}</div>
      <div class="property-container"><span class="label">Trailer #: </span>{{ load?.carrier_driver?.trailer_num }}</div>
    </div>
    <div class="row">
      <div class="property-container"><span class="label">Booking Agent: </span>{{ load?.carrier_booking_agent?.name }}</div>
      <div class="property-container"><span class="label">Email: </span>{{ load?.carrier_booking_agent?.email }}</div>
      <div class="property-container"><span class="label">Phone: </span>{{ load?.carrier_booking_agent?.phone }}</div>
    </div>
  </ng-container>
  <hr />
  <div class="row">
    <h2>Load Details</h2>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">BOL #: </span>{{ load?.pickup_bol_num ? load.pickup_bol_num : '-' }}</div>
    <div class="property-container"><span class="label">PO #: </span>{{ load?.dropoff_po_num ? load.dropoff_po_num : '-' }}</div>
  </div>
  <hr />
  <div class="row">
    <h2>Pickup Details</h2>
  </div>
  <div *ngFor="let stop of load?.pickup_stops; let i = index">
    <div class="row">
      <div class="stop-header">STOP #{{ i + 1 }}</div>
    </div>
    <div class="row">
      <div class="property-container"><span class="label">Address: </span>{{ getFullAddress(stop.location) }}</div>
    </div>
    <div class="row">
      <div class="property-container"><span class="label">Date & time: </span>{{ formatDate(stop.date) + ' ' + stop.time }}</div>
    </div>
    <div class="row">
      <div class="property-container"><span class="label">Contact Name: </span>{{ stop.contact_name ? stop.contact_name : '-' }}</div>
      <div class="property-container"><span class="label">Contact Phone: </span>{{ stop.contact_phone ? stop.contact_phone : '-' }}</div>
    </div>
    <div class="row">
      <div class="property-container"><span class="label">Weight: </span>{{ stop.weight ? stop.weight : '-' }}</div>
      <div class="property-container"><span class="label">Commodity: </span>{{ stop.commodity ? stop.commodity : '-' }}</div>
    </div>
    <div class="row">
      <div class="property-container"><span class="label">Pickup Description: </span>{{ stop.details ? stop.details : '-' }}</div>
    </div>
    <div class="row">
      <div class="property-container"><span class="label">Special Instructions: </span>{{ stop.special_instructions ? stop.special_instructions : '-' }}</div>
    </div>
  </div>
  <hr />
  <div class="row">
    <h2>Dropoff Details</h2>
  </div>
  <div *ngFor="let stop of load?.dropoff_stops; let i = index">
    <div class="row">
      <div class="stop-header">STOP #{{ i + 1 }}</div>
    </div>
    <div class="row">
      <div class="property-container"><span class="label">Address: </span>{{ getFullAddress(stop.location) }}</div>
    </div>
    <div class="row">
      <div class="property-container"><span class="label">Date & time: </span>{{ formatDate(stop.date) + ' ' + stop.time }}</div>
    </div>
    <div class="row">
      <div class="property-container"><span class="label">Contact Name: </span>{{ stop.contact_name ? stop.contact_name : '-' }}</div>
      <div class="property-container"><span class="label">Contact Phone: </span>{{ stop.contact_phone ? stop.contact_phone : '-' }}</div>
    </div>
    <div class="row">
      <div class="property-container"><span class="label">Weight: </span>{{ stop.weight ? stop.weight : '-' }}</div>
      <div class="property-container"><span class="label">Commodity: </span>{{ stop.commodity ? stop.commodity : '-' }}</div>
    </div>
    <div class="row">
      <div class="property-container"><span class="label">Pickup Description: </span>{{ stop.details ? stop.details : '-' }}</div>
    </div>
    <div class="row">
      <div class="property-container"><span class="label">Special Instructions: </span>{{ stop.special_instructions ? stop.special_instructions : '-' }}</div>
    </div>
  </div>
  <hr />
  <div class="row">
    <h2>Equipment</h2>
  </div>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
    <thead>
      <tr *ngIf="load?.equipments != null && load?.equipments.length > 0">
        <th></th>
        <th>Name</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let equipment of load?.equipments; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ equipment.name }}</td>
      </tr>
      <tr *ngIf="load != null && (load.equipments == null || load.equipments.length === 0)">
        <td class="center-text" colspan="2">No data found</td>
      </tr>
    </tbody>
  </table>
  <hr />
  <div class="row">
    <h2>Status History</h2>
  </div>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
    <thead>
      <tr *ngIf="load?.status_history_records != null && load?.status_history_records.length > 0">
        <th></th>
        <th>Timestamp</th>
        <th>Status</th>
        <th>By</th>
        <th>Notes</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let record of load?.status_history_records; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ formatDateTime(record.created_at) }}</td>
        <td>{{ loadStatusToLabelMapping[record.status] }}</td>
        <td>{{ record.user.user_info.first_name + ' ' + record.user.user_info.last_name }}</td>
        <td>{{ record.notes ? record.notes : '-' }}</td>
      </tr>
      <tr *ngIf="load != null && (load.status_history_records == null || load.status_history_records.length === 0)">
        <td class="center-text" colspan="5">No data found</td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="auth.isAdmin$ | async">
    <hr />
    <div class="row">
      <h2>Invoicing Status History</h2>
    </div>
    <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
      <thead>
        <tr *ngIf="load?.invoicing_status_history_records != null && load?.invoicing_status_history_records.length > 0">
          <th></th>
          <th>Timestamp</th>
          <th>Status</th>
          <th>By</th>
          <th>Notes</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let record of load?.invoicing_status_history_records; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ formatDateTime(record.created_at) }}</td>
          <td>{{ loadInvoicingStatusToLabelMapping[record.invoicing_status] }}</td>
          <td>{{ record.user.user_info.first_name + ' ' + record.user.user_info.last_name }}</td>
          <td>{{ record.notes ? record.notes : '-' }}</td>
        </tr>
        <tr *ngIf="load != null && (load.invoicing_status_history_records == null || load.invoicing_status_history_records.length === 0)">
          <td class="center-text" colspan="5">No data found</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
