import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-send-management-alert',
  templateUrl: './send-management-alert.component.html',
  styleUrls: ['./send-management-alert.component.scss']
})
export class SendManagementAlertComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<null>();

  id: number;
  name: string;
  notes: string;

  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<SendManagementAlertComponent>,
    private snackbar: MatSnackBar,
    private clientService: ClientService
  ) { }

  ngOnInit(): void {
    this.id = this.data.id;
    this.name = this.data.name;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  send() {
    this.isLoading = true;
    this.clientService.alertManagementAboutClient(this.id, this.notes).pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.destroy$)
    ).subscribe(success => {
      this.snackbar.open('Alert sent', null, {
        duration: 3000
      });
      this.dialogRef.close({ success: true });
    });
  }

}
