import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import * as moment from 'moment';
import { LoadStatus, LoadStatusToLabelMapping } from 'src/app/enums/load-status.enum';
import { ClientBusinessLocation } from 'src/app/models/client-business-location.model';
import { Load } from 'src/app/models/load.model';
import { LoadService } from 'src/app/services/load.service';
import { UiService } from 'src/app/services/ui.service';
import { DateTimePickerComponent } from '../date-time-picker/date-time-picker.component';
import { filter, finalize, switchMapTo, takeUntil, tap } from 'rxjs/operators';
import { PodUploadComponent } from '../pod-upload/pod-upload.component';
import { Moment } from 'moment';
import { FileSaverService } from 'ngx-filesaver';
import { Subject } from 'rxjs';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from 'src/app/models/confirm-dialog.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { LoadInvoicingStatus, LoadInvoicingStatusToLabelMapping } from 'src/app/enums/load-invoicing-status.enum';
import { RouteHistoryService } from 'src/app/services/route-history.service';
import { ChargeLineItemTypeToLabelMapping } from 'src/app/enums/charge-line-item-type.enum';
import { UpdateOwnerComponent } from '../update-owner/update-owner.component';
import { UpdateOwnerComponentType } from 'src/app/enums/update-owner-component.type.enum';
import { SendRateConEmailComponent } from '../send-rate-con-email/send-rate-con-email.component';
import { ActualFileObject } from 'filepond';

@Component({
  selector: 'app-load-details',
  templateUrl: './load-details.component.html',
  styleUrls: ['./load-details.component.scss']
})
export class LoadDetailsComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<null>();

  id: number;
  load: Load;

  loadStatuses = Object.values(LoadStatus).filter(v => typeof v === 'number');
  loadStatusToLabelMapping = LoadStatusToLabelMapping;
  isStatusSelectLoading = false;

  loadInvoicingStatuses = Object.values(LoadInvoicingStatus).filter(v => typeof v === 'number');
  loadInvoicingStatusToLabelMapping = LoadInvoicingStatusToLabelMapping;
  isInvoicingStatusSelectLoading = false;

  chargeLineItemTypeToLabelMapping = ChargeLineItemTypeToLabelMapping;

  dtOptions: DataTables.Settings = {};

  isLoading = true;

  cameFromAddLoad = false;

  constructor(
    private route: ActivatedRoute,
    private loadService: LoadService,
    private uiService: UiService,
    private router: Router,
    private dialog: MatDialog,
    private fileSaverService: FileSaverService,
    private snackbar: MatSnackBar,
    private location: Location,
    public auth: AuthService,
    private routeHistoryService: RouteHistoryService
  ) {
    this.uiService.setHeaderTitle('Load Details');
  }

  ngOnInit(): void {
    this.dtOptions = {
      serverSide: false,
      pagingType: 'simple',
      lengthChange: false,
      paging: false,
      info: false,
      searching: false,
      ordering: false
    };

    this.route.paramMap.pipe(
      takeUntil(this.destroy$)
    ).subscribe((params: ParamMap) => {
      this.id = parseInt(params.get('id'));
      this.fetchLoad();
    });

    this.cameFromAddLoad = this.routeHistoryService.previousUrl === '/add-load';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  goToEditLoad() {
    this.loadService.loadToEdit = this.load;
    this.router.navigate(['/add-load']);
  }

  checkboxOnChange(loadId: number, value: boolean) {
    this.loadService.updateRequiresAssistance(loadId, value).pipe(
      takeUntil(this.destroy$)
    ).subscribe();
  }

  getFullAddress(location: ClientBusinessLocation) {
    if (location == null) {
      return '-';
    }
    return (location.label ? location.label + ' - ' : '') + location.address + ', ' + location.city + ', ' + location.state + ' ' + location.zip;
  }

  hasPassedDeadline(deadline: string): boolean {
    const dateMoment = moment(deadline, 'YYYY-MM-DD hh:mm:ss');
    return dateMoment.isBefore(moment());
  }

  optionOnChange(id: number, newStatus: number | LoadStatus, selectElem: HTMLSelectElement) {
    newStatus = LoadStatus[LoadStatus[newStatus]];
    if (newStatus !== LoadStatus.PodDelivered) {
      const dialog = this.dialog.open(DateTimePickerComponent, {
        disableClose: true,
        data: {
          showDeadlinePicker: true,
          showNotesTextArea: true
        }
      });
      dialog.afterClosed().pipe(
        takeUntil(this.destroy$)
      ).subscribe(({ proceed, result, notes }: { proceed: boolean, result: Moment, notes?: string }) => {
        if (proceed) {
          const prevStatus = this.load.status;
          this.load.status = newStatus;
          this.isStatusSelectLoading = true;
          this.loadService.changeLoadStatus(id, newStatus, result, notes).pipe(
            finalize(() => this.isStatusSelectLoading = false),
            takeUntil(this.destroy$)
          ).subscribe(success => {
            if (!success) {
              selectElem.selectedIndex = prevStatus;
            } else {
              this.fetchLoad();
            }
          }, err => {
            selectElem.selectedIndex = prevStatus;
          });
        } else {
          this.revertLoadStatus(id, selectElem);
        }
      });
    } else {
      const dialog = this.dialog.open(PodUploadComponent, {
        disableClose: true,
        data: {
          showNotesTextArea: true
        }
      });
      dialog.afterClosed().pipe(
        takeUntil(this.destroy$)
      ).subscribe(({ proceed, result, notes }) => {
        if (proceed) {
          const prevStatus = this.load.status;
          this.load.status = newStatus;
          this.isStatusSelectLoading = true;
          this.loadService.markAsPodDelivered(id, result, notes).pipe(
            finalize(() => this.isStatusSelectLoading = false),
            takeUntil(this.destroy$)
          ).subscribe(success => {
            if (!success) {
              selectElem.selectedIndex = prevStatus;
            } else {
              this.fetchLoad();
            }
          }, err => {
            selectElem.selectedIndex = prevStatus;
          });
        } else {
          this.revertLoadStatus(id, selectElem);
        }
      });
    }
  }

  invoicingOptionOnChange(id: number, newStatus: number | LoadInvoicingStatus, selectElem: HTMLSelectElement) {
    newStatus = LoadInvoicingStatus[LoadInvoicingStatus[newStatus]];
    if (newStatus === LoadInvoicingStatus.InvoicedClient || newStatus === LoadInvoicingStatus.CarrierInvoiceReceived) {
      const dialog = this.dialog.open(DateTimePickerComponent, {
        disableClose: true,
        data: {
          showDeadlinePicker: true,
          showNotesTextArea: true,
          showFileUpload: true,
          showTime: false,
          titleLabel: 'Set due date for payment',
          subtitleLabel: newStatus === LoadInvoicingStatus.InvoicedClient ? `Client net terms: ${ this.load.client.net_terms ?? '-' } days` : '',
          defaultMoment: newStatus === LoadInvoicingStatus.InvoicedClient && this.load.client.net_terms != null ? moment().add(this.load.client.net_terms, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) : null
        }
      });
      dialog.afterClosed().pipe(
        takeUntil(this.destroy$)
      ).subscribe(({ proceed, result, notes, file }: { proceed: boolean, result: Moment, notes?: string, file?: File }) => {
        if (proceed) {
          const prevStatus = this.load.invoicing_status;
          this.load.invoicing_status = newStatus;
          this.isInvoicingStatusSelectLoading = true;
          this.loadService.changeLoadInvoicingStatus(id, newStatus, notes, result, file).pipe(
            finalize(() => this.isInvoicingStatusSelectLoading = false),
            takeUntil(this.destroy$)
          ).subscribe(success => {
            if (!success) {
              selectElem.selectedIndex = prevStatus;
              this.load.invoicing_status = prevStatus;
            } else {
              this.fetchLoad();
            }
          }, err => {
            selectElem.selectedIndex = prevStatus;
            this.load.invoicing_status = prevStatus;
          });
        } else {
          const prevStatus = this.load.invoicing_status;
          selectElem.selectedIndex = prevStatus;
        }
      });
    } else {
      const dialog = this.dialog.open(DateTimePickerComponent, {
        disableClose: true,
        data: {
          showDeadlinePicker: false,
          showNotesTextArea: true
        }
      });
      dialog.afterClosed().pipe(
        takeUntil(this.destroy$)
      ).subscribe(({ proceed, result, notes }: { proceed: boolean, result: Moment, notes?: string }) => {
        if (proceed) {
          const prevStatus = this.load.invoicing_status;
          this.load.invoicing_status = newStatus;
          this.isInvoicingStatusSelectLoading = true;
          this.loadService.changeLoadInvoicingStatus(id, newStatus, notes).pipe(
            finalize(() => this.isInvoicingStatusSelectLoading = false),
            takeUntil(this.destroy$)
          ).subscribe(success => {
            if (!success) {
              selectElem.selectedIndex = prevStatus;
              this.load.invoicing_status = prevStatus;
            }
          }, err => {
            selectElem.selectedIndex = prevStatus;
            this.load.invoicing_status = prevStatus;
          });
        } else {
          const prevStatus = this.load.invoicing_status;
          selectElem.selectedIndex = prevStatus;
        }
      });
    }
  }

  downloadRateCon() {
    this.isLoading = true;
    this.loadService.downloadRateCon(this.load.id).pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.destroy$)
    ).subscribe(res => {
      this.fileSaverService.save(res, `rate-con-${ this.load.visual_id }.pdf`);
    }, err => {
      let msg;
      if (err.error?.message) {
        msg = err.error.message;
      }
      this.snackbar.open(`Failed to download rate con. ${ msg }`, null, {
        duration: 5000
      });
    });
  }

  formatDate(date: Moment) {
    return moment.utc(date).format('YYYY-MM-DD');
  }

  formatDateTime(deadline: moment.Moment) {
    return moment(moment.utc(deadline)).local().format('YYYY-MM-DD HH:mm');
  }

  isPastDate(dateStr: string): boolean {
    return moment(dateStr).diff(moment()) < 0;
  }

  addNote() {
    const dialog = this.dialog.open(DateTimePickerComponent, {
      disableClose: true,
      data: {
        showDeadlinePicker: false,
        showNotesTextArea: true,
        submitButtonLabel: 'Add note'
      }
    });
    dialog.afterClosed().pipe(
      takeUntil(this.destroy$)
    ).subscribe(({ proceed, _, notes }: { proceed: boolean, _: Moment, notes?: string }) => {
      if (proceed) {
        this.loadService.changeLoadStatus(this.load.id, this.load.status, moment.utc(this.load.deadline), notes).pipe(
          takeUntil(this.destroy$)
        ).subscribe(success => {
          if (success) {
            this.fetchLoad();
          }
        });
      }
    });
  }

  deleteLoad() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: new ConfirmDialogModel('Delete Load', `Are you sure you want to delete load #${ this.load.visual_id }?`)
    });

    dialogRef.afterClosed().pipe(
      filter(res => res),
      tap(() => this.isLoading = true),
      switchMapTo(this.loadService.deleteLoad(this.load.id)),
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      if (res) {
        this.snackbar.open('Load deleted', null, {
          duration: 3000
        });
        this.location.back();
      } else {
        this.snackbar.open('Failed to delete load. Please try again', null, {
          duration: 5000
        });
      }
    }, err => {
      let msg;
      if (err.error?.message) {
        msg = err.error.message;
      }
      this.snackbar.open(`Failed to delete load. ${ msg }`, null, {
        duration: 5000
      });
    });
  }

  formatNumber(n: number): string {
    return n ? n.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    }) : '0';
  }

  goBack() {
    if (!this.cameFromAddLoad) {
      this.location.back();
    } else {
      this.router.navigate(['my-loads']);
    }
  }

  changeOwner() {
    const dialog = this.dialog.open(UpdateOwnerComponent, {
      data: {
        id: this.id,
        type: UpdateOwnerComponentType.Load
      },
      width: '300px'
    });
    dialog.afterClosed().pipe(
      takeUntil(this.destroy$)
    ).subscribe(res => {
      if (res.success) {
        this.load = null;
        this.isLoading = true;
        this.loadService.getLoadDetails(this.id).pipe(
          takeUntil(this.destroy$)
        ).subscribe(load => {
          this.load = load;
          this.isLoading = false;
        });
      }
    });
  }

  sendRateConEmail() {
    this.auth.userEmail$.subscribe(email => {
      this.dialog.open(SendRateConEmailComponent, {
        width: '50%',
        maxHeight: '90vh',
        data: {
          loadId: this.load.id,
          emailsTo: this.load.carrier_booking_agent ? [ this.load.carrier_booking_agent.email ] : [],
          emailsCc: [ email ],
          displaySkip: false
        }
      });
    })
  }

  private revertLoadStatus(id: number, selectElem: HTMLSelectElement) {
    const prevStatus = this.load.status;
    selectElem.selectedIndex = prevStatus;
  }

  private fetchLoad() {
    this.isLoading = true;
    this.loadService.getLoadDetails(this.id).pipe(
      takeUntil(this.destroy$)
    ).subscribe(load => {
      this.load = load;
      this.isLoading = false;
    });
  }

}
