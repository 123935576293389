import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Token } from '../models/token.model';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';
import { TokenService } from './token.service';
import { map, tap } from 'rxjs/operators';
import { Role } from '../enums/role.enum';

class LoginResponse {
  id: number;
  name: string;
  role: Role;
  token: Token;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private idKey = 'user_id';
  private fullNameKey = 'user_full_name';
  private emailKey = 'user_email';
  private roleKey = 'user_role';

  userAuthStateSubject = new BehaviorSubject<boolean>(this.tokenService.isLoggedIn());
  userAuthState$ = this.userAuthStateSubject.asObservable();

  private userIdSubject = new BehaviorSubject<number>(this.getUserId());
  userId$ = this.userIdSubject.asObservable();

  private userFullNameSubject = new BehaviorSubject<string>(this.getUserFullName());
  userFullName$ = this.userFullNameSubject.asObservable();

  private userEmailSubject = new BehaviorSubject<string>(this.getUserEmail());
  userEmail$ = this.userEmailSubject.asObservable();

  private userRoleSubject = new BehaviorSubject<Role>(this.getUserRole());
  userRole$ = this.userRoleSubject.asObservable();

  isOps$ = this.userRole$.pipe(map(role => role === Role.Ops || role === Role.Admin));
  isAdmin$ = this.userRole$.pipe(map(role => role === Role.Admin));

  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) { }

  login(email: string, password: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${ environment.apiUrl }/auth/login`, {
      email,
      password
    }).pipe(
      tap(res => {
        this.tokenService.setToken(res.token.access_token);
        this.userAuthStateSubject.next(true);
        this.setUserFullName(res.name);
        this.setUserEmail(email);
        this.setUserRole(res.role);
        this.setUserId(res.id);
      }, _ => {
        this.tokenService.removeToken();
        this.userAuthStateSubject.next(false);
        this.setUserFullName(null);
        this.setUserEmail(null);
        this.setUserRole(null);
        this.setUserId(null);
      })
    );
  }

  register(user: User): Observable<number> {
    return this.http.post<number>(`${ environment.apiUrl }/user/create`, user);
  }

  refreshToken(): Observable<Token> {
    return this.http.post<Token>(`${ environment.apiUrl }/auth/refresh`, {}).pipe(
      tap(token => {
        this.tokenService.setToken(token.access_token);
        this.userAuthStateSubject.next(true);
      }, _ => {
        this.tokenService.removeToken();
        this.userAuthStateSubject.next(false);
      })
    );
  }

  logout(): Observable<any> {
    return this.http.post(`${ environment.apiUrl }/auth/logout`, {}).pipe(
      tap(_ => {
        this.tokenService.removeToken();
        this.userAuthStateSubject.next(false);
      })
    );
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(`${ environment.apiUrl }/auth/forgot-password`, { email });
  }

  resetPassword(email: string, password: string, token: string): Observable<any> {
    return this.http.post(`${ environment.apiUrl }/auth/reset-password`, {
      email,
      password,
      password_confirmation: password,
      token
    });
  }

  updateUserInfo(user: User) {
    this.setUserFullName(`${ user.user_info.first_name } ${ user.user_info.last_name }`.trim());
    this.setUserEmail(user.email);
    this.setUserRole(user.role);
    this.setUserId(user.id);
  }

  private setUserFullName(name?: string) {
    if (this.getUserFullName() === name) {
      return;
    }
    if (name != null) {
      this.userFullNameSubject.next(name);
      localStorage.setItem(this.fullNameKey, name);
    } else {
      this.userFullNameSubject.next('');
      localStorage.removeItem(this.fullNameKey);
    }
  }

  private getUserFullName(): string {
    return localStorage.getItem(this.fullNameKey);
  }

  private setUserEmail(email?: string) {
    if (this.getUserEmail() === email) {
      return;
    }
    if (email != null) {
      this.userEmailSubject.next(email);
      localStorage.setItem(this.emailKey, email);
    } else {
      this.userEmailSubject.next('');
      localStorage.removeItem(this.emailKey);
    }
  }

  private getUserEmail(): string {
    return localStorage.getItem(this.emailKey);
  }

  private setUserRole(role?: Role) {
    if (this.getUserRole() === role) {
      return;
    }
    if (role != null) {
      this.userRoleSubject.next(role);
      localStorage.setItem(this.roleKey, role.toString());
    } else {
      this.userRoleSubject.next(Role.Employee);
      localStorage.removeItem(this.roleKey);
    }
  }

  private getUserRole(): Role {
    return Role[Role[parseInt(localStorage.getItem(this.roleKey))]];
  }

  private setUserId(id?: number) {
    if (this.getUserId() === id) {
      return;
    }
    if (id != null) {
      this.userIdSubject.next(id);
      localStorage.setItem(this.idKey, id.toString());
    } else {
      this.userIdSubject.next(null);
      localStorage.removeItem(this.idKey);
    }
  }

  private getUserId(): number {
    return parseInt(localStorage.getItem(this.idKey));
  }

}
