export enum Role {
  Admin = 0,
  Ops = 1,
  Employee = 2
}

export const RoleToLabelMapping: Record<Role, string> = {
  [Role.Admin]: 'Admin',
  [Role.Ops]: 'Ops',
  [Role.Employee]: 'Employee'
}
