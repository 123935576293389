import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { BehaviorSubject, concat, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, finalize, map } from 'rxjs/operators';
import { CarrierEquipment } from 'src/app/models/carrier-equipment.model';
import { ReportDateRanges } from 'src/app/models/report-date-ranges';
import { ReportLoad } from 'src/app/models/report-load.model';
import { CarrierService } from 'src/app/services/carrier.service';
import { ReportService } from 'src/app/services/report.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-report-load',
  templateUrl: './report-load.component.html',
  styleUrls: ['./report-load.component.scss']
})
export class ReportLoadComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<null>();

  report: ReportLoad;

  selectedDates: { startDate: dayjs.Dayjs, endDate: dayjs.Dayjs } = {
    startDate: dayjs().subtract(29, 'days'),
    endDate: dayjs().add(1, 'days')
  };
  ranges = ReportDateRanges;

  equipmentLoading = false;
  fullEquipmentList$: BehaviorSubject<CarrierEquipment[]> = new BehaviorSubject([]);
  equipment$: Observable<CarrierEquipment[]>;
  equipmentInput$ = new BehaviorSubject('');
  selectedEquipment: CarrierEquipment;

  dtOptions: DataTables.Settings = {};

  constructor(
    private reportService: ReportService,
    private uiService: UiService,
    private carrierService: CarrierService,
    private location: Location
  ) {
    this.uiService.setHeaderTitle('Load Report');
  }

  ngOnInit(): void {
    this.dtOptions = {
      serverSide: false,
      pagingType: 'simple',
      lengthChange: false,
      paging: false,
      info: false,
      searching: false,
      ordering: false
    };

    // this.equipmentInput$.pipe(
    //   debounceTime(1000),
    //   distinctUntilChanged(),
    //   tap(() => this.report = null),
    //   switchMap(term => this.reportService.getLoadReport(this.selectedDates.startDate, this.selectedDates.endDate, term)),
    //   takeUntil(this.destroy$)
    // ).subscribe(res => this.report = res);

    this.loadEquipment();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  datesUpdated() {
    this.report = null;
    this.reportService.getLoadReport(this.selectedDates.startDate, this.selectedDates.endDate, this.selectedEquipment?.id).pipe(
      takeUntil(this.destroy$)
    ).subscribe(res => this.report = res);
  }

  // equipmentInput(input: string) {
  //   this.equipmentInput$.next(input);
  // }

  trackEquipmentByFn(item: CarrierEquipment) {
    return item.id;
  }

  equipmentSelected(equipment: CarrierEquipment) {
    this.report = null;
    this.reportService.getLoadReport(this.selectedDates.startDate, this.selectedDates.endDate, equipment.id).pipe(
      takeUntil(this.destroy$)
    ).subscribe(res => this.report = res);
  }

  equipmentCleared() {
    this.report = null;
    this.reportService.getLoadReport(this.selectedDates.startDate, this.selectedDates.endDate).pipe(
      takeUntil(this.destroy$)
    ).subscribe(res => this.report = res);
  }

  goBack() {
    this.location.back();
  }

  private loadEquipment() {
    this.equipmentLoading = true;
    this.carrierService.getAllEquipment().pipe(
      finalize(() => this.equipmentLoading = false),
      takeUntil(this.destroy$)
    ).subscribe(res => this.fullEquipmentList$.next(res));

    this.equipment$ = concat(this.fullEquipmentList$, this.equipmentInput$.pipe(
      distinctUntilChanged(),
      map(term => this.fullEquipmentList$.getValue().filter(e => e.name.toLowerCase().includes(term.toLowerCase())))
    ));
  }

}
