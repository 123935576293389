import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { LoadService } from 'src/app/services/load.service';
import { QuoteService } from 'src/app/services/quote.service';
import { RouteHistoryService } from 'src/app/services/route-history.service';
import { UiService } from 'src/app/services/ui.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<null>();

  userFullName = this.auth.userFullName$;
  headerTitle = this.uiService.headerTitle;
  hasGrayBg = this.uiService.hasGrayBg;

  isLoggingOut = false;

  constructor(
    private uiService: UiService,
    public auth: AuthService,
    private router: Router,
    private quoteService: QuoteService,
    private loadService: LoadService,
    private routeHistoryService: RouteHistoryService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.auth.userId$.pipe(
      takeUntil(this.destroy$),
      switchMap(id => this.userService.getUserDetails(id))
    ).subscribe(user => {
      this.auth.updateUserInfo(user);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  logout() {
    this.isLoggingOut = true;
    this.auth.logout().pipe(
      finalize(() => {
        this.isLoggingOut = false;
        this.router.navigate(['/login']);
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  clearData() {
    this.quoteService.quoteToEdit = null;
    this.loadService.loadToEdit = null;
  }

}
