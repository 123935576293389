<div class="filters-container">
  <span>Show:</span>
  <button class="btn-link" [class.selected]="(selectedFilter$ | async) === LoadFilter.Active" (click)="selectedFilter$.next(LoadFilter.Active)">{{ (auth.isOps$ | async) ? 'Invoicing' : 'Active' }}</button>
  <button *ngIf="auth.isOps$ | async" class="btn-link" [class.selected]="(selectedFilter$ | async) === LoadFilter.AccountsReceivable" (click)="selectedFilter$.next(LoadFilter.AccountsReceivable)">Accounts Receivable</button>
  <button *ngIf="auth.isOps$ | async" class="btn-link" [class.selected]="(selectedFilter$ | async) === LoadFilter.AccountsPayable" (click)="selectedFilter$.next(LoadFilter.AccountsPayable)">Accounts Payable</button>
  <button class="btn-link" [class.selected]="(selectedFilter$ | async) === LoadFilter.Completed" (click)="selectedFilter$.next(LoadFilter.Completed)">Completed</button>
  <button *ngIf="auth.isOps$ | async" class="btn-link" [class.selected]="(selectedFilter$ | async) === LoadFilter.RequiresAssistance" (click)="selectedFilter$.next(LoadFilter.RequiresAssistance)">Requires Assistance</button>
  <button class="btn-link" [class.selected]="(selectedFilter$ | async) == null" (click)="selectedFilter$.next(null)">All</button>
</div>
<div class="buttons-container" *ngIf="carrierId == null && clientId == null && employeeId == null">
  <input type="text" placeholder="Type to filter results" (input)="filterQueryChanged($event.target.value)" />
  <a class="btn-primary" [routerLink]="['/add-load']">+ New load</a>
</div>
<div class="table-container">
  <div [class.show]="(selectedFilter$ | async) !== LoadFilter.AccountsReceivable && (selectedFilter$ | async) !== LoadFilter.AccountsPayable && !((auth.isOps$ | async) && (selectedFilter$ | async) === LoadFilter.Active)">
    <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover display nowrap">
      <thead>
        <tr>
          <th>Load #</th>
          <th>Pickup Date</th>
          <th>Dropoff Date</th>
          <th>Status</th>
          <th *ngIf="auth.isOps$ | async">Invoicing Status</th>
          <th>Client</th>
          <th>Carrier</th>
          <th>Pickup Location</th>
          <th>Dropoff Location</th>
          <th>Revenue</th>
          <th>Client Accessorial</th>
          <th>Carrier Accessorial</th>
          <th>Line Haul</th>
          <th>Margin</th>
          <th>Deadline</th>
          <th>Requires Assistance</th>
        </tr>
      </thead>
      <tbody *ngIf="loads?.length !== 0">
        <tr class="clickable" [class.red]="hasPassedDeadline(load.deadline)" *ngFor="let load of loads; let i = index" (click)="goToLoad(load.id)">
          <td>{{ load.visual_id }}</td>
          <td>{{ formatDate(firstPickupStop(load)?.date) + ' ' + firstPickupStop(load)?.time }}</td>
          <td>{{ formatDate(firstDropoffStop(load)?.date) + ' ' + firstDropoffStop(load)?.time }}</td>
          <td>
            <select [class.yellow]="load.status === 0" [class.red]="hasPassedDeadline(load.deadline)" [class.green]="load.status === 7" [disabled]="statusSelectLoading[i]" #statusSelect (change)="optionOnChange(load.id, $event.target.value, statusSelect)" (click)="$event.stopPropagation()">
              <option *ngFor="let status of loadStatuses" [value]="status" [selected]="load.status === status">{{ loadStatusToLabelMapping[status] }}</option>
            </select>
          </td>
          <td *ngIf="auth.isOps$ | async">
            <select *ngIf="load.status === 7" [disabled]="invoicingStatusSelectLoading[i]" #invoicingStatusSelect (change)="invoicingOptionOnChange(load.id, $event.target.value, invoicingStatusSelect)" (click)="$event.stopPropagation()">
              <option *ngFor="let status of loadInvoicingStatuses" [value]="status" [selected]="load.invoicing_status === status">{{ loadInvoicingStatusToLabelMapping[status] }}</option>
            </select>
            <div *ngIf="load.status !== 7">-</div>
          </td>
          <td>{{ load.client.name }}</td>
          <td>{{ load.carrier?.business_name ? load.carrier.business_name : '-' }}</td>
          <td>{{ firstPickupStop(load).location != null ? firstPickupStop(load).location.address + ', ' + firstPickupStop(load).location.city + ', ' + firstPickupStop(load).location.state + ' ' + firstPickupStop(load).location.zip : '-' }}</td>
          <td>{{ firstDropoffStop(load).location != null ? firstDropoffStop(load).location.address + ', ' + firstDropoffStop(load).location.city + ', ' + firstDropoffStop(load).location.state + ' ' + firstDropoffStop(load).location.zip : '-' }}</td>
          <td>${{ formatNumber(load.charge) }}</td>
          <td>${{ formatNumber(load.client_accessorial) }}</td>
          <td>${{ formatNumber(load.carrier_accessorial) }}</td>
          <td>${{ formatNumber(load.compensation) }}</td>
          <td>${{ formatNumber(load.margin) }}</td>
          <td>{{ load.deadline ? formatDateTime(load.deadline) : '-' }}</td>
          <td><input type="checkbox" [checked]="load.requires_assistance" (change)="checkboxOnChange(load.id, $event.target.checked)" (click)="$event.stopPropagation()" /></td>
        </tr>
      </tbody>
      <tbody *ngIf="loads?.length === 0">
        <tr>
          <td colspan="16">No data found</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div [class.show]="(auth.isOps$ | async) && (selectedFilter$ | async) === LoadFilter.Active">
    <table id="table-invoicing" datatable [dtOptions]="dtInvoicingOptions" class="row-border cell-border hover display nowrap">
      <thead>
        <tr>
          <th>Load #</th>
          <th>Invoicing Status</th>
          <th>Client</th>
          <th>Carrier</th>
          <th>Revenue</th>
          <th>Client Accessorial</th>
          <th>Carrier Accessorial</th>
          <th>Line Haul</th>
          <th>Margin</th>
        </tr>
      </thead>
      <tbody *ngIf="loads?.length !== 0">
        <tr class="clickable" *ngFor="let load of loads; let i = index" (click)="goToLoad(load.id)">
          <td>{{ load.visual_id }}</td>
          <td>
            <select [disabled]="invoicingStatusSelectLoading[i]" #invoicingStatusSelect (change)="invoicingOptionOnChange(load.id, $event.target.value, invoicingStatusSelect)" (click)="$event.stopPropagation()">
              <option *ngFor="let status of loadInvoicingStatuses" [value]="status" [selected]="load.invoicing_status === status">{{ loadInvoicingStatusToLabelMapping[status] }}</option>
            </select>
          </td>
          <td>{{ load.client.name }}</td>
          <td>{{ load.carrier?.business_name ? load.carrier.business_name : '-' }}</td>
          <td>${{ formatNumber(load.charge) }}</td>
          <td>${{ formatNumber(load.client_accessorial) }}</td>
          <td>${{ formatNumber(load.carrier_accessorial) }}</td>
          <td>${{ formatNumber(load.compensation) }}</td>
          <td>${{ formatNumber(load.margin) }}</td>
        </tr>
      </tbody>
      <tbody *ngIf="loads?.length === 0">
        <tr>
          <td colspan="9">No data found</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div [class.show]="(selectedFilter$ | async) === LoadFilter.AccountsReceivable">
    <table id="table-receivable" datatable [dtOptions]="dtReceivableOptions" class="row-border cell-border hover display nowrap">
      <thead>
        <tr>
          <th>Load #</th>
          <th>Due Date</th>
          <th>Invoicing Status</th>
          <th>Client</th>
          <th>Client Accessorial</th>
          <th>Line Haul</th>
        </tr>
      </thead>
      <tbody *ngIf="loads?.length !== 0">
        <tr class="clickable" *ngFor="let load of loads; let i = index" (click)="goToLoad(load.id)">
          <td>{{ load.visual_id }}</td>
          <td>{{ formatDate(load.client_payment_due_date) }}</td>
          <td>
            <select [disabled]="invoicingStatusSelectLoading[i]" #invoicingStatusSelect (change)="invoicingOptionOnChange(load.id, $event.target.value, invoicingStatusSelect)" (click)="$event.stopPropagation()">
              <option *ngFor="let status of loadInvoicingStatuses" [value]="status" [selected]="load.invoicing_status === status">{{ loadInvoicingStatusToLabelMapping[status] }}</option>
            </select>
          </td>
          <td>{{ load.client.name }}</td>
          <td>${{ formatNumber(load.client_accessorial) }}</td>
          <td>${{ formatNumber(load.compensation) }}</td>
        </tr>
      </tbody>
      <tbody *ngIf="loads?.length === 0">
        <tr>
          <td colspan="6">No data found</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div [class.show]="(selectedFilter$ | async) === LoadFilter.AccountsPayable">
    <table id="table-payable" datatable [dtOptions]="dtPayableOptions" class="row-border cell-border hover display nowrap">
      <thead>
        <tr>
          <th>Load #</th>
          <th>Due Date</th>
          <th>Invoicing Status</th>
          <th>Carrier</th>
          <th>Carrier Accessorial</th>
          <th>Revenue</th>
        </tr>
      </thead>
      <tbody *ngIf="loads?.length !== 0">
        <tr class="clickable" *ngFor="let load of loads; let i = index" (click)="goToLoad(load.id)">
          <td>{{ load.visual_id }}</td>
          <td>{{ formatDate(load.carrier_payment_due_date) }}</td>
          <td>
            <select [disabled]="invoicingStatusSelectLoading[i]" #invoicingStatusSelect (change)="invoicingOptionOnChange(load.id, $event.target.value, invoicingStatusSelect)" (click)="$event.stopPropagation()">
              <option *ngFor="let status of loadInvoicingStatuses" [value]="status" [selected]="load.invoicing_status === status">{{ loadInvoicingStatusToLabelMapping[status] }}</option>
            </select>
          </td>
          <td>{{ load.carrier?.business_name ? load.carrier.business_name : '-' }}</td>
          <td>${{ formatNumber(load.carrier_accessorial) }}</td>
          <td>${{ formatNumber(load.charge) }}</td>
        </tr>
      </tbody>
      <tbody *ngIf="loads?.length === 0">
        <tr>
          <td colspan="6">No data found</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
