import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { EmployeeIssue } from 'src/app/models/employee-issue.model';
import { Load } from 'src/app/models/load.model';
import { LoadService } from 'src/app/services/load.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-log-employee-issue',
  templateUrl: './log-employee-issue.component.html',
  styleUrls: ['./log-employee-issue.component.scss']
})
export class LogEmployeeIssueComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<null>();

  isLoading = true;

  loads: Load[];

  employeeIssue: EmployeeIssue = new EmployeeIssue();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<LogEmployeeIssueComponent>,
    private loadService: LoadService,
    private userService: UserService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.employeeIssue.employee_id = this.data.id;
    this.loadService.getLoadsForEmployee(this.employeeIssue.employee_id, {
      start: 0,
      length: -1
    }).pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.destroy$)
    ).subscribe(res => {
      this.loads = res.data;
      this.loads = this.loads.sort((a, b) => b.id - a.id);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  logIssue() {
    this.isLoading = true;
    this.userService.logEmployeeIssue(this.employeeIssue).pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.destroy$)
    ).subscribe(issueId => {
      this.snackbar.open('Issue logged', null, {
        duration: 3000
      });
      this.employeeIssue.id = issueId;
      this.dialogRef.close({ success: true, item: this.employeeIssue });
    });
  }

}
