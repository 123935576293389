import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ReportCarrier } from '../models/report-carrier.model';
import { ReportClient } from '../models/report-client.model';
import { ReportEmployee, ReportEmployeeRow } from '../models/report-employee.model';
import { ReportLeaderboard } from '../models/report-leaderboard.model';
import { ReportLoad } from '../models/report-load.model';
import { ReportRevenue } from '../models/report-revenue.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

  getRevenueReport(startDate?: Dayjs, endDate?: Dayjs): Observable<ReportRevenue> {
    const params: any = {};
    if (startDate) {
      params.from_date = startDate.format('YYYY-MM-DD');
    }
    if (endDate) {
      params.to_date = endDate.format('YYYY-MM-DD');
    }
    return this.http.get<ReportRevenue>(`${ environment.apiUrl }/report/revenue`, {
      params
    });
  }

  getClientReport(startDate?: Dayjs, endDate?: Dayjs): Observable<ReportClient> {
    const params: any = {};
    if (startDate) {
      params.from_date = startDate.format('YYYY-MM-DD');
    }
    if (endDate) {
      params.to_date = endDate.format('YYYY-MM-DD');
    }
    return this.http.get<ReportClient>(`${ environment.apiUrl }/report/client`, {
      params
    });
  }

  getLoadReport(startDate?: Dayjs, endDate?: Dayjs, equipmentId?: number): Observable<ReportLoad> {
    const params: any = {};
    if (startDate) {
      params.from_date = startDate.format('YYYY-MM-DD');
    }
    if (endDate) {
      params.to_date = endDate.format('YYYY-MM-DD');
    }
    if (equipmentId) {
      params.equipment_id = equipmentId;
    }
    return this.http.get<ReportLoad>(`${ environment.apiUrl }/report/load`, {
      params
    });
  }

  getEmployeeReport(startDate?: Dayjs, endDate?: Dayjs): Observable<ReportEmployee> {
    const params: any = {};
    if (startDate) {
      params.from_date = startDate.format('YYYY-MM-DD');
    }
    if (endDate) {
      params.to_date = endDate.format('YYYY-MM-DD');
    }
    return this.http.get<ReportEmployee>(`${ environment.apiUrl }/report/employee`, {
      params
    });
  }

  getCarrierReport(startDate?: Dayjs, endDate?: Dayjs): Observable<ReportCarrier> {
    const params: any = {};
    if (startDate) {
      params.from_date = startDate.format('YYYY-MM-DD');
    }
    if (endDate) {
      params.to_date = endDate.format('YYYY-MM-DD');
    }
    return this.http.get<ReportCarrier>(`${ environment.apiUrl }/report/carrier`, {
      params
    });
  }

  getEmployeeSelfReport(startDate?: Dayjs, endDate?: Dayjs): Observable<ReportEmployeeRow> {
    return this.auth.userId$.pipe(
      switchMap(id => {
        const params: any = {};
        if (startDate) {
          params.from_date = startDate.format('YYYY-MM-DD');
        }
        if (endDate) {
          params.to_date = endDate.format('YYYY-MM-DD');
        }
        params.id = id;
        return this.http.get<ReportEmployeeRow>(`${ environment.apiUrl }/report/employee-self`, {
          params
        });
      })
    );
  }

  getLeaderboardReport(startDate?: Dayjs, endDate?: Dayjs): Observable<ReportLeaderboard> {
    const params: any = {};
    if (startDate) {
      params.from_date = startDate.format('YYYY-MM-DD');
    }
    if (endDate) {
      params.to_date = endDate.format('YYYY-MM-DD');
    }
    return this.http.get<ReportLeaderboard>(`${ environment.apiUrl }/report/leaderboard`, {
      params
    });
  }

}
