import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, switchMap, tap, takeUntil, filter, switchMapTo, first } from 'rxjs/operators';
import { CarrierNote } from 'src/app/models/carrier-note.model';
import { Carrier } from 'src/app/models/carrier.model';
import { ConfirmDialogModel } from 'src/app/models/confirm-dialog.model';
import { FmcsaCarrier } from 'src/app/models/fmcsa-carrier.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { CarrierService } from 'src/app/services/carrier.service';
import { FmcsaService } from 'src/app/services/fmcsa.service';
import { UiService } from 'src/app/services/ui.service';
import { AddCarrierNoteComponent } from '../add-carrier-note/add-carrier-note.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-carrier-details',
  templateUrl: './carrier-details.component.html',
  styleUrls: ['./carrier-details.component.scss']
})
export class CarrierDetailsComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<null>();

  id: number;
  carrier: Carrier;
  fmcsaCarrier: FmcsaCarrier;

  dtOptions: DataTables.Settings = {};

  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    public carrierService: CarrierService,
    private uiService: UiService,
    private router: Router,
    public auth: AuthService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private fmcsaService: FmcsaService,
    private location: Location
  ) {
    this.uiService.setHeaderTitle('Carrier Details');
  }

  ngOnInit(): void {
    this.dtOptions = {
      serverSide: false,
      pagingType: 'simple',
      lengthChange: false,
      paging: false,
      info: false,
      searching: false,
      ordering: false
    };

    this.route.paramMap.pipe(
      takeUntil(this.destroy$)
    ).subscribe((params: ParamMap) => {
      this.id = parseInt(params.get('id'));

      this.carrierService.getCarrierDetails(this.id).pipe(
        tap(carrier => this.carrier = carrier),
        switchMap(carrier => {
          if (carrier.mc_num != null) {
            return this.fmcsaService.getCarriersByMcNum(carrier.mc_num);
          } else if (carrier.dot_num != null) {
            return this.fmcsaService.getCarriersByDotNum(carrier.dot_num);
          } else {
            return new BehaviorSubject(null).pipe(first());
          }
        }),
        finalize(() => this.isLoading = false),
        takeUntil(this.destroy$)
      ).subscribe(res => {
        if (res != null) {
          if (Array.isArray(res.content) && res.content.length > 0) {
            this.fmcsaCarrier = res.content[0].carrier;
          } else if (!Array.isArray(res.content)) {
            this.fmcsaCarrier = res.content.carrier;
          }
        }
      });
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  goToEditCarrier() {
    this.carrierService.carrierToEdit = this.carrier;
    this.router.navigate(['/add-carrier']);
  }

  addNote() {
    const dialog = this.dialog.open(AddCarrierNoteComponent, {
      data: {
        carrier_id: this.carrier.id
      },
      width: '600px'
    });

    dialog.afterClosed().pipe(
      takeUntil(this.destroy$)
    ).subscribe(({ success, note }) => {
      if (success) {
        this.snackbar.open('Note added', null, {
          duration: 3000
        });
        this.carrier.notes.unshift(note);
      }
    });
  }

  editNote(currentNote: CarrierNote) {
    const dialog = this.dialog.open(AddCarrierNoteComponent, {
      data: {
        carrier_id: this.carrier.id,
        id: currentNote.id,
        content: currentNote.content
      },
      width: '600px'
    });

    dialog.afterClosed().pipe(
      takeUntil(this.destroy$)
    ).subscribe(({ success, note }) => {
      if (success) {
        this.snackbar.open('Note updated', null, {
          duration: 3000
        });
        const i = this.carrier.notes.findIndex(n => n.id === note.id);
        this.carrier.notes[i].content = note.content;
      }
    });
  }

  formatAuthor(author: User) {
    return (author.user_info.first_name + ' ' + author.user_info.last_name).trim();
  }

  formatDate(date: Moment, displayTime = false, utc = false) {
    return (utc ? moment.utc(date) : moment(date)).format('YYYY-MM-DD' + (displayTime ? ' HH:mm' : ''));
  }

  isPastDate(dateStr: string): boolean {
    return moment(dateStr).diff(moment()) < 0;
  }

  deleteCarrier() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: new ConfirmDialogModel('Delete Carrier', `Are you sure you want to delete carrier #${ this.carrier.id }?`)
    });

    dialogRef.afterClosed().pipe(
      filter(res => res),
      tap(() => this.isLoading = true),
      switchMapTo(this.carrierService.deleteCarrier(this.carrier.id)),
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      if (res) {
        this.snackbar.open('Carrier deleted', null, {
          duration: 3000
        });
        this.location.back();
      } else {
        this.snackbar.open('Failed to delete carrier. Please try again', null, {
          duration: 5000
        });
      }
    }, err => {
      let msg;
      if (err.error?.message) {
        msg = err.error.message;
      }
      this.snackbar.open(`Failed to delete carrier. ${ msg }`, null, {
        duration: 5000
      });
    });
  }

  blacklist(shouldBlacklist: boolean) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: new ConfirmDialogModel(shouldBlacklist ? 'Blacklist Carrier' : 'Remove From Blacklist', `Are you sure you want to ${ shouldBlacklist ? 'add' : 'remove' } carrier #${ this.carrier.id } ${ shouldBlacklist ? 'to' : 'from' } the blacklist?`)
    });

    dialogRef.afterClosed().pipe(
      filter(res => res),
      tap(() => this.isLoading = true),
      switchMapTo(this.carrierService.blacklistCarrier(this.id, shouldBlacklist)),
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      this.carrier.is_blacklisted = shouldBlacklist;
      this.snackbar.open(`Carrier ${ shouldBlacklist ? 'added to' : 'removed from' } blacklist`, null, {
        duration: 3000
      });
    }, err => {
      let msg;
      if (err.error?.message) {
        msg = err.error.message;
      }
      this.snackbar.open(`Failed to delete carrier. ${ msg }`, null, {
        duration: 5000
      });
    });
  }

  approveChange(id: number) {
    this.isLoading = true;
    this.carrierService.approveChange(id).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      let idx = this.carrier.changes.findIndex(c => c.id === id);
      this.carrier.changes.splice(idx, 1);
    });
  }

}
