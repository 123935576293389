import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { RoleToLabelMapping } from 'src/app/enums/role.enum';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { UiService } from 'src/app/services/ui.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-employees-table',
  templateUrl: './employees-table.component.html',
  styleUrls: ['./employees-table.component.scss']
})
export class EmployeesTableComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(DataTableDirective, { static: false }) private dtElem: DataTableDirective;

  roleToLabelMapping = RoleToLabelMapping;

  currentQuery = '';
  filterQuery$ = new Subject<string>();
  filterQueryDebounced$ = new Observable<string>();

  destroy$ = new Subject<null>();

  employees: User[];
  dtOptions: DataTables.Settings = {};

  constructor(
    private uiService: UiService,
    private userService: UserService,
    private router: Router,
    public auth: AuthService
  ) {
    this.uiService.setHeaderTitle('Employee List');
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      lengthChange: false,
      searching : false,
      columns: [
        { name: 'id', orderable: true },
        { name: 'name', orderable: false },
        { name: 'email', orderable: false },
        { name: 'role', orderable: true }
      ],
      order: [[3, 'asc']],
      ajax: (dtParams, callback) => {
        this.userService.getAllUsers(dtParams, this.currentQuery).pipe(
          takeUntil(this.destroy$),
          takeUntil(this.filterQueryDebounced$)
        ).subscribe(res => {
          this.employees = res.data;
          callback({
            recordsTotal: res.recordsTotal,
            recordsFiltered: res.recordsFiltered,
            data: []
          });
        });
      }
    };

    this.filterQueryDebounced$ = this.filterQuery$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(query => this.currentQuery = query),
      takeUntil(this.destroy$)
    );
  }

  ngAfterViewInit(): void {
    this.filterQueryDebounced$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(_ => {
      this.dtElem.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload();
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  goToEmployee(id: number) {
    this.router.navigate(['/employee', id]);
  }

  filterQueryChanged(query: string) {
    this.filterQuery$.next(query);
  }

}
