<div class="buttons-container">
  <a class="btn-secondary" [routerLink]="['/carriers']">&lt; Back</a>
  <div class="right-buttons-container">
    <button class="btn-danger-secondary" *ngIf="(auth.isAdmin$ | async) && carrier?.is_blacklisted === false" (click)="blacklist(true)" [disabled]="isLoading">Add To Blacklist</button>
    <button class="btn-secondary" *ngIf="(auth.isAdmin$ | async) && carrier?.is_blacklisted" (click)="blacklist(false)" [disabled]="isLoading">Remove From Blacklist</button>
    <button class="btn-primary" (click)="goToEditCarrier()" [disabled]="isLoading">Edit Carrier</button>
    <button class="btn-round btn-delete" (click)="deleteCarrier()" [disabled]="isLoading" *ngIf="auth.isAdmin$ | async"><mat-icon>delete_outlined</mat-icon></button>
  </div>
</div>
<div *ngIf="carrier == null" class="loading-text">Loading...</div>
<div class="content-container" *ngIf="carrier != null">
  <div class="changes-container" *ngIf="auth.isAdmin$ | async">
    <div class="change-row" *ngFor="let change of carrier?.changes">
      <div *ngIf="change.approved_at == null">
        PENDING APPROVAL - {{ change.user.user_info.first_name }} {{ change.user.user_info.last_name }} made the change: {{ change.description }} on {{ formatDate(change.created_at, false) }}
        <button class="btn-secondary" (click)="approveChange(change.id)" [disabled]="isLoading">
          <mat-icon>check</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <h1>{{ carrier?.business_name }}</h1>
    <span class="status-pill" *ngIf="carrier?.is_blacklisted === false" [class.inactive]="carrierService.getCarrierStatus(carrier) === 'Inactive'">{{ carrierService.getCarrierStatus(carrier) }}</span>
    <span class="status-pill blacklisted" *ngIf="carrier?.is_blacklisted">Blacklisted</span>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">MC #: </span>{{ carrier?.mc_num ? carrier.mc_num : '-' }}</div>
    <div class="property-container"><span class="label">DOT #: </span>{{ carrier?.dot_num ? carrier.dot_num : '-' }}</div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Company name: </span>{{ carrier?.company_name }}</div>
    <div class="property-container"><span class="label">Email: </span>{{ carrier?.email }}</div>
    <div class="property-container"><span class="label">Power units (#): </span>{{ carrier?.power_units }}<ng-container *ngIf="fmcsaCarrier?.totalPowerUnits != null"> ({{ fmcsaCarrier.totalPowerUnits }}, according to FMCSA)</ng-container></div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Carrier packet: </span><a *ngIf="carrier != null && carrier.carrier_packet_url != null" [href]="carrier?.carrier_packet_url" target="_blank">File <mat-icon>attachment</mat-icon></a><span *ngIf="carrier?.carrier_packet_url == null">-</span></div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Carrier packet expiry: </span><span *ngIf="carrier != null && carrier.carrier_packet_expiry != null" [class.expired]="isPastDate(carrier?.carrier_packet_expiry)">{{ formatDate(carrier?.carrier_packet_expiry, false, true) }}</span><span *ngIf="carrier?.carrier_packet_expiry == null">-</span></div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Insurance: </span><a *ngIf="carrier != null && carrier.insurance_url != null" [href]="carrier?.insurance_url" target="_blank">File <mat-icon>attachment</mat-icon></a><span *ngIf="carrier?.insurance_url == null">-</span></div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Insurance expiry: </span><span *ngIf="carrier != null && carrier.insurance_expiry != null" [class.expired]="isPastDate(carrier?.insurance_expiry)">{{ formatDate(carrier?.insurance_expiry, false, true) }}</span><span *ngIf="carrier?.insurance_expiry == null">-</span></div>
  </div>
  <div class="row">
    <div class="property-container"><span class="label">Paperwork:</span></div>
  </div>
  <div class="row" *ngFor="let paperwork of carrier?.paperwork">
    <a [href]="paperwork.file_url" target="_blank">{{ paperwork.description }} <mat-icon>attachment</mat-icon></a>
  </div>
  <div class="row">
    <h2>Addresses</h2>
  </div>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
    <thead>
      <tr>
        <th></th>
        <th>Address</th>
        <th>City</th>
        <th>State</th>
        <th>ZIP</th>
      </tr>
    </thead>
    <tbody>
      <tr class="fmcsa-row" *ngIf="fmcsaCarrier?.phyStreet != null">
        <td>1</td>
        <td>{{ fmcsaCarrier.phyStreet }} (pulled from FMCSA)</td>
        <td>{{ fmcsaCarrier.phyCity }}</td>
        <td>{{ fmcsaCarrier.phyState }}</td>
        <td>{{ fmcsaCarrier.phyZipcode }}</td>
      </tr>
      <tr *ngFor="let address of carrier?.addresses; let i = index">
        <td>{{ fmcsaCarrier?.phyStreet ? i + 2 : i + 1 }}</td>
        <td>{{ address.address }}</td>
        <td>{{ address.city }}</td>
        <td>{{ address.state }}</td>
        <td>{{ address.zip }}</td>
      </tr>
      <tr *ngIf="carrier != null && (carrier.addresses == null || carrier.addresses.length === 0) && fmcsaCarrier?.phyStreet == null">
        <td class="center-text" colspan="5">No data found</td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <h2>Booking Agents</h2>
  </div>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
    <thead>
      <tr>
        <th></th>
        <th>Name</th>
        <th>Email</th>
        <th>Phone</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let agent of carrier?.booking_agents; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ agent.name }}</td>
        <td>{{ agent.email }}</td>
        <td>{{ agent.phone }}</td>
      </tr>
      <tr *ngIf="carrier != null && (carrier.booking_agents== null || carrier.booking_agents.length === 0)">
        <td class="center-text" colspan="4">No data found</td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <h2>Drivers</h2>
  </div>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
    <thead>
      <tr>
        <th></th>
        <th>Name</th>
        <th>Phone</th>
        <th>Driver #</th>
        <th>Tractor #</th>
        <th>Trailer #</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let driver of carrier?.drivers; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ driver.name }}</td>
        <td>{{ driver.phone }}</td>
        <td>{{ driver.driver_num ? driver.driver_num : '-' }}</td>
        <td>{{ driver.tractor_num ? driver.tractor_num : '-' }}</td>
        <td>{{ driver.trailer_num ? driver.trailer_num : '-' }}</td>
      </tr>
      <tr *ngIf="carrier != null && (carrier.drivers == null || carrier.drivers.length === 0)">
        <td class="center-text" colspan="6">No data found</td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <h2>Equipment</h2>
  </div>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
    <thead>
      <tr>
        <th></th>
        <th>Name</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let equipment of carrier?.equipments; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ equipment.name }}</td>
      </tr>
      <tr *ngIf="carrier != null && (carrier.equipments == null || carrier.equipments.length === 0)">
        <td class="center-text" colspan="2">No data found</td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <h2>Loads</h2>
  </div>
  <app-loads-table [carrierId]="id"></app-loads-table>
  <hr />
  <div class="row buttons-container">
    <h2 class="compact-h2">Notes</h2>
    <button class="btn-primary" (click)="addNote()">Add Note</button>
  </div>
  <p class="center-text" *ngIf="carrier.notes?.length === 0">No notes added yet</p>
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover">
    <thead>
      <tr>
        <th></th>
        <th></th>
        <th *ngIf="auth.isAdmin$ | async"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let note of carrier.notes">
        <td>{{ note.content }}</td>
        <td class="cell-shrink">By {{ formatAuthor(note.author) }} ({{ formatDate(note.created_at, true) }})</td>
        <td class="cell-shrink" *ngIf="auth.isAdmin$ | async"><button (click)="editNote(note)">Edit</button></td>
      </tr>
    </tbody>
  </table>
</div>
