import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-login-main',
  templateUrl: './login-main.component.html',
  styleUrls: ['./login-main.component.scss']
})
export class LoginMainComponent implements OnInit {

  headerTitle = this.uiService.headerTitle;

  constructor(
    private uiService: UiService
  ) { }

  ngOnInit(): void {
  }

}
