export class PlaceAddress {
  formatted_address: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  lat?: number;
  lng?: number;
}
