import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { UiService } from 'src/app/services/ui.service';
import { UserService } from 'src/app/services/user.service';
import { filter, finalize, switchMapTo, takeUntil, tap } from 'rxjs/operators';
import { RoleToLabelMapping } from 'src/app/enums/role.enum';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LogEmployeeIssueComponent } from '../log-employee-issue/log-employee-issue.component';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from 'src/app/models/confirm-dialog.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})
export class EmployeeDetailsComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<null>();

  id: number;
  employee: User;

  dtOptions: DataTables.Settings = {};

  isLoading = true;

  roleToLabelMapping = RoleToLabelMapping;

  constructor(
    private uiService: UiService,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    public auth: AuthService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private location: Location
  ) {
    this.uiService.setHeaderTitle('Employee Details');
  }

  ngOnInit(): void {
    this.dtOptions = {
      serverSide: false,
      pagingType: 'simple',
      lengthChange: false,
      paging: false,
      info: false,
      searching: false,
      ordering: false
    };

    this.route.paramMap.pipe(
      takeUntil(this.destroy$)
    ).subscribe((params: ParamMap) => {
      this.id = parseInt(params.get('id'));
      this.refreshData();
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  refreshData() {
    this.isLoading = true;
    this.userService.getUserDetails(this.id).pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.destroy$)
    ).subscribe(user => this.employee = user);
  }

  goToEditEmployee() {
    this.userService.userToEdit = this.employee;
    this.router.navigate(['/add-employee']);
  }

  getAddress(employee?: User) {
    if (employee) {
      const info = employee.user_info;
      return `${ info.address }, ${ info.city } ${ info.state } ${ info.zip }`;
    }
  }

  formatNumber(n?: string): string {
    return n ? parseFloat(n).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    }) : '0';
  }

  logIssue() {
    const dialog = this.dialog.open(LogEmployeeIssueComponent, {
      data: {
        id: this.id
      },
      width: '300px'
    });
    dialog.afterClosed().pipe(
      takeUntil(this.destroy$)
    ).subscribe(res => {
      if (res.success) {
        this.refreshData();
      }
    });
  }

  formatDate(date: Moment) {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }

  deleteEmployee() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: new ConfirmDialogModel('Delete Employee', `Are you sure you want to delete employee #${ this.employee.id }?`)
    });

    dialogRef.afterClosed().pipe(
      filter(res => res),
      tap(() => this.isLoading = true),
      switchMapTo(this.userService.deleteEmployee(this.employee.id)),
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      if (res) {
        this.snackbar.open('Employee deleted', null, {
          duration: 3000
        });
        this.location.back();
      } else {
        this.snackbar.open('Failed to delete employee. Please try again', null, {
          duration: 5000
        });
      }
    }, err => {
      let msg;
      if (err.error?.message) {
        msg = err.error.message;
      }
      this.snackbar.open(`Failed to delete employee. ${ msg }`, null, {
        duration: 5000
      });
    });
  }

}
