export enum LoadStatus {
  Active = 0,
  Booked = 1,
  Dispatched = 2,
  OnsiteShipper = 3,
  LoadedRolling = 4,
  OnsiteReceiver = 5,
  Delivered = 6,
  PodDelivered = 7,
}

export const LoadStatusToLabelMapping: Record<LoadStatus, string> = {
  [LoadStatus.Active]: 'Active',
  [LoadStatus.Booked]: 'Booked',
  [LoadStatus.Dispatched]: 'Dispatched',
  [LoadStatus.OnsiteShipper]: 'Onsite (Shipper)',
  [LoadStatus.LoadedRolling]: 'Loaded & Rolling',
  [LoadStatus.OnsiteReceiver]: 'Onsite (Receiver)',
  [LoadStatus.Delivered]: 'Delivered',
  [LoadStatus.PodDelivered]: 'POD Delivered',
}
