import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit, OnDestroy {

  clientId: number;
  file: any;
  filename: string;
  description: string;
  isAdding = false;

  destroy$ = new Subject<null>();

  errors: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DocumentUploadComponent>,
    private clientService: ClientService
  ) { }

  ngOnInit(): void {
    this.file = this.data.file;
    this.filename = this.data.filename;
    this.clientId = this.data.clientId;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  saveDocument() {
    if (this.description == null || this.description.trim() === '') {
      this.errors.push('Please enter a description');
    }

    this.errors = [];
    this.isAdding = true;
    this.clientService.addDocumentToClient(this.clientId, this.file, this.description).pipe(
      finalize(() => this.isAdding = false),
      takeUntil(this.destroy$)
    ).subscribe(res => {
      this.dialogRef.close({ success: true, res });
    }, err => {
      if (err.error?.errors) {
        this.errors = [].concat.apply([], Object.values(err.error.errors));
      } else if (err.error?.message) {
        this.errors = [err.error.message];
      } else {
        this.errors = ['An unexpected error has occurred. Please try again or contact administrator.'];
      }
    })
  }

}
