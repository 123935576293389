import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilePond, FilePondOptions } from 'filepond';

@Component({
  selector: 'app-pod-upload',
  templateUrl: './pod-upload.component.html',
  styleUrls: ['./pod-upload.component.scss']
})
export class PodUploadComponent implements OnInit {

  showNotesTextArea = false;

  @ViewChild('podPond') podPond: FilePond;
  notes: string;

  hasFile = false;

  pondOptions: FilePondOptions = {
    labelIdle: 'Drop proof of delivery here',
    allowMultiple: false,
    maxFiles: 1,
    credits: false
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<PodUploadComponent>
  ) { }

  ngOnInit(): void {
    this.showNotesTextArea = this.data.showNotesTextArea;
  }

  cancel() {
    this.dialogRef.close({ proceed: false });
  }

  proceed() {
    if (this.podPond.getFile() == null) {
      return;
    }
    const file = this.podPond.getFile().file;
    this.dialogRef.close({ proceed: true, result: file, notes: this.notes });
  }

}
