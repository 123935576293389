<div class="form-container">
  <div class="header-container">
    <h1>{{ headerTitle }}</h1>
    <div class="buttons-container">
      <button class="btn-primary" (click)="save()" [disabled]="isSaving">Save</button>
      <button class="btn-secondary" (click)="cancel()" [disabled]="isSaving">Cancel</button>
    </div>
  </div>
  <div class="errors-container" *ngIf="errors.length > 0">
    <div class="error-line" *ngFor="let error of errors">{{ error }}</div>
  </div>
  <h2>Client Details</h2>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="mc_num">Name</label>
        <input id="mc_num" type="text" [(ngModel)]="client.name" />
      </div>
      <div class="form-field-container">
        <label for="company_name">Address</label>
        <input #addressText placeholder="Start typing an address" [disabled]="!mapLoaded" id="company_name" type="text" [(ngModel)]="client.address" />
      </div>
      <div class="form-field-container">
        <label for="net_terms">Net Terms</label>
        <input id="net_terms" type="text" [(ngModel)]="client.net_terms" />
      </div>
    </div>
  </div>
  <h2>Representatives</h2>
  <div class="form-group-container">
    <div class="form-row flex" *ngFor="let rep of client.reps; let i = index">
      <div class="form-field-container">
        <label for="name">Name</label>
        <input id="name" type="text" [(ngModel)]="rep.name" />
      </div>
      <div class="form-field-container">
        <label for="email">Email</label>
        <input id="email" type="text" [(ngModel)]="rep.email" />
      </div>
      <div class="form-field-container">
        <label for="phone">Phone</label>
        <input id="phone" type="text" [(ngModel)]="rep.phone" />
      </div>
      <div class="form-field-container">
        <label for="title">Title</label>
        <input id="title" type="text" [(ngModel)]="rep.title" />
      </div>
      <button class="btn-remove" (click)="removeRep(i)">- Remove</button>
    </div>
    <button class="btn-secondary" (click)="addRep()">+ Add new representative</button>
  </div>
  <h2>Business Locations</h2>
  <div class="form-group-container">
    <div class="form-row flex" *ngFor="let location of client.business_locations; let i = index">
      <div class="form-field-container">
        <label for="label">Location Label</label>
        <input id="label" type="text" [(ngModel)]="location.label" />
      </div>
      <div class="form-field-container">
        <label for="address">Address</label>
        <input #businessAddressText placeholder="Start typing an address" [disabled]="!mapLoaded" id="address" type="text" [(ngModel)]="location.address" />
      </div>
      <div class="form-field-container">
        <label for="city">City</label>
        <input class="small-field" id="city" type="text" [(ngModel)]="location.city" />
      </div>
      <div class="form-field-container">
        <label for="state">State</label>
        <input class="small-field" id="state" type="text" [(ngModel)]="location.state" />
      </div>
      <div class="form-field-container">
        <label for="zip">ZIP</label>
        <input class="small-field" id="zip" type="text" [(ngModel)]="location.zip" />
      </div>
      <button class="btn-remove" (click)="removeLocation(i)">- Remove</button>
    </div>
    <button class="btn-secondary" (click)="addLocation()">+ Add new location</button>
  </div>
</div>
