<div class="main-container">
  <h2>Send rate con email</h2>
  <div class="errors-container" *ngIf="errors.length > 0">
    <div class="error-line" *ngFor="let error of errors">{{ error }}</div>
  </div>
  <div class="row">
    <label>To</label>
    <mat-form-field>
      <mat-chip-list #emailsToChipList>
        <mat-chip *ngFor="let email of emailsTo" (removed)="removeEmailTo(email)" [removable]="true" [disableRipple]="true">
          {{ email }}
            <mat-icon matChipRemove>close</mat-icon>
        </mat-chip>
        <input [matChipInputFor]="emailsToChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addEmailTo($event)" />
      </mat-chip-list>
    </mat-form-field>
  </div>
  <div class="row">
    <label>CC</label>
    <mat-form-field>
      <mat-chip-list #emailsCcChipList>
        <mat-chip *ngFor="let email of emailsCc" (removed)="removeEmailCc(email)" [removable]="true" [disableRipple]="true">
          {{ email }}
            <mat-icon matChipRemove>close</mat-icon>
        </mat-chip>
        <input [matChipInputFor]="emailsCcChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addEmailCc($event)" />
      </mat-chip-list>
    </mat-form-field>
  </div>
  <div class="row">
    <label>Text</label>
    <textarea cols="30" rows="5" [(ngModel)]="emailContent"></textarea>
  </div>
  <div class="row">
    <label>Additional Attachments</label>
    <file-pond #attachmentsPond [options]="pondOptions"></file-pond>
  </div>
  <div class="row buttons-row">
    <button *ngIf="displaySkip" class="btn-secondary" (click)="cancel()" [disabled]="isLoading">Skip</button>
    <button class="btn-primary" (click)="send()" [disabled]="isLoading">Send</button>
  </div>
</div>
