<div class="buttons-container">
  <input type="text" placeholder="Type to filter results" (input)="filterQueryChanged($event.target.value)" />
  <a class="btn-primary" [routerLink]="['/add-quote']">+ New quote</a>
</div>
<div class="table-container">
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover display nowrap">
    <thead>
      <tr>
        <th>Quote #</th>
        <th>Client</th>
        <th>Pickup Location</th>
        <th>Dropoff Location</th>
        <th>Rate</th>
      </tr>
    </thead>
    <tbody *ngIf="quotes?.length !== 0">
      <tr class="clickable" *ngFor="let quote of quotes; let i = index" (click)="goToQuote(quote.id)">
        <td>{{ quote.id }}</td>
        <td>{{ quote.client.name }}</td>
        <td>{{ quoteService.getPickupLocation(quote) }}</td>
        <td>{{ quoteService.getDropoffLocation(quote) }}</td>
        <td>${{ quote.rate }}</td>
      </tr>
    </tbody>
    <tbody *ngIf="quotes?.length === 0">
      <tr>
        <td colspan="5">No data found</td>
      </tr>
    </tbody>
  </table>
</div>
