import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { from, Subject } from 'rxjs';
import { finalize, switchMap, tap, takeUntil } from 'rxjs/operators';
import { ClientBusinessLocation } from 'src/app/models/client-business-location.model';
import { ClientService } from 'src/app/services/client.service';
import { GeocodingService } from 'src/app/services/geocoding.service';

@Component({
  selector: 'app-add-client-business-location',
  templateUrl: './add-client-business-location.component.html',
  styleUrls: ['./add-client-business-location.component.scss']
})
export class AddClientBusinessLocationComponent implements OnInit, OnDestroy {

  @ViewChild('addressText') addressText: ElementRef;

  clientId: number;
  location = new ClientBusinessLocation();

  errors: string[] = [];
  isSaving = false;

  destroy$ = new Subject<null>();

  mapLoaded = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private clientService: ClientService,
    private dialogRef: MatDialogRef<AddClientBusinessLocationComponent>,
    private geocodingService: GeocodingService,
    private ngZone: NgZone,
    private mapLoader: MapsAPILoader
  ) { }

  ngOnInit(): void {
    this.clientId = this.data.clientId;
    this.location.label = this.data.address;

    from(this.mapLoader.load()).subscribe(() => {
      this.mapLoaded = true;
      this.setupAutocomplete();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private setupAutocomplete() {
    let addressAutocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement, {
      componentRestrictions: { country: 'US' }
    });
    google.maps.event.addListener(addressAutocomplete, 'place_changed', () => {
      let place = addressAutocomplete.getPlace();
      let { address, city, state, zip } = this.geocodingService.deconstructGooglePlaceResult(place);
      this.ngZone.run(() => {
        this.location.address = address;
        this.location.city = city;
        this.location.state = state;
        this.location.zip = zip;
      });
    });
  }

  cancel() {
    this.dialogRef.close({ created: false });
  }

  save() {
    this.isSaving = true;
    this.errors = [];

    let { address, city, state, zip } = this.location;
    if (address.trim() === '' || city.trim() === '' || state.trim() === '' || zip.trim() === '') {
      this.errors.push('All business location fields must be filled in');
      this.isSaving = false;
      return;
    }
    const fullAddress = `${ address }, ${ city } ${ state } ${ zip }`;

    this.geocodingService.getLatLng(fullAddress).pipe(
      tap(res => {
        if (res.length) {
          const locResult = res[0].geometry.location;
          this.location.lat = locResult.lat();
          this.location.lng = locResult.lng();
          let foundLocality = false;
          for (const c of res[0].address_components) {
            const types = c.types;
            if (types.includes('administrative_area_level_1')) {
              this.location.state = c.short_name;
            } else if (types.includes('locality')) {
              this.location.city = c.long_name;
              foundLocality = true;
            } else if (types.includes('administrative_area_level_3') && !foundLocality) { // do not overwrite locality
              this.location.city = c.long_name;
            }
          }
        } else {
          this.errors.push(`Address could not be pinned to a set of coordinates. Please check to see that it is a valid address`);
          throw new Error();
        }
      }),
      switchMap(() => this.clientService.addNewBusinessLocation(this.clientId, this.location)),
      finalize(() => {
        this.ngZone.run(() => {
          this.isSaving = false
        });
      }),
      takeUntil(this.destroy$)
    ).subscribe(id => {
      this.ngZone.run(() => {
        this.errors = [];
        this.location.client_id = this.clientId;
        this.location.id = id;
        this.dialogRef.close({ created: true, item: this.location });
      });
    }, err => {
      this.ngZone.run(() => {
        if (err.error?.errors) {
          this.errors = [].concat.apply([], Object.values(err.error.errors));
        } else if (err.error?.message) {
          this.errors = [err.error.message];
        } else if (this.errors.length === 0) {
          this.errors = ['An unexpected error has occurred. Please try again or contact administrator.'];
        }
      });
    });
  }

}
