<div class="form-container">
  <div class="header-container">
    <h1>Add Booking Agent</h1>
    <div class="buttons-container">
      <button class="btn-primary" (click)="save()" [disabled]="isSaving">Save</button>
      <button class="btn-secondary" (click)="cancel()" [disabled]="isSaving">Cancel</button>
    </div>
  </div>
  <div class="errors-container" *ngIf="errors.length > 0">
    <div class="error-line" *ngFor="let error of errors">{{ error }}</div>
  </div>
  <div class="form-group-container">
    <div class="form-row flex">
      <div class="form-field-container">
        <label for="name">Name</label>
        <input id="name" type="text" [(ngModel)]="agent.name" />
      </div>
      <div class="form-field-container">
        <label for="email">Email</label>
        <input id="email" type="text" [(ngModel)]="agent.email" />
      </div>
      <div class="form-field-container">
        <label for="phone">Phone</label>
        <input id="phone" type="text" [(ngModel)]="agent.phone" />
      </div>
    </div>
  </div>
</div>
