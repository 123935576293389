import { Moment } from "moment";
import { User } from "./user.model";

export class CarrierNote {
  id?: number;
  content: string;
  author_id?: number;
  author: User;
  carrier_id?: number;
  created_at?: Moment;
}
