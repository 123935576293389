<div class="table-container">
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover display nowrap">
    <thead>
      <tr>
        <th>#</th>
        <th>Report</th>
      </tr>
    </thead>
    <tbody>
      <tr class="clickable" *ngFor="let report of reports; let i = index" [routerLink]="[report.link]">
        <td>{{ i + 1 }}</td>
        <td>{{ report.name }}</td>
      </tr>
    </tbody>
  </table>
</div>
