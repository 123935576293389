import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReportCarrier } from 'src/app/models/report-carrier.model';
import { ReportDateRanges } from 'src/app/models/report-date-ranges';
import { ReportService } from 'src/app/services/report.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-report-carrier',
  templateUrl: './report-carrier.component.html',
  styleUrls: ['./report-carrier.component.scss']
})
export class ReportCarrierComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<null>();

  report: ReportCarrier;

  selectedDates: { startDate: dayjs.Dayjs, endDate: dayjs.Dayjs } = {
    startDate: dayjs().subtract(29, 'days'),
    endDate: dayjs().add(1, 'days')
  };
  ranges = ReportDateRanges;

  dtOptions: DataTables.Settings = {};

  constructor(
    private reportService: ReportService,
    private uiService: UiService,
    private location: Location
  ) {
    this.uiService.setHeaderTitle('Carrier Report');
  }

  ngOnInit(): void {
    this.dtOptions = {
      serverSide: false,
      pagingType: 'simple',
      lengthChange: false,
      paging: false,
      info: false,
      searching: false,
      ordering: false
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  formatNumber(n?: string): string {
    return n ? parseFloat(n).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    }) : '0';
  }

  datesUpdated() {
    this.report = null;
    this.reportService.getCarrierReport(this.selectedDates.startDate, this.selectedDates.endDate).pipe(
      takeUntil(this.destroy$)
    ).subscribe(res => this.report = res);
  }

  goBack() {
    this.location.back();
  }

}
