<div class="main-container">
  <h2>Log an issue</h2>
  <label>Load ID</label>
  <select [(ngModel)]="employeeIssue.load_id" [disabled]="isLoading">
    <option *ngFor="let load of loads" [value]="load.id">{{ load.visual_id }}</option>
  </select>
  <label>Notes</label>
  <textarea rows="10" [(ngModel)]="employeeIssue.notes"></textarea>
  <button class="btn-primary" (click)="logIssue()" [disabled]="isLoading">Confirm</button>
</div>
