import { FilePondFile } from "filepond";
import * as moment from "moment";
import { Moment } from "moment";
import { LoadInvoicingStatus } from "../enums/load-invoicing-status.enum";
import { LoadStatus } from "../enums/load-status.enum";
import { LoadStopType } from "../enums/load-stop-type.enum";
import { CarrierBookingAgent } from "./carrier-booking-agent.model";
import { CarrierDriver } from "./carrier-driver.model";
import { CarrierEquipment } from "./carrier-equipment.model";
import { Carrier } from "./carrier.model";
import { ChargeLineItem } from "./charge-line-item.model";
import { ClientBusinessLocation } from "./client-business-location.model";
import { Client } from "./client.model";
import { LoadStatusHistoryRecord } from "./load-status-history-record.model";
import { LoadStop } from "./load-stop.model";

export class Load {
  id: number;
  visual_id?: string;
  pickup_bol_num: string;
  dropoff_po_num: string;
  charge: number;
  carrier_accessorial: number;
  client_accessorial: number;
  compensation: number;
  status: LoadStatus;
  invoicing_status: LoadInvoicingStatus;
  deadlineMoment: Moment;
  deadline: string;
  owner_id: number;
  client_id: number;
  client: Client;
  carrier_id?: number;
  carrier: Carrier;
  carrier_driver_id?: number;
  carrier_driver: CarrierDriver;
  carrier_booking_agent_id?: number;
  carrier_booking_agent: CarrierBookingAgent;
  load_tender?: FilePondFile;
  load_tender_url?: string;
  proof_of_delivery_url: string;
  requires_assistance: boolean;
  created_at: Moment;
  quote_id?: number;
  equipments: CarrierEquipment[];
  charge_line_items: ChargeLineItem[];
  status_history_records: LoadStatusHistoryRecord[];
  pickup_stops: LoadStop[];
  dropoff_stops: LoadStop[];
  carrier_invoice_url?: string;
  client_invoice_url?: string;
  carrier_payment_due_date?: Moment;
  client_payment_due_date?: Moment;

  constructor() {
    this.charge = 0;
    this.carrier_accessorial = 0;
    this.client_accessorial = 0;
    this.compensation = 0;
    this.deadlineMoment = moment().add(1, 'days').startOf('hour');
    this.equipments = [];
    this.charge_line_items = [];
    this.status_history_records = [];
    this.pickup_stops = [];
    this.dropoff_stops = [];
  }
}
