import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DtResponse } from '../models/dt-response.model';
import { EmployeeIssue } from '../models/employee-issue.model';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userToEdit: User;

  constructor(
    private http: HttpClient
  ) { }

  getAllUsers(dtParams?: any, searchQuery: string = ''): Observable<DtResponse<User>> {
    return this.http.post<DtResponse<User>>(`${ environment.apiUrl }/user/all-users`, {
      ...dtParams,
      search_query: searchQuery
    });
  }

  addNewEmployee(user: User | FormData): Observable<number> {
    return this.http.post<number>(`${ environment.apiUrl }/user/create-update`, user);
  }

  getUserDetails(id: number): Observable<User> {
    return this.http.get<User>(`${ environment.apiUrl }/user/${ id }`);
  }

  logEmployeeIssue(issue: EmployeeIssue): Observable<number> {
    return this.http.post<number>(`${ environment.apiUrl }/user/log-employee-issue`, issue);
  }

  deleteEmployee(id: number): Observable<boolean> {
    return this.http.post<boolean>(`${ environment.apiUrl }/user/delete`, {
      id
    });
  }
}
