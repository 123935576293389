import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<null>();

  email: string;
  password: string;
  errorMessage: string;

  isPasswordVisible = false;
  isLoading = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private uiService: UiService
  ) {
    this.uiService.setHeaderTitle('Welcome to the Hive!');
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  login() {
    this.isLoading = true;
    this.auth.login(this.email, this.password).pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.destroy$)
    ).subscribe(success => {
      this.router.navigate(['/dashboard']);
    }, err => {
      if (err.error?.error) {
        this.errorMessage = err.error.error;
      } else {
        this.errorMessage = 'Failed to login. Please check your email and password.';
      }
    });
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

}
