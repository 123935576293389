import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddLoadComponent } from './components/add-load/add-load.component';
import { AddCarrierComponent } from './components/add-carrier/add-carrier.component';
import { AddClientComponent } from './components/add-client/add-client.component';
import { CarrierDetailsComponent } from './components/carrier-details/carrier-details.component';
import { CarriersTableComponent } from './components/carriers-table/carriers-table.component';
import { ClientDetailsComponent } from './components/client-details/client-details.component';
import { ClientsTableComponent } from './components/clients-table/clients-table.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoadsTableComponent } from './components/loads-table/loads-table.component';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { ReportsListComponent } from './components/reports-list/reports-list.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { LoadDetailsComponent } from './components/load-details/load-details.component';
import { DocumentsTableComponent } from './components/documents-table/documents-table.component';
import { ReportRevenueComponent } from './components/report-revenue/report-revenue.component';
import { ReportClientComponent } from './components/report-client/report-client.component';
import { ReportLoadComponent } from './components/report-load/report-load.component';
import { EmployeesTableComponent } from './components/employees-table/employees-table.component';
import { EmployeeDetailsComponent } from './components/employee-details/employee-details.component';
import { AddEmployeeComponent } from './components/add-employee/add-employee.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { QuotesTableComponent } from './components/quotes-table/quotes-table.component';
import { AddQuoteComponent } from './components/add-quote/add-quote.component';
import { QuoteDetailsComponent } from './components/quote-details/quote-details.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { AdminGuard } from './guards/admin.guard';
import { EmployeeGuard } from './guards/employee.guard';
import { LoginMainComponent } from './components/login-main/login-main.component';
import { ReportEmployeeComponent } from './components/report-employee/report-employee.component';
import { ReportCarrierComponent } from './components/report-carrier/report-carrier.component';
import { ReportEmployeeSelfComponent } from './components/report-employee-self/report-employee-self.component';
import { ReportLeaderboardComponent } from './components/report-leaderboard/report-leaderboard.component';

const routes: Routes = [
  { path: '', component: LoginMainComponent, canActivate: [LoginGuard], children: [
    { path: 'login', component: LoginComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: '', pathMatch: 'full', redirectTo: 'login' }
  ] },
  { path: '', component: MainComponent, canActivate: [AuthGuard], children: [
    { path: 'admin-dashboard', component: AdminDashboardComponent, canActivate: [AdminGuard], children: [
      { path: 'loads', component: LoadsTableComponent, data: { allLoads: true }},
      { path: 'reports', component: ReportsListComponent },
      { path: '', pathMatch: 'full', redirectTo: 'loads' },
    ]},
    { path: 'dashboard', component: DashboardComponent, canActivate: [EmployeeGuard], children: [
      { path: 'loads', component: LoadsTableComponent },
      { path: 'reports', component: ReportsListComponent },
      { path: '', pathMatch: 'full', redirectTo: 'loads' },
    ] },
    { path: 'carriers', component: CarriersTableComponent },
    { path: 'carrier/:id', component: CarrierDetailsComponent },
    { path: 'add-carrier', component: AddCarrierComponent },
    { path: 'clients', component: ClientsTableComponent },
    { path: 'client/:id', component: ClientDetailsComponent },
    { path: 'add-client', component: AddClientComponent },
    { path: 'my-quotes', component: QuotesTableComponent },
    { path: 'all-quotes', component: QuotesTableComponent, data: { allQuotes: true } },
    { path: 'add-quote', component: AddQuoteComponent },
    { path: 'quote/:id', component: QuoteDetailsComponent },
    { path: 'my-loads', component: LoadsTableComponent },
    { path: 'all-loads', component: LoadsTableComponent, data: { allLoads: true } },
    { path: 'load/:id', component: LoadDetailsComponent },
    { path: 'add-load', component: AddLoadComponent },
    { path: 'employees', component: EmployeesTableComponent },
    { path: 'employee/:id', component: EmployeeDetailsComponent },
    { path: 'add-employee', component: AddEmployeeComponent },
    { path: 'documents', component: DocumentsTableComponent },
    { path: 'reports', component: ReportsListComponent },
    { path: 'report', children: [
      { path: 'revenue', component: ReportRevenueComponent },
      { path: 'client', component: ReportClientComponent },
      { path: 'load', component: ReportLoadComponent },
      { path: 'employee', component: ReportEmployeeComponent },
      { path: 'carrier', component: ReportCarrierComponent },
      { path: 'employee-self', component: ReportEmployeeSelfComponent },
      { path: 'leaderboard', component: ReportLeaderboardComponent }
    ]},
    { path: '', pathMatch: 'full', redirectTo: 'admin-dashboard' },
  ] },
  { path: '', pathMatch: 'full', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
