<div class="top-container" *ngIf="authService.isAdmin$ | async">
  <div class="errors-container" *ngIf="errors.length > 0">
    <div class="error-line" *ngFor="let error of errors">{{ error }}</div>
  </div>
  <div class="upload-container">
    <file-pond #uploadPond [options]="pondOptions"
      (onaddfile)="fileAdded()">
    </file-pond>
  </div>
  <hr />
</div>
<div class="table-container">
  <table datatable [dtOptions]="dtOptions" class="row-border cell-border hover display nowrap">
    <thead>
      <tr>
        <th>Document #</th>
        <th>Name</th>
        <th>Upload Time</th>
        <th></th>
        <th *ngIf="authService.isAdmin$ | async"></th>
      </tr>
    </thead>
    <tbody *ngIf="documents?.length !== 0">
      <tr *ngFor="let document of documents">
        <td>{{ document.id }}</td>
        <td>{{ document.filename }}</td>
        <td>{{ formatDate(document.created_at) }}</td>
        <td><a href="{{ document.file_url }}" target="_blank">Link</a></td>
        <td *ngIf="authService.isAdmin$ | async">
          <button class="btn-round btn-delete" (click)="deleteDocument(document.id)"><mat-icon>delete_outlined</mat-icon></button>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="documents?.length === 0">
      <tr>
        <td colspan="4" *ngIf="!(authService.isAdmin$ | async)">No data found</td>
        <td colspan="5" *ngIf="authService.isAdmin$ | async">No data found</td>
      </tr>
    </tbody>
  </table>
</div>
