import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<null>();

  email: string;
  password: string;
  passwordConfirm: string;
  token: string;

  isPasswordVisible = false;
  isLoading = false;
  errorResults = '';

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    private uiService: UiService
  ) {
    this.uiService.setHeaderTitle('Reset password.')
  }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(
      takeUntil(this.destroy$)
    ).subscribe((queryParams: ParamMap) => {
      this.token = queryParams.get('token');
      this.email = queryParams.get('email');
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  checkPasswordConfirm(control: NgModel) {
    if (this.password != this.passwordConfirm) {
      control.control.setErrors({ error: 'Make sure both passwords match' });
    } else {
      control.control.setErrors(null);
    }
  }

  resetPassword() {
    if (this.password != this.passwordConfirm) {
      return;
    }

    this.isLoading = true;
    this.errorResults = '';
    this.auth.resetPassword(this.email, this.password, this.token).pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.destroy$)
    ).subscribe(res => {
      this.snackbar.open('Password updated successfully', null, {
        duration: 5000
      });
      this.router.navigate(['/login']);
    }, err => {
      if (err.error?.message) {
        this.errorResults = `Error: ${ err.error?.message }`;
      } else {
        this.errorResults = 'An unknown error has occurred. Please try again or contact administrator.';
      }
    });
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

}
